import { insertInHash, removeFromHash } from "../../common/functions"
import { createHandler, buildFromCreator } from "../ReduxTemplates"
import { AnswersState as SliceInterface } from "./Interface"


export const AnswersCreator = {
    createAnswer : createHandler({
        name: "answers/create_answer",
        method: "POST",
        route: "/quiz/answers/crud",
        key: "answer",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.answer.createInfo.status = "pending"
            state.answer.createInfo.message = "Creating answer... Please wait."
            state.answer.createInfo.submitted = true
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.answer.createInfo.status = "fulfilled"
            state.answer.createInfo.message = "Answer created succesfully."
            state.answer.answers = insertInHash(state.answer.answers, action.payload.answer, "id")
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.answer.createInfo.status = "rejected"
            state.answer.createInfo.message = action.payload.msg
            return state
        }
    }),
    readAnswers : createHandler({
        name: "answers/read_answer",
        method: "GET",
        route: "/quiz/answers/crud",
        key: "",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.answer.readInfo.status = "pending"
            state.answer.readInfo.message = "Loading answer(s)... Please wait."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.answer.readInfo.status = "fulfilled"
            state.answer.readInfo.message = "Answer(s) loaded succesfully."
            if (action.payload.hash_info.enable_hash === true) state.answer.answers = action.payload.answers
            else 
                for (const answer of action.payload.answers) state.answer.answers = insertInHash(state.answer.answers, answer, action.payload.hash_info.hash_key, action.payload.hash_info.hash_type)
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.answer.readInfo.status = "rejected"
            state.answer.readInfo.message = action.payload.msg
            return state
        }
    }),
    updateAnswer : createHandler({
        name: "answers/update_answer",
        method: "PUT",
        route: "/quiz/answers/crud",
        key: "answer",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.answer.updateInfo.status = "pending"
            state.answer.updateInfo.message = "Updating answer... Please wait."
            state.answer.updateInfo.submitted = true
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.answer.updateInfo.status = "fulfilled"
            state.answer.updateInfo.message = "Answer updated succesfully."
            state.answer.answers = {
                ...state.answer.answers,
                [action.payload.answer.session_id] : [
                    ...state.answer.answers[action.payload.answer.session_id].filter(ans => ans.id !== action.payload.answer.id),
                    action.payload.answer
                ]
            }
            
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.answer.updateInfo.status = "rejected"
            state.answer.updateInfo.message = action.payload.msg
            return state
        }
    }),
    deleteAnswer: createHandler({
        name: "answers/delete_answer",
        method: "DELETE",
        route: "/quiz/answers/crud",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.answer.deleteInfo.status = "pending"
            state.answer.deleteInfo.message = "Deleting answer... Please wait."
            state.answer.deleteInfo.submitted = true
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.answer.deleteInfo.status = "fulfilled"
            state.answer.deleteInfo.message = "Answer deleted."
            state.answer.answers = insertInHash(state.answer.answers, action.payload.answer, "id")
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.answer.deleteInfo.status = "rejected"
            state.answer.deleteInfo.message = action.payload.msg
            return state
        }
    })
}

export const [AnswerActions, AnswersReducers] = buildFromCreator(AnswersCreator)