import { createSlice } from "@reduxjs/toolkit";
import { AnswerActions, AnswersReducers } from "./Actions";
import { AnswersState } from "./Interface";

const init: AnswersState = {
    answer: {
        answers: [],
        createInfo: { status: "idle", message: "", session_id: null, submitted: false },
        readInfo: { status: "idle", message: "", session_id: null, submitted: false },
        updateInfo: { status: "idle", message: "", session_id: null, submitted: false },
        deleteInfo: { status: "idle", message: "", session_id: null, submitted: false }
    }
}

const AnswersSlice = createSlice({
    name: "answers",
    initialState: init,
    reducers: {
        setAnswersState: (state: AnswersState, action) => {
            for (let prop in action.payload) {
                state[prop] = action.payload[prop]
            }
            return state
        },
    },
    extraReducers(builder) {
        for (const action in AnswersReducers) {
            builder.addCase(action, AnswersReducers[action])
        }
    }
})


export const { setAnswersState } = AnswersSlice.actions
export default AnswersSlice.reducer