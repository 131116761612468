import { IonModal } from "@ionic/react"
import { useState } from "react"
import { BsFillClockFill, BsFillEyeFill } from "react-icons/bs"
import { AiFillEdit } from "react-icons/ai"
import { NavLink } from "react-router-dom"
import { QuizQuestion as QuestionInterface } from "../../../common/Interfaces"
import QuizQuestion from "../QuizComponents/QuizQuestion"

interface QuestionCardInterface {
    _ref: any
    draggableProps: { style : {} }
    dragHandleProps: {}
    segment: {
        id: number
        quiz_id: number
        name: string
        questions: Array<number>
    } | null
    setSegment: Function | null
    setDispatch: Function | null
    question: QuestionInterface
}

const QuestionCard: React.FC<QuestionCardInterface> = ({ _ref, draggableProps, dragHandleProps,  segment = null, setSegment = null, setDispatch = null, question }) => {

    const [modal, setModal] = useState(false)

    const handleClick = (e) => {
        let parent = e.target
        if (parent === null) return
        while (parent !== null && parent.classList.contains("question-container") === false && parent.classList.contains("view-question") === false) parent = parent.parentElement
        if (parent !== null && segment !== null && parent.classList.contains("question-container")) {
            setSegment({ ...segment, questions: segment.questions.includes(question.id) ? segment.questions.filter(i => i !== question.id) : [...segment.questions, question.id] })
            setDispatch()
        }
        else if (parent !== null && parent.classList.contains("view-question")) setModal(true)
    }

    return (
        <div
            {...draggableProps}
            {...dragHandleProps}
            ref={_ref}
            style={{
                userSelect: "none",
                ...draggableProps.style
            }}
            className={segment !== null && segment.questions.includes(question.id) ?
                "question-container cursor-pointer shadow rounded-lg p-3 bg-green-100" :
                "question-container cursor-pointer shadow rounded-lg p-3  bg-white"}
        onClick={e => handleClick(e)}
        >
            <IonModal isOpen={modal} onWillDismiss={e => setModal(false)}>
                <QuizQuestion question={question} setOpen={setModal} />
            </IonModal>
            <h1 className="font-bold text-slate-700 flex justify-between">
                Question:
                <span className="flex items-center gap-1">
                    <BsFillClockFill />
                    {question.duration} s
                </span>
            </h1>
            {question.question}
            <h1 className="text-slate-700 capitalize flex justify-between items-center mt-3">
                <div>
                    <span className="font-bold">Type: </span>
                    {question.type}
                    {question.type === "choice" ? ` (${question.data?.is_multiple === 1 ? "multiple" : "single choice"})` : null}
                </div>
                <span className="w-[100px] flex justify-end gap-2">
                    <NavLink className="view-question bg-slate-800 text-white px-3 py-0.5 rounded-lg shadow hover:shadow-lg" to={"/question/" + question.id}><AiFillEdit /></NavLink>
                    <button className="view-question bg-slate-800 text-white px-3 py-0.5 rounded-lg shadow hover:shadow-lg">
                        <BsFillEyeFill />
                    </button>
                </span>
            </h1>
        </div>
    )
}

export default QuestionCard