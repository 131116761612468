import { useDispatch, useSelector } from "react-redux"
import { STATIC_URL } from "../../common/constants"
import { AppDispatch, RootState } from "../../Redux/Store"
import { UserState } from "../../Redux/User/Interface"
import { useEffect, useState } from "react"
import { AnswerActions } from "../../Redux/Answers/Actions"
import { AnswersState } from "../../Redux/Answers/Interface"
import { MdQuiz, MdCheckBox } from "react-icons/md"
import { NavLink } from "react-router-dom"
import { useIonAlert } from "@ionic/react"
import { UserActions } from "../../Redux/User/Actions"
import { HiUsers } from "react-icons/hi"
import { IoIosPaper } from "react-icons/io"
import { MdOutlineAnalytics } from "react-icons/md"
import SessionsBrowser from "./SessionsBrowser"
import ManageUsers from "../UserManagement/ManageUsers"
const getUnreviewed = answers => {
    try {
        const ret = []
        for (const answer in answers) {
            if (answers[answer].find(a => a.points < 0) !== undefined) ret.push(answer)
        }
        return ret
    } catch (e) {
        console.log(e)
    }
}

const Dashboard: React.FC<{}> = ({ }) => {
    const dispatch = useDispatch<AppDispatch>()
    const UserReudcer: UserState = useSelector((state: RootState) => state.user)
    const AnswersReducer: AnswersState = useSelector((state: RootState) => state.answers)
    const [selected, setSelected] = useState(null)
    const [unreviewed, setUnreviewed] = useState(undefined)
    const [alert] = useIonAlert()

    useEffect(() => {
        dispatch(AnswerActions.readAnswers({ arguments: { enable_hash: true, hash_key: "session_id", hash_type: "obht" } }))
        dispatch(UserActions.readUsers({ arguments: { enable_hash: true } }))
    }, [])


    useEffect(() => {
        if (AnswersReducer.answer.readInfo.status === "rejected") {
            alert({
                "message": AnswersReducer.answer.readInfo.message,
                "header": "Error",
                buttons: ["I Understand"]
            })
        }
        if (AnswersReducer.answer.readInfo.status === "fulfilled") setUnreviewed(getUnreviewed(AnswersReducer.answer.answers))
    }, [AnswersReducer.answer.readInfo.status])


    return (
        <div className="pt-16 pb-12 max-h-[200px] bg-[#254E46] bg-[url('https://i.gifer.com/CGKV.gif')] bg-no-repeat bg-contain bg-center rounded-b-2xl bg-fixed">
            <div className={(selected === null ? "mt-40 " : "mt-24 ") + "w-[1000px] max-w-[98%] mx-auto mb-12 flex flex-wrap gap-2 items-center transition-all"}>
                {UserReudcer.current.type_id >= 2 ? <div className={(selected === 0 ? "p-3 border-4 border-red-200 " : "p-2 ") + "md:mx-auto bg-red-600 rounded-md shadow relative w-[48%] md:w-[32%] transition-all cursor-pointer"} onClick={e => setSelected(0)}>
                    <HiUsers className="absolute text-3xl md:text-5xl right-[5px] bottom-[5px] text-red-200" />
                    <h1 className="md:text-lg font-bold text-white mr-6 md:mr-0">User Menagement</h1>
                    <p className="hidden md:block text-white">{UserReudcer.users.filter(u => u !== null).length} active users</p>
                </div> : null}
                <div className={(selected === 1 ? "p-3 border-4 border-blue-200 " : "p-2 ") + "md:mx-auto bg-blue-600 rounded-md shadow relative w-[48%] md:w-[32%] transition-all cursor-pointer"} onClick={e => setSelected(1)}>
                    <IoIosPaper className="absolute text-3xl md:text-5xl right-[5px] bottom-[5px] text-blue-200" />
                    <h1 className="md:text-lg font-bold text-white mr-10 md:mr-0">Sessions Grading</h1>
                    <p className="hidden md:block text-white">{unreviewed === undefined ? "..." : unreviewed.length === 0 ? "No sessions to review" : unreviewed.length + " sessions to review"}</p>
                </div>
                <div className={(selected === 2 ? "p-3 border-4 border-emerald-200 " : "p-2 ") + "md:mx-auto bg-emerald-600 rounded-md shadow relative w-[48%] md:w-[32%] transition-all cursor-pointer"} onClick={e => setSelected(2)}>
                    <MdOutlineAnalytics className="absolute text-3xl md:text-5xl right-[5px] bottom-[5px] text-emerald-200" />
                    <h1 className="md:text-lg font-bold text-white mr-8 md:mr-0">Analytics Browser</h1>
                    <p className="hidden md:block text-white">View detailed analytics</p>
                </div>
            </div>

            {selected === 0 ? <ManageUsers /> : null}
            {selected === 1 ? <SessionsBrowser /> : null}
        </div>
    )
}

export default Dashboard