import { NavLink } from "react-router-dom"
import { STATIC_URL } from "../../../common/constants"
import { CareerQuizes, QuizSegments } from "../../../common/Interfaces"
import { QuizesState } from "../../../Redux/Quizes/Interface"

const getQuestiosnInfo = (segments: Array<QuizSegments>, reducer: QuizesState) => {
    const questions = []
    let totalDuration = 0
    for (const segment of segments) {
        for (const question of segment.questions) {
            let q = reducer.quizQuestions.questions.find(q => q !== null && q.id === question.question_id)
            if (q === undefined || q.archived === 1) continue
            questions.push(q)
            totalDuration += q.duration
        }
    }
    return { questions, totalDuration }
}

const QuizCard: React.FC<{
    quiz: CareerQuizes
    QuizesReducer: QuizesState
    current_user_type: number
}> = ({ quiz, QuizesReducer, current_user_type }) => {
    const segments = QuizesReducer.quizSegments.segments.filter(seg => seg !== null && seg.quiz_id === quiz.id)
    const qestions = getQuestiosnInfo(segments, QuizesReducer)

    return (
        <NavLink to={(current_user_type === 1 ? "/quiz/" : "/edit_quiz/") + quiz.id} className="p-4 bg-white shadow flex">
            <img src={STATIC_URL + quiz.cover_photo} className="w-20 h-20 mr-2 rounded-md shadow border border-slate-700" />
            <div className="h-20 flex flex-col justify-between">
                <p className="text-slate-900 font-bold text-sm">{quiz.name}</p>
                <p className="text-slate-700 text-sm">
                    {segments.length} parts containing {qestions.questions.length} questions lasting {Math.round(qestions.totalDuration / 60)} minutes
                </p>
            </div>
        </NavLink>
    )
}

export default QuizCard