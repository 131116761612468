import { useSelector } from "react-redux"
import { CareersState } from "../../../Redux/Careers/Interface"
import { UserState } from "../../../Redux/User/Interface"
import { RootState } from "../../../Redux/Store"
import { UserCareers } from "../../../common/Interfaces"

const EnrolledUsers: React.FC<{ id: number, enrollments:Array<UserCareers> }> = ({ id, enrollments }) => {

    const UserReudcer: UserState = useSelector((state: RootState) => state.user)
    const CareersReducer: CareersState = useSelector((state: RootState) => state.career)

    return (
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg mb-12">
            <table className="w-full text-sm text-left text-gray-500">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 border-b border-slate-400">
                    <tr>
                        <th className="px-6 py-3">User</th>
                        <th className="px-6 py-3">User Email</th>
                        <th className="px-6 py-3">Date Enrolled</th>
                        <th className="px-6 py-3">Career</th>
                        <th className="px-6 py-3">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {enrollments.length === 0 ? <p className="text-center py-1 text-slate-600 font-bold">No enrollments in this career</p> : null }
                    {enrollments.map((c, idx) => (
                        <tr className={idx % 2 === 0 ? "bg-white border-b" : "bg-gray-50 border-b"}>
                            <th className="px-6 py-4">{UserReudcer.users[c.user_id]?.first_name} {UserReudcer.users[c.user_id]?.last_name}</th>
                            <td className="px-6 py-4">{UserReudcer.users[c.user_id]?.email}</td>
                            <td className="px-6 py-4">{c.enroll_date}</td>
                            <td className="px-6 py-4">{CareersReducer.career.careers[c.career_id]?.name}</td>
                            <td className="px-6 py-4"><a href="#" className="font-medium text-red-600 hover:underline">Un-Enroll</a></td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default EnrolledUsers