import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { CareersState } from "../../../Redux/Careers/Interface"
import { AppDispatch, RootState } from "../../../Redux/Store"
import { setCareersState } from "../../../Redux/Careers/Slice"
import { IonLoading, useIonAlert } from "@ionic/react"
import { CareerActions } from "../../../Redux/Careers/Actions"
import { HiPhotograph } from "react-icons/hi"
import { BsFillArrowLeftSquareFill } from "react-icons/bs"
import { NavLink, useParams } from "react-router-dom"

let session = Math.random().toString(36).slice(2)

const EditCareer: React.FC<{}> = ({ }) => {
    const id:number = parseInt(useParams<{id: string}>().id)
    const dispatch = useDispatch<AppDispatch>()
    const CareersReducer: CareersState = useSelector((state: RootState) => state.career)
    const current_career = CareersReducer.career.careers[id]
    const [career, setCareer] = useState({ ...current_career })

    const [ionAlert] = useIonAlert()

    useEffect(() => {
        session = Math.random().toString(36).slice(2)
        dispatch(setCareersState({
            career: { ...CareersReducer.career, updateInfo: { ...CareersReducer.career.updateInfo, submitted: false, session_id: session } }
        }))
    }, [])

    useEffect(() => {
        if (CareersReducer.career.updateInfo.submitted !== true || CareersReducer.career.updateInfo.session_id !== session) return
        if (CareersReducer.career.updateInfo.status === "fulfilled") {
            ionAlert({ header: "Success", message: "Career created successfully.", buttons: ["OK"] })
        }
        if (CareersReducer.career.updateInfo.status === "rejected") {
            ionAlert({ header: "Error!", message: CareersReducer.career.updateInfo.message, buttons: ["OK"] })
        }
    }, [CareersReducer.career.updateInfo.status])

    const handleChange = e => {
        if (e.target.name === "cover_photo") setCareer({ ...career, [e.target.name]: e.target.files[0] })
        else setCareer({ ...career, [e.target.name]: e.target.value?.toString() })
        dispatch(setCareersState({
            career: { ...CareersReducer.career, updateInfo: { ...CareersReducer.career.updateInfo, submitted: false } }
        }))
    }

    const handleSubmit = e => {
        e.preventDefault()
        const data = new FormData()
        data.append("id", career.id.toString())
        if (current_career.name !== career.name) data.append("name", career.name)
        if (current_career.description !== career.description) data.append("description", career.description)
        if (typeof(career.cover_photo) !== typeof("abc") && career.cover_photo !== null) data.append("cover_photo", career.cover_photo)
        dispatch(CareerActions.updateCareer({ career: data }))
    }

    return (
        <div >
            <div className="
                bg-[url('https://liveimages.algoworks.com/new-algoworks/wp-content/uploads/2022/04/19150239/ezgif.com-gif-maker-1.gif')] 
                bg-[#043E61] bg-no-repeat bg-contain bg-center bg-fixed
                pt-16 pb-12 max-h-[250px] rounded-b-2xl
            ">
                <IonLoading isOpen={CareersReducer.career.updateInfo.submitted === true && CareersReducer.career.updateInfo.status === "pending"} message={CareersReducer.career.updateInfo.message} />
                <div className="w-[800px] max-w-[90%] mx-auto p-4 mt-36 mb-12 bg-white  rounded-lg shadow-md">
                    <h1 className="text-xl font-bold text-slate-800  mb-4 flex items-center gap-3">
                        <NavLink to={"/career/"+id} className="text-[#043E61] cursor-pointer">
                            <BsFillArrowLeftSquareFill />
                        </NavLink>
                        {current_career.name}
                    </h1>
                    <div className="flex flex-col gap-2 mb-3">
                        <label className="text-sm font-bold text-slate-600 ">Career Name</label>
                        <input className="px-2 py-1.5 bg-white  text-slate-800  rounded border border-slate-200  border-2 outline-none focus:border-[#043E61]" type="text" value={career.name} name="name" placeholder="Plastic Repair Technician" onChange={e => handleChange(e)} />
                    </div>
                    <div className="flex flex-col gap-2 mb-3">
                        <label className="text-sm font-bold text-slate-600 ">Career Description</label>
                        <textarea className="px-2 py-1.5 min-h-[100px] bg-white  text-slate-800  rounded border border-slate-200 border-2 outline-none focus:border-[#043E61]" value={career.description} name="description" placeholder="Explanation about the career..." onChange={e => handleChange(e)} />
                    </div>
                    <div className="relative mb-5 flex items-center">
                        <label className="text-sm font-bold text-slate-600  mr-5">Select Career Cover Photo</label>
                        <div>
                            <input type="file" name="cover_photo" onChange={e => handleChange(e)} className="absolute w-[50px] opacity-0 z-10 cursor-pointer" />
                            <HiPhotograph className={`w-[50px] z-0 text-3xl ${career.cover_photo === null || typeof(career.cover_photo) === typeof("abc") ? "text-gray-500" : "text-[#043E61]"}`} />
                        </div>
                    </div>

                    <button className="bg-[#043E61] text-white px-8 py-1 rounded shadow-md hover:shadow-lg" onClick={e => handleSubmit(e)}>UPDATE</button>
                </div>
            </div>
        </div>
    )
}

export default EditCareer