import { insertInHash, removeFromHash } from "../../common/functions"
import { createHandler, buildFromCreator } from "../ReduxTemplates"
import { UserState as SliceInterface } from "./Interface"

export const UserCreator = {
    login: createHandler({
        name: "user/login",
        method: "POST",
        route: "/user/auth/login",
        key: "user",
        skipAuth: true,
        pending: (state:SliceInterface) => {
            state.loginInfo.status = "pending"
            state.loginInfo.message = "Logging in... Please wait.."
            state.loginInfo.submitted = true
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.loginInfo.status = "fulfilled"
            state.loginInfo.message = "Login success."
            state.current = action.payload.user
            state.users = insertInHash(state.users, action.payload.user, "id")
            state.token = action.payload.token
            state.refresh = action.payload.refresh
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.loginInfo.status = "rejected"
            state.loginInfo.message = action.payload.msg
            return state
        }
    }),
    register: createHandler({
        name: "user/register",
        method: "POST",
        route: "/user/auth/register",
        key: "user",
        skipAuth: true,
        pending: (state:SliceInterface, action) => {
            state.registerInfo.status = "pending"
            state.registerInfo.message = "Registering user... Please wait."
            state.registerInfo.submitted = true
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.registerInfo.status = "fulfilled"
            state.registerInfo.message = "User registered successfully."
            state.current = action.payload.user
            state.users = insertInHash(state.users, action.payload.user, "id")
            state.token = action.payload.token
            state.refresh = action.payload.refresh
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.registerInfo.status = "rejected"
            state.registerInfo.message = action.payload.msg
            return state
        }
    }),
    forgotPassword: createHandler({
        name: "user/forgotPassword",
        method: "POST",
        route: "/user/auth/forgot_password",
        key: "user",
        skipAuth: true,
        pending: (state:SliceInterface, action) => {
            state.resetPassword.emailInfo.status =  "pending"
            state.resetPassword.emailInfo.message =  "Sending email... Please wait."
            state.resetPassword.verifyInfo.status =  "idle"
            state.resetPassword.verifyInfo.message =  ""
            state.resetPassword.resetInfo.status =  "idle"
            state.resetPassword.resetInfo.message =  ""
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.resetPassword.emailInfo.status =  "fulfilled"
            state.resetPassword.emailInfo.message =  action.payload.msg
            state.resetPassword.verifyInfo.status =  "idle"
            state.resetPassword.verifyInfo.message =  ""
            state.resetPassword.resetInfo.status =  "idle"
            state.resetPassword.resetInfo.message =  ""
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.resetPassword.emailInfo.status =  "rejected"
            state.resetPassword.emailInfo.message =  action.payload.msg
            state.resetPassword.verifyInfo.status =  "idle"
            state.resetPassword.verifyInfo.message =  ""
            state.resetPassword.resetInfo.status =  "idle"
            state.resetPassword.resetInfo.message =  ""
            return state
        },
    }),
    verifyCode: createHandler({
        name: "user/verifyCode",
        method: "POST",
        route: "/user/auth/verify_code",
        key: "user",
        skipAuth: true,
        pending: (state:SliceInterface, action) => {
            state.resetPassword.verifyInfo.status =  "pending"
            state.resetPassword.verifyInfo.message =  "Verifying code... Please wait."
            state.resetPassword.resetInfo.status =  "idle"
            state.resetPassword.resetInfo.message =  ""
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.resetPassword.verifyInfo.status =  "fulfilled"
            state.resetPassword.verifyInfo.message =  action.payload.msg
            state.resetPassword.resetInfo.status =  "idle"
            state.resetPassword.resetInfo.message =  ""
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.resetPassword.verifyInfo.status =  "rejected"
            state.resetPassword.verifyInfo.message =  action.payload.msg
            state.resetPassword.resetInfo.status =  "idle"
            state.resetPassword.resetInfo.message =  ""
            return state
        },
    }),
    resetPassword: createHandler({
        name: "user/resetPassword",
        method: "POST",
        route: "/user/auth/reset_password",
        key: "user",
        skipAuth: true,
        pending: (state:SliceInterface, action) => {
            state.resetPassword.resetInfo.status =  "pending"
            state.resetPassword.resetInfo.message =  "Updating password... Please wait."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.resetPassword.emailInfo.status =  "idle"
            state.resetPassword.emailInfo.message =  ""
            state.resetPassword.verifyInfo.status =  "idle"
            state.resetPassword.verifyInfo.message =  ""
            state.resetPassword.resetInfo.status =  "fulfilled"
            state.resetPassword.resetInfo.message =  action.payload.msg
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.resetPassword.emailInfo.status =  "idle"
            state.resetPassword.emailInfo.message =  ""
            state.resetPassword.verifyInfo.status =  "idle"
            state.resetPassword.verifyInfo.message =  ""
            state.resetPassword.resetInfo.status =  "rejected"
            state.resetPassword.resetInfo.message =  action.payload.msg
            return state
        },
    }),
    createUser : createHandler({
        name: "user/create",
        method: "POST",
        route: "/user/crud",
        key: "user",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.createUserInfo.status = "pending"
            state.createUserInfo.message = "Creating user... Please wait."
            state.createUserInfo.submitted = true
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.createUserInfo.status = "fulfilled"
            state.createUserInfo.message = "User created succesfully."
            state.users = insertInHash(state.users, action.payload.user, "id")
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.createUserInfo.status = "rejected"
            state.createUserInfo.message = action.payload.msg
            return state
        }
    }),
    readUsers : createHandler({
        name: "user/read",
        method: "GET",
        route: "/user/crud",
        key: "",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.readUserInfo.status = "pending"
            state.readUserInfo.message = "Loading user(s)... Please wait."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.readUserInfo.status = "fulfilled"
            state.readUserInfo.message = "User(s) loaded succesfully."
            if (action.payload.hash_info.enable_hash === true) state.users = action.payload.users
            else 
                for (const user of action.payload.users) state.users = insertInHash(state.users, user, action.payload.hash_info.hash_key, action.payload.hash_info.hash_type)
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.readUserInfo.status = "rejected"
            state.readUserInfo.message = action.payload.msg
            return state
        }
    }),
    updateUser : createHandler({
        name: "user/update",
        method: "PUT",
        route: "/user/crud",
        key: "user",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.updateUserInfo.status = "pending"
            state.updateUserInfo.message = "Updating user... Please wait."
            state.updateUserInfo.submitted = true
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.updateUserInfo.status = "fulfilled"
            state.updateUserInfo.message = "User updated succesfully."
            if (action.payload.user.id === state.current.id) state.current = action.payload.user
            state.users = insertInHash(state.users, action.payload.user, "id")
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.updateUserInfo.status = "rejected"
            state.updateUserInfo.message = action.payload.msg
            return state
        }
    }),
    deleteUser : createHandler({
        name: "user/delete",
        method: "DELETE",
        route: "/user/crud",
        key: "user",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.deleteUserInfo.status = "pending"
            state.deleteUserInfo.message = "Deleting user... Please wait."
            state.deleteUserInfo.submitted = true
        },
        fulfilled: (state:SliceInterface, action) => {
            state.deleteUserInfo.status = "fulfilled"
            state.deleteUserInfo.message = "User deleted."
            if (action.payload.user.id === state.current.id) state.current = action.payload.user
            state.users = removeFromHash(state.users, null, "archived_id")
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.deleteUserInfo.status = "rejected"
            state.deleteUserInfo.message = action.payload.msg
            return state
        }
    }),
    readUserTypes : createHandler({
        name: "user/user_types/read",
        method: "GET",
        route: "/user/types/crud",
        key: "",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.userTypes.readInfo.status = "pending"
            state.userTypes.readInfo.message = "Loading user types... Please wait."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.userTypes.readInfo.status = "fulfilled"
            state.userTypes.readInfo.message = "User types loaded succesfully."
            state.userTypes.types = action.payload.user_types
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.userTypes.readInfo.status = "rejected"
            state.userTypes.readInfo.message = action.payload.msg
            
            return state
        }
    }),

}

export const [UserActions, UserReducers] = buildFromCreator(UserCreator)