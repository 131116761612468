import { IonCheckbox } from "@ionic/react"
import { HiPhotograph } from "react-icons/hi"

const handleSetCorrect = (question, option) => {
    if (question.is_multiple === 1) return [...question.options.filter(opt => opt.id !== option.id), { ...option, is_correct: option.is_correct === 1 ? 0 : 1 }].sort((a, b) => a.id > b.id ? 1 : -1)
    else return [...question.options.filter(opt => opt.id !== option.id).map(opt => ({ ...opt, is_correct: 0 })), { ...option, is_correct: option.is_correct === 1 ? 0 : 1 }].sort((a, b) => a.id > b.id ? 1 : -1)
}

const Option: React.FC<{ question, handleChange, option }> = ({ question, handleChange, option }) => {

    return (
        <div className="mb-4 rounded shadow p-5">
            <div className="flex flex-col gap-2">
                <label className="text-sm font-bold text-slate-600 ">Enter option text (optional if image is not included)</label>
                <input
                    className="px-2 py-1.5 bg-white  text-slate-800  rounded border-slate-200  border-2 outline-none focus:border-[#59A65F]"
                    type="text"
                    value={option.text}
                    name="text"
                    placeholder="Option Text"
                    onChange={e => {
                        handleChange({
                            target: {
                                name: "options",
                                value: [...question.options.filter(opt => opt.id !== option.id), { ...option, text: e.target.value }]
                            }
                        })
                    }}
                />
            </div>
            <div className="flex mt-3">
                <div className="flex items-center gap-2 cursor-pointer w-1/2">
                    <IonCheckbox
                        onIonChange={e => {
                            handleChange({
                                target: {
                                    name: "options",
                                    value: handleSetCorrect(question, option)
                                }
                            })
                        }} checked={option.is_correct === 1} slot="start" color={"success"}></IonCheckbox>
                    <p className="text-sm font-bold text-slate-600 ">Is Correct Choice</p>
                </div>

                <div className="relative flex w-1/2 items-center">
                    <label className="text-sm font-bold text-slate-600  mr-5">Select Question Photo (optional)</label>
                    <div>
                        <input onClick={e => {
                            if (option.image === null || typeof (option.image) === typeof ("")) return
                            e.preventDefault()
                            handleChange({
                                target: {
                                    name: "options",
                                    value: [...question.options.filter(opt => opt.id !== option.id), { ...option, image: null }]
                                }
                            })
                        }} type="file" name="image_path" onChange={e => handleChange({
                            target: {
                                name: "options",
                                value: [...question.options.filter(opt => opt.id !== option.id), { ...option, image: e.target.files[0] }]
                            }
                        })} className="absolute w-[50px] opacity-0 z-10 cursor-pointer" />
                        <HiPhotograph className={`w-[50px] z-0 text-3xl ${option.image === null || typeof (option.image) === typeof ("") ? "text-gray-500" : "text-[#043E61]"}`} />
                    </div>
                </div>
            </div>
            <button onClick={e => handleChange({
                target: {
                    name: "options",
                    value: question.options.filter(opt => opt.id !== option.id)
                }
            })} className="block mt-4 bg-red-500 text-white px-2 py-1 rounded shadow">Remove</button>
        </div>
    )
}

export default Option
