import { IonLoading, useIonAlert } from "@ionic/react"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { CareerQuizes } from "../../../common/Interfaces"
import { QuizActions } from "../../../Redux/Quizes/Actions"
import { QuizesState } from "../../../Redux/Quizes/Interface"
import { setQuizesState } from "../../../Redux/Quizes/Slice"
import { AppDispatch } from "../../../Redux/Store"
import QuestionCard from "../Cards/QuestionCard"
import DraggableParent from "../../../common/components/DraggableParent"
import { v4 as uuidv4 } from 'uuid';

let session = Math.random().toString(36).slice(2)

const CreateSegment: React.FC<{ current_quiz: CareerQuizes, QuizReducer: QuizesState, id: number }> = ({ current_quiz, QuizReducer, id }) => {
    const dispatch = useDispatch<AppDispatch>()

    const [segment, setSegment] = useState<{ id: number, quiz_id: number, name: string, questions: Array<number> }>({ id: 0, quiz_id: current_quiz.id, name: "", questions: [] })
    const [questioNFilter, setQuestioNFilter] = useState("")

    const [selected, setSelected] = useState([ ])
    const [items, setItems] = useState([...QuizReducer.quizQuestions.questions].filter(q => q !== null).map((q, i) => ({id:  uuidv4(), content: q})));


    const [ionAlert] = useIonAlert()

    useEffect(() => {
        session = Math.random().toString(36).slice(2)
        dispatch(setQuizesState({ quizSegments: { ...QuizReducer.quizSegments, createInfo: { ...QuizReducer.quizSegments.createInfo, submitted: false, session_id: session } } }))
    }, [])

    useEffect(() => {
        if (QuizReducer.quizSegments.createInfo.submitted !== true || QuizReducer.quizSegments.createInfo.session_id !== session) return
        if (QuizReducer.quizSegments.createInfo.status === "fulfilled") {
            ionAlert({ header: "Success", message: "Segment created successfully.", buttons: ["OK"] })
            setSegment({ id: 0, quiz_id: current_quiz.id, name: "", questions: [] })
        }
        if (QuizReducer.quizSegments.createInfo.status === "rejected") {
            ionAlert({ header: "Error!", message: QuizReducer.quizSegments.createInfo.message, buttons: ["OK"] })
        }
    }, [QuizReducer.quizSegments.createInfo.status])

    const handleSubmit = e => {
        e.preventDefault()
        if (segment.name.length < 3) return ionAlert({ header: "Error!", message: "Please enter a more descriptive segment name.", buttons: ["OK"] })
        if (segment.questions.length ===0) return ionAlert({ header: "Error!", message: "Please select at least one question by clicking on it.", buttons: ["OK"] })
        const data = new FormData() 
        data.append("quiz_id", current_quiz.id.toString())
        data.append("name", segment.name)
        data.append("questions", JSON.stringify(segment.questions))
        dispatch(QuizActions.createSegment({ segment: data }))
    }

    return (
        <div>
            <IonLoading isOpen={QuizReducer.quizSegments.createInfo.submitted === true && QuizReducer.quizSegments.createInfo.status === "pending"} message={QuizReducer.quizSegments.createInfo.message} />
            <div className="flex flex-col gap-2 mb-3">
                <label className="text-sm font-bold text-slate-600 ">Section Name</label>
                <input className="px-2 py-1.5 bg-white  text-slate-800  rounded border border-slate-200  border-2 outline-none focus:border-[#043E61]" type="text" value={segment.name} name="name" placeholder="Section One" onChange={e => setSegment({ ...segment, name: e.target.value })} />
            </div>
            <hr />

            <div className="flex w-full justify-between">

            <h1 className="mt-8 text-slate-700 font-bold text-lg mb-3 flex flex-col w-1/3">
                    Available Questions:
                    <input
                        className="px-2 py-1 bg-white text-slate-800 rounded border border-slate-200 border-2 outline-none focus:border-[#043E61]"
                        type="text"
                        value={questioNFilter}
                        name="name"
                        placeholder="Filter questions by name"
                        onChange={e => {
                            setQuestioNFilter(e.target.value);
                            dispatch(setQuizesState({ quizSegments: { ...QuizReducer.quizSegments, createInfo: { ...QuizReducer.quizSegments.createInfo, submitted: false, session_id: session } } }))
                        }}
                    />
                </h1>

                <h1 className="mt-8 text-slate-700 font-bold text-lg mb-3 flex flex-col w-1/3 text-right">
                    Selected Questions:
                    <input
                        className="px-2 py-1 bg-white text-slate-800 rounded border border-slate-200 border-2 outline-none focus:border-[#043E61]"
                        type="text"
                        value={questioNFilter}
                        name="name"
                        placeholder="Filter questions by name"
                        onChange={e => {
                            setQuestioNFilter(e.target.value);
                            dispatch(setQuizesState({ quizSegments: { ...QuizReducer.quizSegments, createInfo: { ...QuizReducer.quizSegments.createInfo, submitted: false, session_id: session } } }))
                        }}
                    />
                </h1>
            </div>

            <div className="grid grid-cols-2 flex-wrap items-start justify-between gap-2 mb-8 w-full">
                {/* <DraggableParent 
                    availableItems={items} 
                    selectedItems={selected} 
                    onOrderChange={e => {
                        setSelected(e)
                        const current_questions = e.map(item => item.id)
                        setItems([...items.filter(item => current_questions.includes(item.id) == false)])
                    }} 
                    segment={segment}
                    setSegment={setSegment}
                    setDispatch={() => dispatch(setQuizesState({ quizSegments: { ...QuizReducer.quizSegments, createInfo: { ...QuizReducer.quizSegments.createInfo, submitted: false, session_id: session } } }))}
                /> */}
                {QuizReducer.quizQuestions.questions
                    .map(q => q === null || (questioNFilter.length > 0 && !q.question.toLowerCase().startsWith(questioNFilter.toLowerCase())) ? null :
                        <QuestionCard
                            key={q.id}
                            question={q}
                            segment={segment}
                            setSegment={setSegment}
                            setDispatch={() => dispatch(setQuizesState({ quizSegments: { ...QuizReducer.quizSegments, createInfo: { ...QuizReducer.quizSegments.createInfo, submitted: false, session_id: session } } }))} 
                            _ref={undefined} 
                            draggableProps={{style: undefined}} 
                            dragHandleProps={undefined}                        
                        />)
                }
            </div>
            <button className="bg-slate-800 text-white px-8 py-1 rounded shadow-md hover:shadow-lg ml-auto block" onClick={e => handleSubmit(e)}>CREATE SECTION</button>
        </div>
    )
}

export default CreateSegment