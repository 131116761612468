import { useState } from "react"
import Select from "react-select"
import { UserState } from "../../../Redux/User/Interface"
import { useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../../Redux/Store"
import { UserCareers } from "../../../common/Interfaces"
import { useDispatch } from "react-redux"
import { CareerActions } from "../../../Redux/Careers/Actions"
import { IonLoading } from "@ionic/react"
import { CareersState } from "../../../Redux/Careers/Interface"

const EnrollUsers: React.FC<{ id: number, enrollments: Array<UserCareers> }> = ({ id, enrollments }) => {
    const dispatch = useDispatch<AppDispatch>()
    const UserReducer: UserState = useSelector((state: RootState) => state.user)
    const CareersReducer: CareersState = useSelector((state: RootState) => state.career)

    const [selected, setSelected] = useState(
        enrollments.map(en => ({
            value: en.user_id,
            label: UserReducer.users[en.user_id]?.first_name + " " + UserReducer.users[en.user_id]?.last_name + " (" + UserReducer.users[en.user_id]?.email + ")"
        }))
    )

    const [created, setCreated] = useState([])

    const enrollExisting = e => {
        e.preventDefault()
        const data = new FormData()
        data.append("career_id", id.toString())
        data.append("user_ids", JSON.stringify(selected.map(s => s.value)))
        dispatch(CareerActions.massEnrollUsers({ career_users: data }))
    }

    const handleChangeCreate = (e, idx) => {
        if (idx === created.length) setCreated([{ email: "", first_name: "", last_name: "", phone_number: "", [e.target.name]: e.target.value }])
        else {
            let new_created = [...created]
            new_created[idx][e.target.name] = e.target.value
            setCreated(new_created)
        }
    }
    console.log(created)

    const handleCreateAndEnroll = e => {
        e.preventDefault()
        const data = new FormData()
        data.append("career_id", id.toString())
        data.append("users_info", JSON.stringify(created))
        dispatch(CareerActions.massEnrollUsers({ career_users: data }))
    }

    return (
        <div>
            <IonLoading isOpen={CareersReducer.userCareers.createInfo.status === "pending"} message={CareersReducer.userCareers.createInfo.message} />
            <div className="mt-4 w-full bg-white rounded shadow p-4">
                <h1 className="text-lg text-[#043e61] font-bold mb-3">SELECT FROM EXISING USERS</h1>
                <div>
                    <label className="text-[#043e61]">Select users</label>
                    <Select
                        isMulti
                        name="users"
                        value={selected}
                        onChange={e => {
                            setSelected([...e])
                        }}
                        options={UserReducer.users.filter(u => u !== null && u.type_id === 1).map(u => ({ label: u.first_name + " " + u.last_name + " (" + u.email + ")", value: u.id }))}
                        className="text-[#043e61] w-full"
                        classNamePrefix="select"
                    />
                </div>
                <button className="block ml-auto bg-[#043e61] px-2 py-1 text-white mt-4 rounded-md" onClick={e => enrollExisting(e)}> UPDATE </button>
            </div>
            <h1 className="text-center my-3 font-bold text-[#043e61] text-xl">OR</h1>
            <div className="mt-4 w-full bg-white rounded shadow p-4">
                <h1 className="text-lg text-[#043e61] font-bold mb-3">INSERT AND ENROLL NEW USERS</h1>
                {[...created, undefined].map((u, idx) => (<div className="mb-3">
                    <label className="text-[#043e61]">Enter user details</label>
                    <span className="grid grid-cols-2 md:grid-cols-4 gap-1">
                        <input className="p-1 bg-white focus:outline-none border border-slate-300" type="email" name="email" placeholder="Email i.e. user@gmail.com" value={u === undefined ? "" : u.email} onChange={e => handleChangeCreate(e, idx)} />
                        <input className="p-1 bg-white focus:outline-none border border-slate-300" type="text" name="first_name" placeholder="Name i.e. John" value={u === undefined ? "" : u.first_name} onChange={e => handleChangeCreate(e, idx)} />
                        <input className="p-1 bg-white focus:outline-none border border-slate-300" type="text" name="last_name" placeholder="Surname i.e. Spencer" value={u === undefined ? "" : u.last_name} onChange={e => handleChangeCreate(e, idx)} />
                        <input className="p-1 bg-white focus:outline-none border border-slate-300" type="text" name="phone_number" placeholder="Phone Number i.e. +1 111 222 333 4444" value={u === undefined ? "" : u.phone_number} onChange={e => handleChangeCreate(e, idx)} />
                    </span>
                </div>))}
                <button className="block ml-auto bg-[#043e61] px-2 py-1 text-white mt-4 rounded-md" onClick={e => handleCreateAndEnroll(e)}>ENROLL</button>
            </div>
        </div>
    )
}

export default EnrollUsers