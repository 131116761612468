import { IonLoading, IonModal } from "@ionic/react"
import { useEffect, useState } from "react"
import { BsFillArrowLeftSquareFill } from "react-icons/bs"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import { NavLink } from "react-router-dom"
import { STATIC_URL } from "../../common/constants"
import { CareerQuizes, QuizSegments } from "../../common/Interfaces"
import { QuizActions } from "../../Redux/Quizes/Actions"
import { QuizesState } from "../../Redux/Quizes/Interface"
import { AppDispatch, RootState } from "../../Redux/Store"

const getQuestiosnInfo = (segments: Array<QuizSegments>, reducer: QuizesState) => {
    const questions = []
    let totalDuration = 0
    for (const segment of segments) {
        for (const question of segment.questions) {
            let q = reducer.quizQuestions.questions.find(q => q !== null && q.id === question.question_id)
            if (q === undefined || q.archived === 1) continue
            questions.push(q)
            totalDuration += q.duration
        }
    }
    return {
        questions,
        totalDuration
    }
}


const QuizPage: React.FC = () => {
    const id: number = parseInt(useParams<{ id: string }>().id)
    const dispatch = useDispatch<AppDispatch>()
    const [readMore, setReadMore] = useState(false)
    const QuizReducer: QuizesState = useSelector((state: RootState) => state.quiz)

    useEffect(() => {
        dispatch(QuizActions.readQuizes({ arguments: { enable_hash: true } }))
        dispatch(QuizActions.readQuestions({ arguments: { enable_hash: true } }))
    }, [])

    const current: CareerQuizes = QuizReducer.careerQuizes.quizes[id]
    const currentInfo = getQuestiosnInfo(current.segments, QuizReducer)

    return (
        <div>
            <IonLoading key="quiz-page-loading" isOpen={QuizReducer.careerQuizes.readInfo.status === "pending"} message={QuizReducer.careerQuizes.readInfo.message} />
            <div className="pt-24 pb-24 max-h-[350px] bg-[#1881fd] bg-[url('https://app.aptidext.com/assets/images/quiz-page-study.gif')] bg-no-repeat bg-contain bg-center rounded-b-2xl bg-fixed">
                <div className="w-[800px] max-w-[90%] mx-auto pt-1 p-4 mt-36 mb-12 bg-white  rounded-lg shadow-md">
                    <span className="flex mb-2 items-center mt-2">
                        <NavLink to={"/career/"+current.career_id} className="text-[#1881fd] cursor-pointer mr-2 text-xl">
                            <BsFillArrowLeftSquareFill />
                        </NavLink>
                        <h1 className="text-xl font-bold text-slate-800 mb-1">{current.name}</h1>
                    </span>
                    <p><img src={STATIC_URL + current.cover_photo} className="w-[45px] h-[45px] mr-2 shadow float-left" /> {current.description.substring(0, 120)} {current.description.length > 120 ? <span onClick={e => setReadMore(true)} className="text-slate-600 cursor-pointer">read more</span> : null}</p>
                </div>
            </div>
            <IonModal isOpen={readMore} onWillDismiss={e => setReadMore(false)}>
                <div className="w-full h-full overflow-y-scroll px-6 py-4 md:p-12 bg-slate-700">
                    <h1 className="font-bold text-xl mb-8">{current.name}</h1>
                    <p className="text-justify"><img src={STATIC_URL + current.cover_photo} className="w-[88px] h-[88px] mr-2 shadow float-left" />{current.description}</p>
                    <button onClick={e => setReadMore(false)} className="mt-4 block mx-auto bg-slate-400 px-4 py-1 font-bold text-slate-900 rounded-md">CLOSE</button>
                </div>
            </IonModal>
            <div className="w-[800px] max-w-[90%] mx-auto p-4 mt-24  md:mt-24 mb-12 bg-white  rounded-lg shadow-md">
                <h1 className="text-xl font-bold text-slate-800 mb-3">Before you begin the quiz</h1>
                <p className="text-slate-800 mb-2 text-justify">Before starting this quiz, please take the time to read the following information in order to better understand the types of questions you will be answering.</p>
                <p className="text-slate-800 mb-2 text-justify">Each quiz is comprised of at least one section, and each section is comprised of at least one question. The question can be: </p>
                <ul className="mb-3">
                    <li className="text-slate-800 ml-7 list-disc">Single Choice</li>
                    <li className="text-slate-800 ml-7 list-disc">Multiple Choice</li>
                    <li className="text-slate-800 ml-7 list-disc">Writing Short Essays</li>
                    <li className="text-slate-800 ml-7 list-disc">Sliders</li>
                </ul>
                <p className="text-slate-800 mb-2 text-justify">The quiz you've selected in particular is comprosed of {current.segments.length} segment(s) and {currentInfo.questions.length} question(s). The time to complete this quiz is approximately {(currentInfo.totalDuration / 60).toFixed(2)} minute(s), so we suggest that you sit tight in a comfortable place where you can focus while solving the quiz.</p>
                <p className="text-slate-800 mb-2 text-justify">It is important to note that the system can track if the user exits and re-enters the application and upon doing so, the session ends and the quiz is marked as failed, so please refrain from putting the application in background or exiting the application while taking the exam.</p>
                <div className="flex items-center justify-center mt-5">
                    <NavLink to={"/start_quiz/"+current.id} className="bg-[#1881fd] text-white font-bold px-3 py-1.5 rounded shadow hover:shadow-lg cursor-pointer">GO TO QUIZ</NavLink>
                </div>
            </div>
        </div>
    )
}

export default QuizPage