import { useEffect, useState } from "react";
import { BsCheckLg } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import ReactSlider from "react-slider";
import { STATIC_URL } from "../../common/constants";
import { CareerQuizes, QuizQuestion, User } from "../../common/Interfaces";
import { AnswerActions } from "../../Redux/Answers/Actions";
import { AppDispatch } from "../../Redux/Store";
import "./Answer.css"

const AnswerContainer: React.FC<{ question: QuizQuestion, setQuestion: Function, selected: number, quiz: CareerQuizes, user: User, session_id: string }> = ({ question, setQuestion, selected, quiz, user, session_id }) => {
    const dispatch = useDispatch<AppDispatch>()
    const [counter, setCounter] = useState(question?.duration);

    const [answer, setAnswer] = useState<{
        text: string | null,
        segment: number | null,
        options: Array<number>,
        start: Date
    }>({
        text: "",
        segment: 0,
        options: [],
        start: new Date(),
    })
    const handleSubmit = e => {
        const data = new FormData()
        const end: Date = new Date()
        const time_taken = (end.valueOf() - answer.start.valueOf()) / 1000
        data.append("date", answer.start.toISOString().slice(0, 19).replace('T', ' '))
        data.append("time_taken", time_taken.toString())
        data.append("question_id", question.id.toString())
        data.append("user_id", user.id.toString())
        data.append("quiz_id", quiz.id.toString())
        data.append("session_id", session_id)
        data.append("segment_id", question.segment_id)

        if (question.type === "essay") data.append("answer_text", answer.text)
        if (question.type === "choice") data.append("answer_option_ids", JSON.stringify(answer.options))
        if (question.type === "slider") data.append("answer_slider_segment", answer.segment.toString())
        dispatch(AnswerActions.createAnswer({ answer: data }))
        setQuestion(selected + 1)
    }

    useEffect(() => {
        const timer = counter > 0 && setInterval(() => setCounter(counter - 0.1), 100);
        if (counter <= 0) {
            handleSubmit({})
        }
        return () => clearInterval(timer);
    }, [counter]);

    useEffect(() => {
        setCounter(question?.duration)
        setAnswer({
            text: "",
            segment: 0,
            options: [],
            start: new Date(),
        })
    }, [question])

    if (question === undefined) return (
        <div className="h-screen flex flex-col items-center justify-center max-w-[420px] mx-auto">
            <h1 className="font-bold text-2xl text-[#F7C01D] text-center -mt-12">CONGRATULATIONS</h1>
            <img src="https://app.aptidext.com/assets/images/congradulations.png" />

            <NavLink className="bg-[#F7C01D] text-slate-800 font-bold px-2 py-1/2 rounded block mx-auto mt-3 w-1/3 text-center py-1" to={"/quiz/" + quiz.id}>GO BACK</NavLink>
        </div>
    )
    return (
        <div key={question.id} className="max-w-[420px] mx-auto md:pt-40 h-screen pb-24 overflow-y-scroll p-4">
            <div className="mb-1 text-base font-medium text-[#F7C01D] flex justify-between items-center">Remaining Time: <p>{counter.toFixed(1)} s</p></div>
            <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                <div className="bg-[#F7C01D] h-2.5 rounded-full dark:bg--[#F7C01D] animate-timer" style={{ "width": ((100 / question?.duration) * counter) + "%" }}></div>
            </div>
            <h1 className="font-bold text-slate-300 mt-4 text-justify">{question?.question}</h1>
            {question.image_path === null || question.image_path === "null" ? null : <img className="w-[60%] h-fill mx-auto my-4 border border-4 border-[#F7C01D]" src={STATIC_URL + question.image_path} />}

            {question.type === "essay" ? <div>
                <textarea className="my-4 px-2 py-1.5 min-h-[250px] w-full bg-slate-700 opacity-70 text-slate-100 rounded border-slate-300 border-2 outline-none focus:border-[#F7C01D]"
                    value={answer.text === null ? "" : answer.text}
                    name="text"
                    placeholder="Write your answer..."
                    onChange={e => setAnswer({ ...answer, text: e.target.value })}
                />
            </div> : null}
            {question.type === "slider" ? <div className="w-[100%] mx-auto my-8">
                <div className="mb-4 p-3 bg-slate-700 opacity-70 h-[45px] rounded-lg">
                    <ReactSlider
                        marks
                        className="customSlider in-answer"
                        trackClassName="customSlider-track"
                        min={1}
                        value={answer.segment}
                        onChange={e => setAnswer({ ...answer, segment: e })}
                        max={question.data.segments}
                        renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                    />
                </div>
                <div className="flex items-center justify-between capitalize mt-2">
                    <span className="w-1/3 text-left">
                        <p className="bg-slate-700 rounded-md px-2 py-1 inline text-center">{question.data.word_least}</p>
                    </span>
                    <span className="w-1/3 text-center">
                        <p className="bg-slate-700 rounded-md px-2 py-1 inline text-center">Neutral</p>
                    </span>
                    <span className="w-1/3 text-right">
                        <p className="bg-slate-700 rounded-md px-2 py-1 inline text-center">{question.data.word_most}</p>
                    </span>
                </div>
            </div> : null}

            {question.type === "choice" ? <div className="w-[100%] mx-auto my-8">
                {question.data.options.map(opt =>
                    <div className={`
                        my-4 p-3 w-full bg-[rgba(51,65,85,0.8)] text-slate-100 rounded-lg border-slate-300 border-2 cursor-pointer flex items-center
                        ${answer.options.includes(opt.id) ? " !bg-[rgba(9,64,29,0.55)]" : ""}
                    `} onClick={(e) => {
                            if (question.data.is_multiple === 1) setAnswer({ ...answer, options: answer.options.includes(opt.id) ? answer.options.filter(o => o !== opt.id) : [...answer.options, opt.id] })
                            else setAnswer({ ...answer, options: [opt.id] })
                        }}>
                        <span className="ml-1 w-[90%]">
                            {opt.image_path !== null ? <img className="w-1/2 md:w-1/3" src={STATIC_URL + opt.image_path} /> : null}
                            {opt.text}
                        </span>
                    </div>
                )}
            </div> : null}

            <button className="bg-[#F7C01D] text-slate-800 font-bold px-2 py-1/2 rounded block mx-auto mt-3" onClick={e => handleSubmit(e)}>SUBMIT</button>
        </div>
    )
}

export default AnswerContainer