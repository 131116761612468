import { insertInHash, removeFromHash } from "../../common/functions"
import { createHandler, buildFromCreator } from "../ReduxTemplates"
import { QuizesState as SliceInterface } from "./Interface"


export const QuizCreator = {
    createQuiz : createHandler({
        name: "quizes/create_quiz",
        method: "POST",
        route: "/quiz/crud",
        key: "quiz",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.careerQuizes.createInfo.status = "pending"
            state.careerQuizes.createInfo.message = "Creating quiz... Please wait."
            state.careerQuizes.createInfo.submitted = true
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.careerQuizes.createInfo.status = "fulfilled"
            state.careerQuizes.createInfo.message = "Quiz created succesfully."
            state.careerQuizes.quizes = insertInHash(state.careerQuizes.quizes, action.payload.quiz, "id")
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.careerQuizes.createInfo.status = "rejected"
            state.careerQuizes.createInfo.message = action.payload.msg
            return state
        }
    }),
    readQuizes : createHandler({
        name: "quizes/read_quizes",
        method: "GET",
        route: "/quiz/crud",
        key: "",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.careerQuizes.readInfo.status = "pending"
            state.careerQuizes.readInfo.message = "Loading quizes... Please wait."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.careerQuizes.readInfo.status = "fulfilled"
            state.careerQuizes.readInfo.message = "Quizes loaded succesfully."
            if (action.payload.hash_info.enable_hash === true) state.careerQuizes.quizes = action.payload.quizes
            else
                for (const quiz of action.payload.quizes) state.careerQuizes.quizes = insertInHash(state.careerQuizes.quizes, quiz, "id")
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.careerQuizes.readInfo.status = "rejected"
            state.careerQuizes.readInfo.message = action.payload.msg
            return state
        }
    }),
    updateQuiz : createHandler({
        name: "quizes/update_quiz",
        method: "PUT",
        route: "/quiz/crud",
        key: "quiz",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.careerQuizes.updateInfo.status = "pending"
            state.careerQuizes.updateInfo.message = "Updating quiz... Please wait."
            state.careerQuizes.updateInfo.submitted = true
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.careerQuizes.updateInfo.status = "fulfilled"
            state.careerQuizes.updateInfo.message = "Quiz updated succesfully."
            state.careerQuizes.quizes = insertInHash(state.careerQuizes.quizes, action.payload.quiz, "id")
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.careerQuizes.updateInfo.status = "rejected"
            state.careerQuizes.updateInfo.message = action.payload.msg
            return state
        }
    }),
    deleteQuiz : createHandler({
        name: "quizes/delete_quiz",
        method: "DELETE",
        route: "/quiz/crud",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.careerQuizes.deleteInfo.status = "pending"
            state.careerQuizes.deleteInfo.message = "Deleting quiz... Please wait."
            state.careerQuizes.deleteInfo.submitted = true
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.careerQuizes.deleteInfo.status = "fulfilled"
            state.careerQuizes.deleteInfo.message = "Quiz deleted."
            state.careerQuizes.quizes = insertInHash(state.careerQuizes.quizes, action.payload.quiz, "id")
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.careerQuizes.deleteInfo.status = "rejected"
            state.careerQuizes.deleteInfo.message = action.payload.msg
            return state
        }
    }),
    createSegment : createHandler({
        name: "quizes/create_segment",
        method: "POST",
        route: "/quiz/segments/crud",
        key: "segment",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.quizSegments.createInfo.status = "pending"
            state.quizSegments.createInfo.message = "Creating segment... Please wait."
            state.quizSegments.createInfo.submitted = true
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.quizSegments.createInfo.status = "fulfilled"
            state.quizSegments.createInfo.message = "Segment created succesfully."
            state.careerQuizes.quizes[action.payload.segment.quiz_id].segments = [...state.careerQuizes.quizes[action.payload.segment.quiz_id].segments, action.payload.segment]
            state.quizSegments.segments = insertInHash(state.quizSegments.segments, action.payload.segment, "id")
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.quizSegments.createInfo.status = "rejected"
            state.quizSegments.createInfo.message = action.payload.msg
            return state
        }
    }),
    readSegments : createHandler({
        name: "quizes/read_segments",
        method: "GET",
        route: "/quiz/segments/crud",
        key: "",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.quizSegments.readInfo.status = "pending"
            state.quizSegments.readInfo.message = "Loading segments... Please wait."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.quizSegments.readInfo.status = "fulfilled"
            state.quizSegments.readInfo.message = "Segments loaded succesfully."
            if (action.payload.hash_info.enable_hash === true) state.quizSegments.segments = action.payload.segments
            else
                for (const seg of action.payload.segments) state.quizSegments.segments = insertInHash(state.quizSegments.segments, seg, "id")

            for (const segment of action.payload.segments) if (segment !== null) state.careerQuizes.quizes[segment.quiz_id].segments = [...state.careerQuizes.quizes[segment.quiz_id].segments.filter(s => s !== null && s.id !==  segment.id), segment]
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.quizSegments.readInfo.status = "rejected"
            state.quizSegments.readInfo.message = action.payload.msg
            return state
        }
    }),
    updateSegment : createHandler({
        name: "quizes/update_segment",
        method: "PUT",
        route: "/quiz/segments/crud",
        key: "segment",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.quizSegments.updateInfo.status = "pending"
            state.quizSegments.updateInfo.message = "Updating segment... Please wait."
            state.quizSegments.updateInfo.submitted = true
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.quizSegments.updateInfo.status = "fulfilled"
            state.quizSegments.updateInfo.message = "Segment updated succesfully."
            state.quizSegments.segments = insertInHash(state.quizSegments.segments, action.payload.segment, "id")
            state.careerQuizes.quizes[action.payload.segment.quiz_id].segments = [...state.careerQuizes.quizes[action.payload.segment.quiz_id].segments.filter(s => s !== null && s.id !==  action.payload.segment.id), action.payload.segment]
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.quizSegments.updateInfo.status = "rejected"
            state.quizSegments.updateInfo.message = action.payload.msg
            return state
        }
    }),
    deleteSegment : createHandler({
        name: "quizes/delete_segment",
        method: "DELETE",
        route: "/quiz/segments/crud",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.quizSegments.deleteInfo.status = "pending"
            state.quizSegments.deleteInfo.message = "Deleting segment... Please wait."
            state.quizSegments.deleteInfo.submitted = true
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.quizSegments.deleteInfo.status = "fulfilled"
            state.quizSegments.deleteInfo.message = "Segment deleted."
            state.quizSegments.segments = insertInHash(state.quizSegments.segments, action.payload.segment, "id")
            state.careerQuizes.quizes[action.payload.segment.quiz_id].segments = [...state.careerQuizes.quizes[action.payload.segment.quiz_id].segments.filter(s => s !== null && s.id !==  action.payload.segment.id), action.payload.segment]
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.quizSegments.deleteInfo.status = "rejected"
            state.quizSegments.deleteInfo.message = action.payload.msg
            return state
        }
    }),
    createQuestion : createHandler({
        name: "quizes/create_question",
        method: "POST",
        route: "/quiz/questions/crud",
        key: "question",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.quizQuestions.createInfo.status = "pending"
            state.quizQuestions.createInfo.message = "Creating question... Please wait."
            state.quizQuestions.createInfo.submitted = true
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.quizQuestions.createInfo.status = "fulfilled"
            state.quizQuestions.createInfo.message = "Question created succesfully."
            state.quizQuestions.questions = insertInHash(state.quizQuestions.questions, action.payload.question, "id")
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.quizQuestions.createInfo.status = "rejected"
            state.quizQuestions.createInfo.message = action.payload.msg
            return state
        }
    }),
    readQuestions : createHandler({
        name: "quizes/read_question",
        method: "GET",
        route: "/quiz/questions/crud",
        key: "",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.quizQuestions.readInfo.status = "pending"
            state.quizQuestions.readInfo.message = "Loading questions... Please wait."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.quizQuestions.readInfo.status = "fulfilled"
            state.quizQuestions.readInfo.message = "Questions loaded succesfully."
            if (action.payload.hash_info.enable_hash === true) state.quizQuestions.questions = action.payload.questions
            else
                for (const question of action.payload.questions) state.quizQuestions.questions = insertInHash(state.quizQuestions.questions, question, "id")
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.quizQuestions.readInfo.status = "rejected"
            state.quizQuestions.readInfo.message = action.payload.msg
            return state
        }
    }),
    updateQuestion : createHandler({
        name: "quizes/update_question",
        method: "PUT",
        route: "/quiz/questions/crud",
        key: "question",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.quizQuestions.updateInfo.status = "pending"
            state.quizQuestions.updateInfo.message = "Updating question... Please wait."
            state.quizQuestions.updateInfo.submitted = true
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.quizQuestions.updateInfo.status = "fulfilled"
            state.quizQuestions.updateInfo.message = "Question updated succesfully."
            state.quizQuestions.questions = insertInHash(state.quizQuestions.questions, action.payload.question, "id")
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.quizQuestions.updateInfo.status = "rejected"
            state.quizQuestions.updateInfo.message = action.payload.msg
            return state
        }
    }),
    deleteQuestion : createHandler({
        name: "quizes/delete_question",
        method: "DELETE",
        route: "/quiz/questions/crud",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.quizQuestions.deleteInfo.status = "pending"
            state.quizQuestions.deleteInfo.message = "Deleting question... Please wait."
            state.quizQuestions.deleteInfo.submitted = true
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.quizQuestions.deleteInfo.status = "fulfilled"
            state.quizQuestions.deleteInfo.message = "Question deleted."
            state.quizQuestions.questions = insertInHash(state.quizQuestions.questions, action.payload.question, "id")
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.quizQuestions.deleteInfo.status = "rejected"
            state.quizQuestions.deleteInfo.message = action.payload.msg
            return state
        }
    })
}

export const [QuizActions, QuizsReducers] = buildFromCreator(QuizCreator)