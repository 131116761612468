import { useParams } from "react-router"
import Login from "./Login"
import Register from "./Register"

const AuthContainer: React.FC = () => {
    const type: string = useParams<{ type: string }>().type
    return (
        <div>
            <img src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fcdn.dribbble.com%2Fusers%2F193682%2Fscreenshots%2F3123334%2Ffix-dribbble.gif&f=1&nofb=1&ipt=b9478a372b3c557d36266f6b48c8109db957f58b3215c88f02d697739999e40a&ipo=images" className="absolute inset-0 z-0 h-[75%] md:h-full w-full object-cover" />
            <div className="absolute inset-0 z-0 h-full w-full bg-black/10"></div>

            {type === "login" ? <Login /> : type === "register" ? <Register /> : null}
            
        </div>
    )
}

export default AuthContainer