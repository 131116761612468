import { IonLoading, useIonAlert } from "@ionic/react"
import { useEffect, useState } from "react"
import { BsFillArrowLeftSquareFill } from "react-icons/bs"
import { useDispatch, useSelector } from "react-redux"
import { NavLink } from "react-router-dom"
import { QuizActions } from "../../../Redux/Quizes/Actions"
import { QuizesState } from "../../../Redux/Quizes/Interface"
import { setQuizesState } from "../../../Redux/Quizes/Slice"
import { AppDispatch, RootState } from "../../../Redux/Store"
import ChoiceForm from "./ChoiceForm"
import EssayForm from "./EssayForm"
import SliderForm from "./SliderForm"
import QuizQuestion from "../../Careers/QuizComponents/QuizQuestion"

let session = Math.random().toString(36).slice(2)

const CreateQuestionForm: React.FC<{}> = ({ }) => {
    const dispatch = useDispatch<AppDispatch>()
    const QuizesReducer: QuizesState = useSelector((state: RootState) => state.quiz)
    const [ionAlert] = useIonAlert()
    const [question, setQuestion] = useState<any>({
        question: "",
        points: null,
        duration: null,
        image_path: null,
        type: "slider",

        segments: null,
        prefered_segment: null,
        word_least: "",
        word_most: ""
    })

    useEffect(() => {
        session = Math.random().toString(36).slice(2)
        dispatch(setQuizesState({ quizQuestions: { ...QuizesReducer.quizQuestions, createInfo: { ...QuizesReducer.quizQuestions.createInfo, submitted: false, session_id: session } } }))
    }, [])

    useEffect(() => {
        if (QuizesReducer.quizQuestions.createInfo.submitted !== true || QuizesReducer.quizQuestions.createInfo.session_id !== session) return
        if (QuizesReducer.quizQuestions.createInfo.status === "fulfilled") {
            ionAlert({ header: "Success", message: "Quiz created successfully.", buttons: ["OK"] })
            // setQuestion({
            //     question: "",
            //     points: 0,
            //     duration: 0,
            //     image_path: null,

            //     type: "slider",
            //     segments: 0,
            //     prefered_segment: 0,
            //     word_least: "",
            //     word_most: ""
            // })
            session = Math.random().toString(36).slice(2)
            dispatch(setQuizesState({ quizQuestions: { ...QuizesReducer.quizQuestions, createInfo: { ...QuizesReducer.quizQuestions.createInfo, session_id: session } } }))
        }
        if (QuizesReducer.quizQuestions.createInfo.status === "rejected") {
            ionAlert({ header: "Error!", message: QuizesReducer.quizQuestions.createInfo.message, buttons: ["OK"] })
        }
    }, [QuizesReducer.quizQuestions.createInfo.status])

    const handleChangeType = e => {
        if (e.target.value === "slider") {
            setQuestion({
                question: "",
                points: null,
                duration: null,
                image_path: null,
                type: e.target.value,
                segments: null,
                prefered_segment: null,
                word_least: "",
                word_most: ""
            })
        } else if (e.target.value === "essay") {
            setQuestion({
                question: "",
                points: null,
                duration: null,
                image_path: null,
                type: e.target.value,
                max_characters: 0
            })
        }
        else if (e.target.value === "choices") {
            setQuestion({
                question: "",
                points: null,
                duration: null,
                image_path: null,
                type: e.target.value,
                is_multiple: 0,
                options: [{
                    id: Math.random().toString(36).slice(2),
                    is_correct: 1,
                    text: "Example Option",
                    image: null
                }]
            })
        }
    }

    const handleChange = e => {
        if (e.target.name === "image_path") setQuestion({ ...question, image_path: e.target.files[0] })
        else if (e.target.name === "is_multiple" && e.target.value === 0) setQuestion({ ...question, is_multiple: 0, options: question.options.map(opt => ({ ...opt, is_correct: 0 })) })
        else setQuestion({ ...question, [e.target.name]: e.target.value })
        dispatch(setQuizesState({ quizQuestions: { ...QuizesReducer.quizQuestions, createInfo: { ...QuizesReducer.quizQuestions.createInfo, submitted: false } } }))
    }

    const handleSubmit = e => {
        e.preventDefault()
        const data = new FormData()
        data.append("question", question.question)
        data.append("points", question.points)
        data.append("duration", question.duration)
        data.append("image_path", question.image_path)
        data.append("question_type", question.type)
        if (question.type === "slider") {
            data.append("segments", question.segments)
            data.append("prefered_segment", question.prefered_segment)
            data.append("word_least", question.word_least)
            data.append("word_most", question.word_most)
        } else if (question.type === "essay") {
            data.append("max_characters", question.max_characters)
        } else if (question.type === "choices") {
            data.append("is_multiple", question.is_multiple)
            data.append("options", JSON.stringify(question.options.sort((a, b) => a.id > b.id ? 1 : -1).map(opt => ({ ...opt, image_path: opt.image === null ? false : opt.id }))))
            for (const option of question.options)
                if (option.image !== null) data.append(option.id, option.image)
        }
        dispatch(QuizActions.createQuestion({ question: data }))
    }

    console.log(question)

    return (
        <div>
            <IonLoading isOpen={QuizesReducer.quizQuestions.createInfo.submitted === true && QuizesReducer.quizQuestions.createInfo.status === "pending"} message={QuizesReducer.quizQuestions.createInfo.message} />
            <div className="
                bg-[url('https://app.aptidext.com/assets/images/questions.png')] 
                bg-[#59A65F] bg-no-repeat bg-contain bg-center bg-fixed
                pt-16 pb-12 max-h-[250px] rounded-b-2xl
            ">
                <div className="w-[800px] max-w-[90%] mx-auto p-4 mt-36 mb-12 bg-white  rounded-lg shadow-md">
                    <h1 className="text-xl font-bold text-slate-700 mb-1 flex gap-2 items-center">
                        <NavLink to={"/questions"} className="text-[#59A65F] cursor-pointer">
                            <BsFillArrowLeftSquareFill />
                        </NavLink>
                        Create New Question
                    </h1>
                    <div className="flex flex-col gap-2 w-full mb-3">
                        <label className="text-sm font-bold text-slate-600 ">Select Question Type</label>
                        <select
                            onChange={e => handleChangeType(e)}
                            value={question.type}
                            className="px-2 py-1.5 bg-white text-slate-800 rounded border-slate-200  border-2 outline-none focus:border-[#59A65F]"
                        >
                            {["slider", "choices", "essay"].map(item => <option value={item} className="capitalize">{item}</option>)}
                        </select>
                    </div>
                    <div className="flex flex-col gap-2 mb-3">
                        <label className="text-sm font-bold text-slate-600 ">Enter Question</label>
                        <textarea className="px-2 py-1.5 min-h-[100px] bg-white  text-slate-800  rounded border-slate-200 border-2 outline-none focus:border-[#59A65F]"
                            value={question.question}
                            name="question"
                            placeholder="How would you repair the damaged vehicle part shown on the picture?"
                            onChange={e => handleChange(e)}
                        />
                    </div>
                    <div className="mb-3 flex flex-row gap-2">
                        <div className="flex flex-col gap-2 w-1/2">
                            <label className="text-sm font-bold text-slate-600 ">Enter Points for correct answer</label>
                            <input
                                className="px-2 py-1.5 bg-white  text-slate-800  rounded border-slate-200  border-2 outline-none focus:border-[#59A65F]"
                                type="number"
                                value={question.points}
                                name="points"
                                placeholder="8"
                                onChange={e => handleChange(e)}
                            />
                        </div>
                        <div className="flex flex-col gap-2 w-1/2">
                            <label className="text-sm font-bold text-slate-600 ">Enter Duration (in seconds)</label>
                            <input
                                className="px-2 py-1.5 bg-white  text-slate-800  rounded border-slate-200  border-2 outline-none focus:border-[#59A65F]"
                                type="number"
                                value={question.duration}
                                name="duration"
                                placeholder="90"
                                onChange={e => handleChange(e)}
                            />
                        </div>
                    </div>
                    {question.type === "slider" ? <SliderForm question={question} handleChange={handleChange} /> : null}
                    {question.type === "choices" ? <ChoiceForm question={question} handleChange={handleChange} /> : null}
                    {question.type === "essay" ? <EssayForm question={question} handleChange={handleChange} /> : null}
                    <QuizQuestion question={{
                        ...question,
                        data: question.type === "choices" ? { 
                            options: question.options.sort((a, b) => a.id > b.id ? 1 : -1),
                            is_multiple: question.is_multiple,
                        } : question.type === "slider" ? {
                            prefered_segment: question.prefered_segment,
                            segments: question.segments,
                            word_least: question.word_least,
                            word_most: question.word_most,
                        } : { max_characters: question.max_characters }
                    }} setOpen={() => { }} />
                    <button onClick={e => handleSubmit(e)} className="bg-[#59A65F] text-white px-8 py-1 rounded shadow-md hover:shadow-lg mt-4">SUBMIT</button>
                </div>
            </div>
        </div>
    )
}

export default CreateQuestionForm