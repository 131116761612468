import { NavLink } from "react-router-dom"
import { STATIC_URL } from "../../../common/constants"
import { Career, CareerQuizes } from "../../../common/Interfaces"


const CareerCard: React.FC<{ career: Career, quizes: Array<CareerQuizes> }> = ({ career, quizes }) => (
    <NavLink to={"/career/" + career.id} className="p-4 bg-white  shadow flex rounded">
        <img src={STATIC_URL + career.cover_photo} className="w-16 h-16 mr-2 rounded-md shadow border border-slate-700" />
        <div className="h-12 flex flex-col justify-between">
            <p className="text-slate-900  font-bold text-sm">{career.name}</p>
            <p className="text-slate-700  text-sm">{quizes.length} available assessments</p>
        </div>
    </NavLink>
)

export default CareerCard