import { insertInHash, removeFromHash } from "../../common/functions"
import { createHandler, buildFromCreator } from "../ReduxTemplates"
import { CareersState as SliceInterface } from "./Interface"


export const CareerCreator = {
    createCareer : createHandler({
        name: "careers/create_career",
        method: "POST",
        route: "/career/crud",
        key: "career",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.career.createInfo.status = "pending"
            state.career.createInfo.message = "Creating career... Please wait."
            state.career.createInfo.submitted = true
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.career.createInfo.status = "fulfilled"
            state.career.createInfo.message = "Career created succesfully."
            state.career.careers = insertInHash(state.career.careers, action.payload.career, "id")
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.career.createInfo.status = "rejected"
            state.career.createInfo.message = action.payload.msg
            return state
        }
    }),
    readCareers : createHandler({
        name: "careers/read_career",
        method: "GET",
        route: "/career/crud",
        key: "",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.career.readInfo.status = "pending"
            state.career.readInfo.message = "Loading career(s)... Please wait."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.career.readInfo.status = "fulfilled"
            state.career.readInfo.message = "Career(s) loaded succesfully."
            if (action.payload.hash_info.enable_hash === true) state.career.careers = action.payload.careers
            else 
                for (const career of action.payload.careers) state.career.careers = insertInHash(state.career.careers, career, action.payload.hash_info.hash_key, action.payload.hash_info.hash_type)
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.career.readInfo.status = "rejected"
            state.career.readInfo.message = action.payload.msg
            return state
        }
    }),
    updateCareer : createHandler({
        name: "careers/update_career",
        method: "PUT",
        route: "/career/crud",
        key: "career",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.career.updateInfo.status = "pending"
            state.career.updateInfo.message = "Updating career... Please wait."
            state.career.updateInfo.submitted = true
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.career.updateInfo.status = "fulfilled"
            state.career.updateInfo.message = "Career updated succesfully."
            state.career.careers = insertInHash(state.career.careers, action.payload.career, "id")
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.career.updateInfo.status = "rejected"
            state.career.updateInfo.message = action.payload.msg
            return state
        }
    }),
    deleteCareer : createHandler({
        name: "careers/delete_career",
        method: "DELETE",
        route: "/career/crud",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.career.deleteInfo.status = "pending"
            state.career.deleteInfo.message = "Deleting career... Please wait."
            state.career.deleteInfo.submitted = true
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.career.deleteInfo.status = "fulfilled"
            state.career.deleteInfo.message = "Career deleted."
            state.career.careers = insertInHash(state.career.careers, action.payload.career, "id")
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.career.deleteInfo.status = "rejected"
            state.career.deleteInfo.message = action.payload.msg
            return state
        }
    }),
    massEnrollUsers : createHandler({
        name: "careers/mass_enroll_users",
        method: "POST",
        route: "/career/user_careers/mass_enroll",
        key: "career_users",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.userCareers.createInfo.status = "pending"
            state.userCareers.createInfo.message = "Enrolling user in career... Please wait."
            state.userCareers.createInfo.submitted = true
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.userCareers.createInfo.status = "fulfilled"
            state.userCareers.createInfo.message = "User has been enrolled succesfully."
            state.userCareers.userCareers = action.payload.user_careers
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.userCareers.createInfo.status = "rejected"
            state.userCareers.createInfo.message = action.payload.msg
            return state
        }
    }),
    createUserCareer : createHandler({
        name: "careers/create_user_career",
        method: "POST",
        route: "/career//user_careers/crud",
        key: "career",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.userCareers.createInfo.status = "pending"
            state.userCareers.createInfo.message = "Enrolling user in career... Please wait."
            state.userCareers.createInfo.submitted = true
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.userCareers.createInfo.status = "fulfilled"
            state.userCareers.createInfo.message = "User has been enrolled succesfully."
            state.userCareers.userCareers = insertInHash(state.userCareers.userCareers, action.payload.user_career, "id")
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.userCareers.createInfo.status = "rejected"
            state.userCareers.createInfo.message = action.payload.msg
            return state
        }
    }),
    readUserCareers : createHandler({
        name: "careers/read_user_career",
        method: "GET",
        route: "/career/user_careers/crud",
        key: "",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.userCareers.readInfo.status = "pending"
            state.userCareers.readInfo.message = "Loading user career... Please wait."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.userCareers.readInfo.status = "fulfilled"
            state.userCareers.readInfo.message = "User Career loaded succesfully."
            state.userCareers.userCareers = action.payload.user_careers
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.userCareers.readInfo.status = "rejected"
            state.userCareers.readInfo.message = action.payload.msg
            return state
        }
    }),
    deleteUserCareer : createHandler({
        name: "careers/delete_user_career",
        method: "DELETE",
        route: "/career//user_careers/crud",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.userCareers.deleteInfo.status = "pending"
            state.userCareers.deleteInfo.message = "Removing user from career... Please wait."
            state.userCareers.deleteInfo.submitted = true
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.userCareers.deleteInfo.status = "fulfilled"
            state.userCareers.deleteInfo.message = "User removed from career."
            state.userCareers.userCareers[action.payload.deleted_id] = null
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.userCareers.deleteInfo.status = "rejected"
            state.userCareers.deleteInfo.message = action.payload.msg
            return state
        }
    })
}

export const [CareerActions, CareersReducers] = buildFromCreator(CareerCreator)