import { IonLoading, useIonAlert } from "@ionic/react"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { CareerQuizes } from "../../../common/Interfaces"
import { QuizActions } from "../../../Redux/Quizes/Actions"
import { QuizesState } from "../../../Redux/Quizes/Interface"
import { setQuizesState } from "../../../Redux/Quizes/Slice"
import { AppDispatch } from "../../../Redux/Store"
import QuestionCard from "../Cards/QuestionCard"

let session = Math.random().toString(36).slice(2)

const EditSegment: React.FC<{ selectedSegment: number, current_quiz: CareerQuizes, QuizReducer: QuizesState, id: number }> = ({ selectedSegment, current_quiz, QuizReducer, id }) => {
    const dispatch = useDispatch<AppDispatch>()

    const [segment, setSegment] = useState({ ...QuizReducer.quizSegments.segments[selectedSegment], questions: QuizReducer.quizSegments.segments[selectedSegment].questions.map(q => q.question_id) })
    const [questioNFilter, setQuestioNFilter] = useState("")


    const [ionAlert] = useIonAlert()

    useEffect(() => {
        session = Math.random().toString(36).slice(2)
        dispatch(setQuizesState({ quizSegments: { ...QuizReducer.quizSegments, updateInfo: { ...QuizReducer.quizSegments.updateInfo, submitted: false, session_id: session } } }))
    }, [])

    useEffect(() => {
        if (QuizReducer.quizSegments.updateInfo.submitted !== true || QuizReducer.quizSegments.updateInfo.session_id !== session) return
        if (QuizReducer.quizSegments.updateInfo.status === "fulfilled") {
            ionAlert({ header: "Success", message: "Segment created successfully.", buttons: ["OK"] })
        }
        if (QuizReducer.quizSegments.updateInfo.status === "rejected") {
            ionAlert({ header: "Error!", message: QuizReducer.quizSegments.updateInfo.message, buttons: ["OK"] })
        }
    }, [QuizReducer.quizSegments.updateInfo.status])

    const handleSubmit = e => {
        e.preventDefault()
        const data = new FormData()
        data.append("id", segment.id.toString())
        data.append("name", segment.name)
        data.append("questions", JSON.stringify(segment.questions))
        dispatch(QuizActions.updateSegment({ segment: data }))
    }

    return (
        <div>
            <IonLoading isOpen={QuizReducer.quizSegments.updateInfo.submitted === true && QuizReducer.quizSegments.updateInfo.status === "pending"} message={QuizReducer.quizSegments.updateInfo.message} />
            <div className="flex flex-col gap-2 mb-3">
                <label className="text-sm font-bold text-slate-600 ">Section Name</label>
                <input className="px-2 py-1.5 bg-white  text-slate-800  rounded border border-slate-200  border-2 outline-none focus:border-[#043E61]" type="text" value={segment.name} name="name" placeholder="Section One" onChange={e => setSegment({ ...segment, name: e.target.value })} />
            </div>
            <hr />

            <h1 className="mt-8 text-slate-700 font-bold text-lg mb-3 flex justify-between items-center">
                Available Questions:
                <input
                    className="px-2 py-1 bg-white text-slate-800 rounded border border-slate-200 border-2 outline-none focus:border-[#043E61]"
                    type="text"
                    value={questioNFilter}
                    name="name"
                    placeholder="Filter questions by name"
                    onChange={e => {
                        setQuestioNFilter(e.target.value);
                        dispatch(setQuizesState({ quizSegments: { ...QuizReducer.quizSegments, updateInfo: { ...QuizReducer.quizSegments.updateInfo, submitted: false, session_id: session } } }))
                    }}
                />
            </h1>

            <div className="grid grid-cols-2 flex-wrap items-start justify-between gap-2 mb-8">
                {QuizReducer.quizQuestions.questions
                    .map(q => q === null || (questioNFilter.length > 0 && !q.question.toLowerCase().startsWith(questioNFilter.toLowerCase())) ? null :
                        <QuestionCard
                            key={q.id}
                            question={q}
                            segment={segment}
                            setSegment={setSegment}
                            setDispatch={() => dispatch(setQuizesState({ quizSegments: { ...QuizReducer.quizSegments, updateInfo: { ...QuizReducer.quizSegments.updateInfo, submitted: false, session_id: session } } }))}
                            _ref={undefined} 
                            draggableProps={{style: undefined}} 
                            dragHandleProps={undefined}
                        />)
                }
            </div>
            <button className="bg-slate-800 text-white px-8 py-1 rounded shadow-md hover:shadow-lg ml-auto block" onClick={e => handleSubmit(e)}>UPDATE SECTION</button>
        </div>
    )
}

export default EditSegment