import { createSlice } from "@reduxjs/toolkit";
import { QuizsReducers } from "./Actions"
import { QuizesState } from "./Interface";


const init: QuizesState = {

    careerQuizes: {
        quizes: [],
        createInfo: { status: "idle", message: "", session_id: null, submitted: false },
        readInfo: { status: "idle", message: "", session_id: null, submitted: false },
        updateInfo: { status: "idle", message: "", session_id: null, submitted: false },
        deleteInfo: { status: "idle", message: "", session_id: null, submitted: false },
    },

    quizSegments: {
        segments: [],
        createInfo: { status: "idle", message: "", session_id: null, submitted: false },
        readInfo: { status: "idle", message: "", session_id: null, submitted: false },
        updateInfo: { status: "idle", message: "", session_id: null, submitted: false },
        deleteInfo: { status: "idle", message: "", session_id: null, submitted: false },
    },

    quizQuestions: {
        questions: [],
        createInfo: { status: "idle", message: "", session_id: null, submitted: false },
        readInfo: { status: "idle", message: "", session_id: null, submitted: false },
        updateInfo: { status: "idle", message: "", session_id: null, submitted: false },
        deleteInfo: { status: "idle", message: "", session_id: null, submitted: false },
    }
}

const QuizesSlice = createSlice({
    name: "careers",
    initialState: init,
    reducers: {
        setQuizesState: (state: QuizesState, action) => {
            for (let prop in action.payload) {
                state[prop] = action.payload[prop]
            }
            return state
        },
    },
    extraReducers(builder) {
        for (const action in QuizsReducers) {
            builder.addCase(action, QuizsReducers[action])
        }
    }
})


export const { setQuizesState } = QuizesSlice.actions
export default QuizesSlice.reducer