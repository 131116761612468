import { Fragment } from 'react';
import { IonRouterOutlet } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from './Redux/Store';

import { UserState } from './Redux/User/Interface';
import PageContainer from './common/components/PageContainer';
import AuthContainer from './pages/Auth/AuthContainer';
import Careers from './pages/Careers/Careers';
import Dashboard from './pages/Dashboard/Dashboard';
import Profile from "./pages/Profile/Profile"
import CareerQuizes from './pages/Careers/CareerQuizes';
import CreateCareer from './pages/Careers/Forms/CreateCareer';
import CreateQuiz from './pages/Careers/Forms/CreateQuiz';
import EditCareer from './pages/Careers/Forms/EditCareer';
import EditQuiz from './pages/Careers/Forms/EditQuiz';
import BrowseQuestions from './pages/Questions/BrowseQuestions';
import CreateQuestionForm from './pages/Questions/Forms/CreateQuestionForm';
import EditQuestionForm from './pages/Questions/Forms/EditQuestionForm';
import QuizPage from './pages/TakeQuiz/QuizPage';
import Quiz from './pages/TakeQuiz/Quiz';
import SessionsBrowser from './pages/Dashboard/SessionsBrowser';
import CareerUsersContainer from './pages/Careers/CareerUsers/CareerUsersContainer';


const RootRouter: React.FC<{}> = ({ }) => {
    const UserReducer: UserState = useSelector((state: RootState) => state.user)
    return (
        <IonReactRouter>
            <IonRouterOutlet>
                <Switch>
                    {UserReducer.current === null ? <Fragment>
                        <Redirect to="/auth/login" />
                        <Route exact path="/auth/:type">
                            <AuthContainer />
                        </Route>
                    </Fragment> : <Fragment>
                        <Route exact path="/careers">
                            <PageContainer user={UserReducer.current}><Careers /></PageContainer>
                        </Route>
                        <Route exact path="/career/:id">
                            <PageContainer user={UserReducer.current}><CareerQuizes /></PageContainer>
                        </Route>
                        {UserReducer.current.type_id >= 2 ?
                            <Fragment>
                                <Route exact path="/create_career">
                                    <PageContainer user={UserReducer.current}><CreateCareer /></PageContainer>
                                </Route>
                                <Route exact path="/edit_career/:id">
                                    <PageContainer user={UserReducer.current}><EditCareer /></PageContainer>
                                </Route>
                                <Route exact path="/career_users/:id">
                                    <PageContainer user={UserReducer.current}><CareerUsersContainer /></PageContainer>
                                </Route>
                                <Route exact path="/edit_quiz/:id">
                                    <PageContainer user={UserReducer.current}><EditQuiz /></PageContainer>
                                </Route>
                                <Route exact path="/create_quiz/:id">
                                    <PageContainer user={UserReducer.current}><CreateQuiz /></PageContainer>
                                </Route>
                                <Route exact path="/questions">
                                    <PageContainer user={UserReducer.current}><BrowseQuestions /></PageContainer>
                                </Route>
                                <Route exact path="/create_question">
                                    <PageContainer user={UserReducer.current}><CreateQuestionForm /></PageContainer>
                                </Route>
                                <Route exact path="/question/:id">
                                    <PageContainer user={UserReducer.current}><EditQuestionForm /></PageContainer>
                                </Route>
                            </Fragment> : null
                        }
                        <Route exact path="/dashboard">
                            <PageContainer user={UserReducer.current}><Dashboard /></PageContainer>
                        </Route>
                        <Route exact path="/sessions_browser">
                            <PageContainer user={UserReducer.current}><SessionsBrowser /></PageContainer>
                        </Route>
                        <Route exact path="/quiz/:id">
                            <PageContainer user={UserReducer.current}><QuizPage /></PageContainer>
                        </Route>
                        <Route exact path="/profile">
                            <PageContainer user={UserReducer.current}><Profile /></PageContainer>
                        </Route>
                        <Route exact path="/start_quiz/:id">
                            <Quiz />
                        </Route>
                        <Route exact path="/">
                            <Redirect to={"/careers"} />
                        </Route>
                    </Fragment>}
                </Switch>
            </IonRouterOutlet>
        </IonReactRouter>
    )
}

export default RootRouter