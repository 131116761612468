import { createSlice } from "@reduxjs/toolkit"
import { UserReducers } from "./Actions"
import { UserState } from "./Interface"


const init: UserState = {
    users: [],
    current: null,
    token: "",
    refresh: "",

    registerInfo: { status: "idle", message: "", session_id: null, submitted: false },
    loginInfo: { status: "idle", message: "", session_id: null, submitted: false },

    createUserInfo: { status: "idle", message: "", session_id: null, submitted: false },
    readUserInfo: { status: "idle", message: "", session_id: null, submitted: false },
    updateUserInfo: { status: "idle", message: "", session_id: null, submitted: false },
    deleteUserInfo: { status: "idle", message: "", session_id: null, submitted: false },

    userTypes: {
        readInfo: { status: "idle", message: "", session_id: null, submitted: false },
        types: []
    },
    resetPassword: {
        emailInfo: { status: "idle", message: "", session_id: null, submitted: false },
        verifyInfo: { status: "idle", message: "", session_id: null, submitted: false },
        resetInfo: { status: "idle", message: "", session_id: null, submitted: false },

        userEmail: ""
    }
}

const UserSlice = createSlice({
    name: "user",
    initialState: init,
    reducers: {
        logout: state => {
            state.current = null
            state.token = ""
            state.refresh = ""
        },
        setUserState: (state: UserState, action) => {
            for (let prop in action.payload) {
                state[prop] = action.payload[prop]
            }
            return state
        },
    },
    extraReducers(builder) {
        for (const action in UserReducers) {
            builder.addCase(action, UserReducers[action])
        }
    }
})


export const { logout, setUserState } = UserSlice.actions
export default UserSlice.reducer