import { configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { combineReducers } from 'redux'
import { FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, persistReducer } from 'redux-persist'

import UserReducer from './User/Slice'
import CareersReducer from './Careers/Slice'
import QuizesReducer from './Quizes/Slice'
import AnswersReducers from './Answers/Slice'


const persistConfig = {
    key: 'aptidex_'+(localStorage.getItem("version") === null ? "0.0" : localStorage.getItem("version")),
    storage,
}

export const store = configureStore({
    reducer: persistReducer(persistConfig, combineReducers({
        user: UserReducer,
        career: CareersReducer,
        quiz: QuizesReducer,
        answers: AnswersReducers
    })),
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
})


export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch