import { IonLoading, useIonAlert } from "@ionic/react"
import { Fragment, useEffect, useState } from "react"
import { BsFillArrowLeftSquareFill } from "react-icons/bs"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import { NavLink } from "react-router-dom"
import { QuizActions } from "../../../Redux/Quizes/Actions"
import { QuizesState } from "../../../Redux/Quizes/Interface"
import { setQuizesState } from "../../../Redux/Quizes/Slice"
import { AppDispatch, RootState } from "../../../Redux/Store"
import ChoiceForm from "./ChoiceForm"
import EssayForm from "./EssayForm"
import SliderForm from "./SliderForm"
import QuizQuestion from "../../Careers/QuizComponents/QuizQuestion"

let session = Math.random().toString(36).slice(2)

const EditQuestionForm: React.FC<{}> = ({ }) => {
    const dispatch = useDispatch<AppDispatch>()
    const id: number = parseInt(useParams<{ id: string }>().id)
    const [ionAlert] = useIonAlert()
    const QuizesReducer: QuizesState = useSelector((state: RootState) => state.quiz)

    const [question, setQuestion] = useState<any>(null)

    useEffect(() => {
        dispatch(QuizActions.readQuestions({ arguments: { enable_hash: true } }))
        session = Math.random().toString(36).slice(2)
        dispatch(setQuizesState({ quizQuestions: { ...QuizesReducer.quizQuestions, updateInfo: { ...QuizesReducer.quizQuestions.updateInfo, submitted: false, session_id: session } } }))
    }, [])

    useEffect(() => {
        if (QuizesReducer.quizQuestions.updateInfo.submitted !== true || QuizesReducer.quizQuestions.updateInfo.session_id !== session) return
        if (QuizesReducer.quizQuestions.updateInfo.status === "fulfilled") {
            ionAlert({ header: "Success", message: "Quiz created successfully.", buttons: ["OK"] })
            setQuestion({ ...QuizesReducer.quizQuestions.questions[id], ...QuizesReducer.quizQuestions.questions[id].data })
            session = Math.random().toString(36).slice(2)
            dispatch(setQuizesState({ quizQuestions: { ...QuizesReducer.quizQuestions, updateInfo: { ...QuizesReducer.quizQuestions.updateInfo, session_id: session } } }))
        }
        if (QuizesReducer.quizQuestions.updateInfo.status === "rejected") ionAlert({ header: "Error!", message: QuizesReducer.quizQuestions.updateInfo.message, buttons: ["OK"] })
    }, [QuizesReducer.quizQuestions.updateInfo.status])

    const handleChange = e => {
        if (e.target.name === "image_path") setQuestion({ ...question, image_path: e.target.files[0] })
        else if (e.target.name === "is_multiple" && e.target.value === 0) setQuestion({ ...question, is_multiple: 0, options: question.options.map(opt => ({ ...opt, is_correct: 0 })) })
        else setQuestion({ ...question, [e.target.name]: e.target.value })
        dispatch(setQuizesState({ quizQuestions: { ...QuizesReducer.quizQuestions, updateInfo: { ...QuizesReducer.quizQuestions.updateInfo, submitted: false } } }))
    }

    const handleSubmit = e => {
        e.preventDefault()
        const data = new FormData()
        data.append("id", question.id.toString())
        data.append("question", question.question)
        data.append("points", question.points)
        data.append("duration", question.duration)
        if (typeof (question.image_path) === "object") data.append("image_path", question.image_path)
        if (question.type === "slider") {
            data.append("segments", question.segments)
            data.append("prefered_segment", question.prefered_segment)
            data.append("word_least", question.word_least)
            data.append("word_most", question.word_most)
        } else if (question.type === "essay") {
            data.append("max_characters", question.max_characters)
        } else if (question.type === "choices" || question.type === "choice") {
            data.append("is_multiple", question.is_multiple)
            for (const option of question.options)
                if (option.image !== null) data.append(option.id, option.image)
            data.append("options", JSON.stringify(question.options.map(opt => {
                let obj = { ...opt, image_path: opt.image === null ? false : opt.id }
                if (typeof (obj.id) === "string") delete obj["id"]
                return obj
            })))
        }
        dispatch(QuizActions.updateQuestion({ question: data }))
    }

    const handleDelete = e => {

    }

    if (question === null && QuizesReducer.quizQuestions.readInfo.status !== "fulfilled") return <IonLoading isOpen={true} message="Loading question data... Please wait" />
    if (question === null) setQuestion({ ...QuizesReducer.quizQuestions.questions[id], ...QuizesReducer.quizQuestions.questions[id].data })
    return (
        <div>
            <IonLoading isOpen={QuizesReducer.quizQuestions.updateInfo.submitted === true && QuizesReducer.quizQuestions.updateInfo.status === "pending"} message={QuizesReducer.quizQuestions.updateInfo.message} />
            <div className="
                bg-[url('https://app.aptidext.com/assets/images/questions.png')] 
                bg-[#59A65F] bg-no-repeat bg-contain bg-center bg-fixed
                pt-16 pb-12 max-h-[250px] rounded-b-2xl
            ">
                <div className="w-[800px] max-w-[90%] mx-auto p-4 mt-36 mb-12 bg-white rounded-lg shadow-md">
                    <h1 className="text-xl font-bold text-slate-700 mb-1 flex gap-2 items-center">
                        <NavLink to={"/questions"} className="text-[#59A65F] cursor-pointer">
                            <BsFillArrowLeftSquareFill />
                        </NavLink>
                        Edit Question
                    </h1>
                    {question === null ? null :
                        <Fragment>

                            <div className="flex flex-col gap-2 mb-3">
                                <label className="text-sm font-bold text-slate-600 ">Enter Question</label>
                                <textarea className="px-2 py-1.5 min-h-[100px] bg-white  text-slate-800  rounded border-slate-200 border-2 outline-none focus:border-[#59A65F]"
                                    value={question.question}
                                    name="question"
                                    placeholder="How would you repair the damaged vehicle part shown on the picture?"
                                    onChange={e => handleChange(e)}
                                />
                            </div>
                            <div className="mb-3 flex flex-row gap-2">
                                <div className="flex flex-col gap-2 w-1/2">
                                    <label className="text-sm font-bold text-slate-600 ">Enter Points for correct answer</label>
                                    <input
                                        className="px-2 py-1.5 bg-white text-slate-800 rounded border-slate-200  border-2 outline-none focus:border-[#59A65F]"
                                        type="number"
                                        value={question.points}
                                        name="points"
                                        placeholder="8"
                                        onChange={e => handleChange(e)}
                                    />
                                </div>
                                <div className="flex flex-col gap-2 w-1/2">
                                    <label className="text-sm font-bold text-slate-600 ">Enter Duration (in seconds)</label>
                                    <input
                                        className="px-2 py-1.5 bg-white text-slate-800 rounded border-slate-200  border-2 outline-none focus:border-[#59A65F]"
                                        type="number"
                                        value={question.duration}
                                        name="duration"
                                        placeholder="90"
                                        onChange={e => handleChange(e)}
                                    />
                                </div>
                            </div>
                            {question.type === "slider" ? <SliderForm question={question} handleChange={handleChange} /> : null}
                            {question.type === "choices" || question.type === "choice" ? <ChoiceForm question={question} handleChange={handleChange} /> : null}
                            {question.type === "essay" ? <EssayForm question={question} handleChange={handleChange} /> : null}
                            <QuizQuestion question={{
                                ...question,
                                data: question.type === "choices" || question.type === "choice" || question.type === "multiple" ? {
                                    options: question.options,
                                    is_multiple: question.is_multiple,
                                } : question.type === "slider" ? {
                                    prefered_segment: question.prefered_segment,
                                    segments: question.segments,
                                    word_least: question.word_least,
                                    word_most: question.word_most,
                                } : { max_characters: question.max_characters }
                            }} setOpen={() => { }} />
                            <div className="mt-4 flex justify-between">
                                <button onClick={e => handleSubmit(e)} className="bg-[#59A65F] text-white px-8 py-1 rounded shadow-md hover:shadow-lg">SUBMIT</button>
                                <button onClick={e => handleDelete(e)} className="bg-red-500 text-white px-8 py-1 rounded shadow-md hover:shadow-lg">DELETE</button>
                            </div>
                        </Fragment>
                    }
                </div>
            </div>
        </div>
    )
}

export default EditQuestionForm