import { IonContent, IonPage } from "@ionic/react"
import { User } from "../Interfaces"
import Nav from "./Nav"

const PageContainer: React.FC<{ user: User, children: JSX.Element }> = ({ user, children }) => (
    <IonPage>
        {user === null ? null : <Nav user={user} />}
        <IonContent fullscreen>
            <div id="page-container" className="bg-slate-100  h-screen overflow-y-scroll pb-12">
                {children}
            </div>
        </IonContent>
    </IonPage>
)


export default PageContainer