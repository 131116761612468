import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { AppDispatch, RootState } from "../../Redux/Store"
import { UserState } from "../../Redux/User/Interface"
import { useSelector } from "react-redux"
import { UserActions } from "../../Redux/User/Actions"
import BrowseUsers from "./BrowseUsers"
import CreateUser from "./CreateUser"

const ManageUsers: React.FC<{}> = ({ }) => {
    const dispatch = useDispatch<AppDispatch>()
    const [selected, setSelected] = useState(0)
    const UserReducer: UserState = useSelector((state: RootState) => state.user)

    useEffect(() => {
        dispatch(UserActions.readUsers({ arguments: { enable_hash: true } }))
        dispatch(UserActions.readUserTypes({ arguments: { enable_hash: true } }))
    }, [])

    return (
        <div className="w-[1000px] max-w-[95%] mx-auto">
            <hr className="mb-4" />
            {UserReducer.readUserInfo.status === "pending" ? <p className="text-center">Loading user info... Please wait.</p> : null}
            {UserReducer.userTypes.readInfo.status === "pending" ? <p className="text-center">Loading user types info... Please wait.</p> : null}

            {selected === 0 ? <BrowseUsers setSelected={setSelected} /> : null}
            {selected === 1 ? <CreateUser setSelected={setSelected} /> : null}
        </div>
    )
}

export default ManageUsers