import { IonLoading, useIonAlert } from "@ionic/react"
import { useEffect, useState } from "react"
import { BsFillArrowLeftSquareFill } from "react-icons/bs"
import { HiPhotograph } from "react-icons/hi"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import { NavLink } from "react-router-dom"
import { QuizActions } from "../../../Redux/Quizes/Actions"
import { QuizesState } from "../../../Redux/Quizes/Interface"
import { setQuizesState } from "../../../Redux/Quizes/Slice"
import { AppDispatch, RootState } from "../../../Redux/Store"
import { GrFormCheckmark } from "react-icons/gr"

let session = Math.random().toString(36).slice(2)


const CreateQuiz: React.FC<{}> = ({ }) => {
    const dispatch = useDispatch<AppDispatch>()
    const id: number = parseInt(useParams<{ id: string }>().id)
    const QuizesReducer: QuizesState = useSelector((state: RootState) => state.quiz)
    const [quiz, setQuiz] = useState({
        name: "",
        description: "",
        cover_photo: null,
        has_vr: false,
    })

    const [ionAlert] = useIonAlert()

    useEffect(() => {
        session = Math.random().toString(36).slice(2)
        dispatch(setQuizesState({
            careerQuizes: { ...QuizesReducer.careerQuizes, createInfo: { ...QuizesReducer.careerQuizes.createInfo, submitted: false, session_id: session } }
        }))
    }, [])

    useEffect(() => {
        if (QuizesReducer.careerQuizes.createInfo.submitted !== true || QuizesReducer.careerQuizes.createInfo.session_id !== session) return
        if (QuizesReducer.careerQuizes.createInfo.status === "fulfilled") {
            ionAlert({ header: "Success", message: "Quiz created successfully.", buttons: ["OK"] })
            setQuiz({
                name: "",
                description: "",
                cover_photo: null,
                has_vr: false
            })
        }
        if (QuizesReducer.careerQuizes.createInfo.status === "rejected") {
            ionAlert({ header: "Error!", message: QuizesReducer.careerQuizes.createInfo.message, buttons: ["OK"] })
        }
    }, [QuizesReducer.careerQuizes.createInfo.status])

    const handleChange = e => {
        if (e.target.name === "cover_photo") setQuiz({ ...quiz, [e.target.name]: e.target.files[0] })
        else setQuiz({ ...quiz, [e.target.name]: e.target.value?.toString() })
        dispatch(setQuizesState({ careerQuizes: { ...QuizesReducer.careerQuizes, createInfo: { ...QuizesReducer.careerQuizes.createInfo, submitted: false } } }))
    }

    const handleSubmit = e => {
        e.preventDefault()
        const data = new FormData()
        data.append("name", quiz.name)
        data.append("description", quiz.description)
        data.append("cover_photo", quiz.cover_photo)
        data.append("career_id", id.toString())
        dispatch(QuizActions.createQuiz({ quiz: data }))
    }

    return (
        <div>
            <div className="
                bg-[url('https://liveimages.algoworks.com/new-algoworks/wp-content/uploads/2022/04/19150239/ezgif.com-gif-maker-1.gif')] 
                bg-[#043E61] bg-no-repeat bg-contain bg-center bg-fixed
                pt-16 pb-12 max-h-[250px] rounded-b-2xl
            ">
                <IonLoading isOpen={QuizesReducer.careerQuizes.createInfo.submitted === true && QuizesReducer.careerQuizes.createInfo.status === "pending"} message={QuizesReducer.careerQuizes.createInfo.message} />
                <div className="w-[800px] max-w-[90%] mx-auto p-4 mt-36 mb-12 bg-white  rounded-lg shadow-md">
                    <h1 className="text-xl font-bold text-slate-800  mb-4 flex items-center gap-3">
                        <NavLink to={"/career/"+id} className="text-[#043E61] cursor-pointer">
                            <BsFillArrowLeftSquareFill />
                        </NavLink>
                        Create A New Assessment
                    </h1>
                    <div className="flex flex-col gap-2 mb-3">
                        <label className="text-sm font-bold text-slate-600 ">Enter Assesment Name</label>
                        <input className="px-2 py-1.5 bg-white  text-slate-800  rounded border border-slate-200  border-2 outline-none focus:border-[#043E61]" type="text" value={quiz.name} name="name" placeholder="Plastic Repair Technician" onChange={e => handleChange(e)} />
                    </div>
                    <div className="flex flex-col gap-2 mb-3">
                        <label className="text-sm font-bold text-slate-600 ">Enter Assesment Description</label>
                        <textarea className="px-2 py-1.5 min-h-[100px] bg-white  text-slate-800  rounded border border-slate-200 border-2 outline-none focus:border-[#043E61]" value={quiz.description} name="description" placeholder="Explanation about the career..." onChange={e => handleChange(e)} />
                    </div>
                    <div className="flex flex-row items-center gap-2 mb-3">
                        <GrFormCheckmark className={(quiz.has_vr ? "bg-slate-800 !text-white" : "") + " text-2xl border rounded border-slate-800 border-2 cursor-pointer"} onClick={e => setQuiz({...quiz, has_vr: !quiz.has_vr})} />
                        <label className="text-sm font-bold text-slate-600 ">VR Included in assessment</label>
                    </div>
                    <div className="relative mb-5 flex items-center">
                        <label className="text-sm font-bold text-slate-600  mr-5">Select Assesment Cover Photo</label>
                        <div>
                            <input type="file" name="cover_photo" onChange={e => handleChange(e)} className="absolute w-[50px] opacity-0 z-10 cursor-pointer" />
                            <HiPhotograph className={`w-[50px] z-0 text-3xl ${quiz.cover_photo === null ? "text-gray-500" : "text-[#043E61]"}`} />
                        </div>
                    </div>

                    <button className="bg-[#043E61] text-white px-8 py-1 rounded shadow-md hover:shadow-lg" onClick={e => handleSubmit(e)}>SUBMIT</button>
                </div>
            </div>
        </div>
    )
}

export default CreateQuiz