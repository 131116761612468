import { IonLoading, useIonAlert } from "@ionic/react"
import { useEffect, useState } from "react"
import { HiPhotograph } from "react-icons/hi"
import { useDispatch, useSelector } from "react-redux"
import { STATIC_URL } from "../../common/constants"
import { AppDispatch, RootState } from "../../Redux/Store"
import { UserActions } from "../../Redux/User/Actions"
import { UserState } from "../../Redux/User/Interface"
import { setUserState } from "../../Redux/User/Slice"
let session = Math.random().toString(36).slice(2)

const Profile: React.FC<{}> = ({ }) => {
    const dispatch = useDispatch<AppDispatch>()
    const UserReudcer: UserState = useSelector((state: RootState) => state.user)
    const [user, setUser] = useState({ ...UserReudcer.current })
    const [ionAlert] = useIonAlert()

    useEffect(() => {
        session = Math.random().toString(36).slice(2)
        dispatch(setUserState({ updateUserInfo: { ...UserReudcer.updateUserInfo, submitted: false, session_id: session } }))
    }, [])

    useEffect(() => {
        if (UserReudcer.updateUserInfo.submitted !== true || UserReudcer.updateUserInfo.session_id !== session) return
        if (UserReudcer.updateUserInfo.status === "fulfilled") {
            ionAlert({ header: "Success", message: "User Updated successfully.", buttons: ["OK"] })
        }
        if (UserReudcer.updateUserInfo.status === "rejected") {
            ionAlert({ header: "Error!", message: UserReudcer.updateUserInfo.message, buttons: ["OK"] })
        }
    }, [UserReudcer.updateUserInfo.status])

    const handleChange = e => {
        if (e.target.name === "profile_picture_path") setUser({ ...user, [e.target.name]: e.target.files[0] })
        else setUser({ ...user, [e.target.name]: e.target.value?.toString() })
        dispatch(setUserState({ updateUserInfo: { ...UserReudcer.updateUserInfo, submitted: false } }))
    }

    const handleSubmit = e => {
        e.preventDefault()
        const data = new FormData()
        data.append("id", user.id.toString())
        if (user.first_name !== UserReudcer.current.first_name) data.append("first_name", user.first_name)
        if (user.last_name !== UserReudcer.current.last_name) data.append("last_name", user.last_name)
        if (user.email !== UserReudcer.current.email) data.append("email", user.email)
        if (user.phone_number !== UserReudcer.current.phone_number) data.append("phone_number", user.phone_number)
        if (typeof(user.profile_picture_path) !== typeof("") && user.profile_picture_path !== null) data.append("profile_picture_path", user.profile_picture_path)
        dispatch(UserActions.updateUser({ user: data }))
    }

    return (
        <div>
            <div className="
                bg-[url('https://app.aptidext.com/assets/images/profile-banner.gif')]
                bg-[#60A5FA] bg-no-repeat bg-contain bg-center bg-fixed
                pt-16 pb-12 max-h-[250px] rounded-b-2xl
            ">
                <IonLoading isOpen={UserReudcer.updateUserInfo.submitted === true && UserReudcer.updateUserInfo.status === "pending"} message={UserReudcer.updateUserInfo.message} />
                <div className="w-[800px] max-w-[90%] mx-auto p-4 mt-36 mb-12 bg-white  rounded-lg shadow-md">
                    <span className="flex items-center gap-2 mb-4">
                        <img
                            className="w-12 rounded-full border-[#60A5FA] border-4"
                            src={STATIC_URL + UserReudcer.current.profile_picture_path}
                        />
                        <h1 className="text-xl font-bold text-slate-700 mb-1">Welcome, {UserReudcer.current.first_name} {UserReudcer.current.last_name}</h1>

                    </span>
                    <div className="flex flex-col gap-2 mb-3">
                        <label className="text-sm font-bold text-slate-600 ">First Name</label>
                        <input className="px-2 py-1.5 bg-white text-slate-800 rounded border-slate-200  border-2 outline-none focus:border-[#60A5FA]" type="text" value={user.first_name} name="first_name" placeholder="John" onChange={e => handleChange(e)} />
                    </div>
                    <div className="flex flex-col gap-2 mb-3">
                        <label className="text-sm font-bold text-slate-600 ">Last Name</label>
                        <input className="px-2 py-1.5 bg-white text-slate-800 rounded border-slate-200  border-2 outline-none focus:border-[#60A5FA]" type="text" value={user.last_name} name="last_name" placeholder="Doe" onChange={e => handleChange(e)} />
                    </div>
                    <div className="flex flex-col gap-2 mb-3">
                        <label className="text-sm font-bold text-slate-600 ">Email</label>
                        <input className="px-2 py-1.5 bg-white text-slate-800 rounded border-slate-200  border-2 outline-none focus:border-[#60A5FA]" type="email" value={user.email} name="email" placeholder="john.doe@example.com" onChange={e => handleChange(e)} />
                    </div>
                    <div className="flex flex-col gap-2 mb-3">
                        <label className="text-sm font-bold text-slate-600 ">Phone Number</label>
                        <input className="px-2 py-1.5 bg-white text-slate-800 rounded border-slate-200  border-2 outline-none focus:border-[#60A5FA]" type="email" value={user.phone_number} name="phone_number" placeholder="+1 222 333 4444" onChange={e => handleChange(e)} />
                    </div>
                    <div className="relative mb-5 flex items-center">
                        <label className="text-sm font-bold text-slate-600 mr-5">Select new profile picture</label>
                        <div>
                            <input type="file" name="profile_picture_path" onChange={e => handleChange(e)} className="absolute w-[50px] opacity-0 z-10 cursor-pointer" />
                            <HiPhotograph className={`w-[50px] z-0 text-3xl ${user.profile_picture_path === null || typeof(user.profile_picture_path) === typeof("abc") ? "text-gray-500" : "text-[#60A5FA]"}`} />
                        </div>
                    </div>
                    <button className="bg-[#60A5FA] text-white px-8 py-1 rounded shadow-md hover:shadow-lg" onClick={e => handleSubmit(e)}>SUBMIT</button>
                </div>
            </div>
        </div>
    )
}

export default Profile
