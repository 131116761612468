import { IonButton, IonContent, IonItem, IonList, IonPopover } from "@ionic/react"
import { useEffect, useState } from "react"
import { BsCheckLg, BsFilter, BsPlusSquareFill } from "react-icons/bs"
import { useDispatch, useSelector } from "react-redux"
import { NavLink } from "react-router-dom"
import { QuizActions } from "../../Redux/Quizes/Actions"
import { QuizesState } from "../../Redux/Quizes/Interface"
import { AppDispatch, RootState } from "../../Redux/Store"
import QuestionCard from "../Careers/Cards/QuestionCard"
import SliderForm from "./Forms/SliderForm"

const BrowseQuestions: React.FC<{}> = ({ }) => {
    const dispatch = useDispatch<AppDispatch>()
    const QuizReducer: QuizesState = useSelector((state: RootState) => state.quiz)
    useEffect(() => { dispatch(QuizActions.readQuestions({ arguments: { enable_hash: true } })) }, [])
    const [filter, setFilter] = useState({
        types: ["choice", "essay", "slider"],
        question: "",
        popover: false
    })

    return (
        <div >
            <div className="
                bg-[url('https://app.aptidext.com/assets/images/questions.png')] 
                bg-[#59A65F] bg-no-repeat bg-contain bg-center bg-fixed
                pt-16 pb-12 max-h-[250px] rounded-b-2xl
            ">
                <div className="w-[1000px] max-w-[90%] mx-auto p-4 mt-36 mb-12 bg-white  rounded-lg shadow-md">
                    <h1 className="text-xl font-bold text-slate-700  mb-1">Browse and manage questions</h1>
                    <div className="flex justify-between gap-2">
                        <input
                            className="w-full bg-white  px-2 py-1.5 border-slate-600  border-2 rounded-lg outline-none focus:border-[#59A65F]"
                            type="text"
                            value={filter.question}
                            onChange={e => setFilter({ ...filter, question: e.target.value })}
                            placeholder="Search for a question..."
                        />
                        <p id="popover-button" onClick={e => setFilter({ ...filter, popover: true })} className="bg-[#59A65F] px-3 flex items-center justify-center rounded-md text-xl text-white shadow-md cursor-pointer"> <BsFilter /> </p>

                    </div>
                    <IonPopover side="left" trigger="popover-button" isOpen={filter.popover} onWillDismiss={e => setFilter({ ...filter, popover: false })}>
                        <IonContent>
                            <IonList>
                                <IonItem
                                    onClick={e => setFilter({ ...filter, types: filter.types.includes("choice") ? filter.types.filter(t => t !== "choice") : [...filter.types, "choice"] })}
                                >
                                    <span className={`border-slate-600 border-2 w-5 h-5 rounded mr-1 text-white ${filter.types.includes("choice") ? "bg-[#59A65F]" : ""}`}><BsCheckLg /></span>
                                    <p>Choice</p>
                                </IonItem>
                                <IonItem
                                    onClick={e => setFilter({ ...filter, types: filter.types.includes("essay") ? filter.types.filter(t => t !== "essay") : [...filter.types, "essay"] })}
                                >
                                    <span className={`border-slate-600 border-2 w-5 h-5 rounded mr-1 text-white ${filter.types.includes("essay") ? "bg-[#59A65F]" : ""}`}><BsCheckLg /></span>
                                    <p>Essay</p>
                                </IonItem>
                                <IonItem
                                    onClick={e => setFilter({ ...filter, types: filter.types.includes("slider") ? filter.types.filter(t => t !== "slider") : [...filter.types, "slider"] })}
                                >
                                    <span className={`border-slate-600 border-2 w-5 h-5 rounded mr-1 text-white ${filter.types.includes("slider") ? "bg-[#59A65F]" : ""}`}><BsCheckLg /></span>
                                    <p>Slider</p>
                                </IonItem>
                            </IonList>
                        </IonContent>
                    </IonPopover>
                </div>
            </div>
            <NavLink to={"/create_question/"} className="mt-40 md:mt-24 w-[1000px] max-w-[90%] mx-auto mb-4 p-4 flex items-center border-dashed border-[#59A65F] border-2 cursor-pointer text-[#59A65F]">
                <BsPlusSquareFill className="text-4xl" />
                <div className="ml-2 flex flex-col justify-between font-bold"> CREATE NEW QUESTION </div>
            </NavLink>
            <div className="w-[1000px] max-w-[90%] mx-auto grid grid-cols-1 md:grid-cols-2 gap-2">
                {QuizReducer.quizQuestions.questions.map(q => q === null ? null : filter.types.includes(q.type) && q.question.toLowerCase().startsWith(filter.question.toLowerCase()) ? <QuestionCard
                    key={q.id}
                    question={q}
                    _ref={null}
                    draggableProps={ {style: {}}}
                    dragHandleProps={{}}
                    segment={null}
                    setSegment={null}
                    setDispatch={null}
                /> : null)}

            </div>
        </div>
    )
}

export default BrowseQuestions
