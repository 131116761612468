import { createSlice } from "@reduxjs/toolkit";
import { CareersReducers } from "./Actions"
import { CareersState } from "./Interface";


const init: CareersState = {
    career: {
        careers: [],

        createInfo: { status: "idle", message: "", session_id: null, submitted: false },
        readInfo: { status: "idle", message: "", session_id: null, submitted: false },
        updateInfo: { status: "idle", message: "", session_id: null, submitted: false },
        deleteInfo: { status: "idle", message: "", session_id: null, submitted: false },
    },

    userCareers: {
        userCareers: [],

        createInfo: { status: "idle", message: "", session_id: null, submitted: false },
        readInfo: { status: "idle", message: "", session_id: null, submitted: false },
        deleteInfo: { status: "idle", message: "", session_id: null, submitted: false },
    }
}

const CareersSlice = createSlice({
    name: "careers",
    initialState: init,
    reducers: {
        setCareersState: (state: CareersState, action) => {
            for (let prop in action.payload) {
                state[prop] = action.payload[prop]
            }
            return state
        },
    },
    extraReducers(builder) {
        for (const action in CareersReducers) {
            builder.addCase(action, CareersReducers[action])
        }
    }
})


export const { setCareersState } = CareersSlice.actions
export default CareersSlice.reducer