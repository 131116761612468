import { Fragment, useState } from "react"
import { HiPhotograph } from "react-icons/hi"
import QuizQuestion, { AnswerInterface, Slider } from "../../Careers/QuizComponents/QuizQuestion"

const SliderForm: React.FC<{ question, handleChange }> = ({ question, handleChange }) => {

    const [answer, setAnswer] = useState<AnswerInterface>({
        question_id: question.id,
        text: null,
        options: [],
        segment: null,
        start: new Date(),
        end: null
    })

    return (
        <Fragment>
            <div className="mb-3 flex flex-row gap-2">
                <div className="flex flex-col gap-2 w-1/2">
                    <label className="text-sm font-bold text-slate-600 ">Enter Number of segments </label>
                    <input
                        className="px-2 py-1.5 bg-white text-slate-800 rounded border-slate-200  border-2 outline-none focus:border-[#59A65F]"
                        type="number"
                        value={question.segments}
                        name="segments"
                        placeholder="5"
                        onChange={e => handleChange(e)}
                    />
                </div>
                <div className="flex flex-col gap-2 w-1/2">
                    <label className="text-sm font-bold text-slate-600 ">Enter prefered segment</label>
                    <input
                        className="px-2 py-1.5 bg-white text-slate-800 rounded border-slate-200  border-2 outline-none focus:border-[#59A65F]"
                        type="number"
                        value={question.prefered_segment}
                        name="prefered_segment"
                        placeholder="0"
                        onChange={e => handleChange(e)}
                    />
                </div>
            </div>

            <div className="mb-3 flex flex-row gap-2">
                <div className="flex flex-col gap-2 w-1/2">
                    <label className="text-sm font-bold text-slate-600 ">Enter the word for left extreme</label>
                    <input
                        className="px-2 py-1.5 bg-white text-slate-800 rounded border-slate-200  border-2 outline-none focus:border-[#59A65F]"
                        type="text"
                        value={question.word_least}
                        name="word_least"
                        placeholder="Never"
                        onChange={e => handleChange(e)}
                    />
                </div>
                <div className="flex flex-col gap-2 w-1/2">
                    <label className="text-sm font-bold text-slate-600 ">Enter the word for right extreme</label>
                    <input
                        className="px-2 py-1.5 bg-white text-slate-800 rounded border-slate-200  border-2 outline-none focus:border-[#59A65F]"
                        type="text"
                        value={question.word_most}
                        name="word_most"
                        placeholder="Always"
                        onChange={e => handleChange(e)}
                    />
                </div>
            </div>


            <div className="relative mb-5 flex items-center">
                <label className="text-sm font-bold text-slate-600  mr-5">Select Question Photo (optional)</label>
                <div>
                    <input onClick={e => {
                        if (question.image_path === null || typeof (question.image_path) === typeof ("")) return
                        e.preventDefault()
                        handleChange({
                            target: {
                                name: "image_path",
                                files: [null]
                            }
                        })
                    }} type="file" name="image_path" onChange={e => handleChange(e)} className="absolute w-[50px] opacity-0 z-10 cursor-pointer" />
                    <HiPhotograph className={`w-[50px] z-0 text-3xl ${question.image_path === null || typeof (question.image_path) === typeof ("") ? "text-gray-500" : "text-[#043E61]"}`} />
                </div>
            </div>
            {/* <Slider question={question} answer={answer} setAnswer={setAnswer} asPreview={true} /> */}
        </Fragment>
    )
}

export default SliderForm