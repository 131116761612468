import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import { STATIC_URL } from "../../../common/constants"
import { QuizActions } from "../../../Redux/Quizes/Actions"
import { QuizesState } from "../../../Redux/Quizes/Interface"
import { AppDispatch, RootState } from "../../../Redux/Store"
import EditQuizForm from "./EditQuizForm"
import QuizSegments from "./QuizSegments"

const EditQuiz: React.FC<{}> = ({ }) => {
    const dispatch = useDispatch<AppDispatch>()
    const id: number = parseInt(useParams<{ id: string }>().id)
    const QuizReducer: QuizesState = useSelector((state: RootState) => state.quiz)

    useEffect(() => {
        dispatch(QuizActions.readQuizes({ arguments: { enable_hash: true } }))
        dispatch(QuizActions.readQuestions({ arguments: { enable_hash: true } }))
    }, [])

    const current_quiz = QuizReducer.careerQuizes.quizes[id]

    return (
        <div>
            <div className="pt-20 pb-10 bg-slate-800  rounded-b-3xl">
                <div className="w-[1200px] max-w-[90%] mx-auto flex gap-4 items-start">
                    <img className="w-24 h-24" src={STATIC_URL + current_quiz.cover_photo} />
                    <div className="w-[90%]">
                        <h1 className="font-bold text-slate-200 text-2xl mb-2">{current_quiz.name}</h1>
                        <p className="text-base text-slate-200 whitespace-pre-line">{current_quiz.description}</p>
                    </div>
                </div>
            </div>
            <EditQuizForm
                current_quiz={current_quiz}
                QuizReducer={QuizReducer}
                id={id}
            />
            <QuizSegments 
                current_quiz={current_quiz}
                QuizReducer={QuizReducer}
                id={id}
            />
        </div>
    )
}

export default EditQuiz