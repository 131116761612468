import { IonLoading } from "@ionic/react"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import { STATIC_URL } from "../../common/constants"
import { QuizSegments } from "../../common/Interfaces"
import { QuizActions } from "../../Redux/Quizes/Actions"
import { QuizesState } from "../../Redux/Quizes/Interface"
import { AppDispatch, RootState } from "../../Redux/Store"
import { UserState } from "../../Redux/User/Interface"
import AnswerContainer from "./AnswerContainer"

const session = Math.random().toString(36).slice(2)

const getQuestiosnInfo = (segments: Array<QuizSegments>, reducer: QuizesState) => {
    const questions = []
    let totalDuration = 0
    if (segments === undefined) return { questions: [], totalDuration: 0 }
    let i = 0
    for (const segment of segments) {
        for (const question of segment.questions) {
            let q = reducer.quizQuestions.questions.find(q => q !== null && q.id === question.question_id)
            if (q.archived === 1) continue
            questions.push({ ...q, idx: i, segment_id: segment.id })
            totalDuration += q.duration
            ++i
        }
    }
    return { questions, totalDuration }
}

const Quiz: React.FC = () => {
    const id: number = parseInt(useParams<{ id: string }>().id)
    const dispatch = useDispatch<AppDispatch>()
    const QuizReducer: QuizesState = useSelector((state: RootState) => state.quiz)
    const UserReducer: UserState = useSelector((state: RootState) => state.user)
    const [currentQuestion, setCurrentQestion] = useState(0)
    const [currentInfo, setCurrentInfo] = useState(undefined)
    let current

    useEffect(() => {
        dispatch(QuizActions.readQuizes({ arguments: { enable_hash: true } }))
        dispatch(QuizActions.readQuestions({ arguments: { enable_hash: true } }))
    }, [])

    if (QuizReducer.careerQuizes.readInfo.status === "pending" || QuizReducer.quizQuestions.readInfo.status === "pending") return (
        <div className="bg-slate-800 w-full min-h-screen p-3">
            <h1>Loading.... Please wait.</h1>
        </div>
    )
    current = QuizReducer.careerQuizes.quizes[id]

    return (
        <div className="bg-slate-800 w-full min-h-screen overflow-y-scroll" style={{
            "backgroundImage": "url('https://app.aptidext.com/assets/images/questionmark-bg.png')",
            "backgroundSize": "contain",
        }}>
            <div className="w-full h-full glass" style={{ "overflowY": "scroll" }}>
                {currentInfo === undefined ?
                    <div className="h-screen overflow-y-scroll flex flex-col gap-4 items-center justify-center">
                        <img src={STATIC_URL + current.cover_photo} className="w-40 h-40 border-2 border-[#F7C01D] rounded-full animate-custom -mt-12" />

                        <h1 className="text-[#F7C01D] font-bold text-lg">{current.name}</h1>
                        <button onClick={e => setCurrentInfo(getQuestiosnInfo(current.segments, QuizReducer))} className="bg-[#F7C01D] text-slate-800 font-bold rounded px-5 py-1">START QUIZ</button>
                    </div>
                    : <AnswerContainer
                        question={currentInfo.questions[currentQuestion]}
                        setQuestion={setCurrentQestion}
                        selected={currentQuestion}
                        quiz={current}
                        user={UserReducer.current}
                        session_id={session}
                    />}
            </div>
        </div>
    )
}

export default Quiz