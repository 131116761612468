import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { CareerActions } from "../../Redux/Careers/Actions"
import { CareersState } from "../../Redux/Careers/Interface"
import { QuizActions } from "../../Redux/Quizes/Actions"
import { QuizesState } from "../../Redux/Quizes/Interface"
import { AppDispatch, RootState } from "../../Redux/Store"
import { UserState } from "../../Redux/User/Interface"
import { BsPlusSquareFill } from "react-icons/bs"
import { NavLink } from "react-router-dom"
import CareerCard from "./Cards/CareerCard"

const Careers: React.FC<{}> = ({ }) => {
    const dispatch = useDispatch<AppDispatch>()
    const UserReudcer: UserState = useSelector((state: RootState) => state.user)
    const QuizesReducer: QuizesState = useSelector((state: RootState) => state.quiz)
    const CareersReducer: CareersState = useSelector((state: RootState) => state.career)

    useEffect(() => {
        dispatch(CareerActions.readCareers({ arguments: { enable_hash: true, hash_key: "id" } }))
        dispatch(CareerActions.readUserCareers({ arguments: { enable_hash: true, hash_key: "id" } }))
        dispatch(QuizActions.readQuizes({ arguments: { enable_hash: true, hash_key: "id" } }))
    }, [])

    const userAvailableCareers = CareersReducer.career.careers.filter(c => c !== null && (UserReudcer.current.type_id !== 1 || CareersReducer.userCareers.userCareers.filter(uc => uc !== null && uc.user_id === UserReudcer.current.id).map(user_career => user_career.career_id).includes(c.id)))

    return (
        <div>
            <div className="
                bg-[url('https://app.aptidext.com/assets/images/gerberlogo.png')] 
                bg-[#0c4a6e] bg-no-repeat bg-center bg-fixed
                pt-16 pb-12 max-h-[250px] rounded-b-2xl
            ">
                <div className="w-[800px] max-w-[90%] mx-auto p-4 mt-36 mb-12 bg-white  rounded-lg shadow-md">
                    <h1 className="text-xl font-bold text-slate-800  mb-1 text-[#ee3525]">Careers Browser</h1>
                    <p className="">Browse through careers and pick the one that suits you best.</p>
                </div>
            </div>
            <div className="w-[800px] max-w-[90%] mx-auto mt-20 p-2">
                {UserReudcer.current.type_id >= 2 ? <NavLink to="/create_career" className="p-4 flex items-center border border-dashed border-[#ee3525] border-2 cursor-pointer text-[#ee3525] mb-3">
                    <BsPlusSquareFill className="text-4xl" />
                    <div className="ml-2 flex flex-col justify-between font-bold"> CREATE NEW CAREER </div>
                </NavLink> : null}
                {userAvailableCareers.length === 0 ? <p>You haven't been invited to start in any careers as of yet. Please keep an eye out in your e-mail for any future invitations.</p> : <h1 className="font-bold text-slate-800 mb-3">Available Careers:</h1>}
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                    {userAvailableCareers.map(c => <CareerCard
                        key={c.id}
                        career={c}
                        quizes={QuizesReducer.careerQuizes.quizes.filter(q => q !== null && q.career_id === c.id)}
                    />)}
                </div>
            </div>
        </div>
    )
}
export default Careers