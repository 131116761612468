import { useState } from "react"
import { BsXSquareFill, BsPlusSquareFill, BsFillArrowLeftSquareFill } from "react-icons/bs"
import { CareerQuizes } from "../../../common/Interfaces"
import { QuizesState } from "../../../Redux/Quizes/Interface"
import SegmentCard from "../Cards/SegmentCard"
import CreateSegment from "./CreateSegment"
import EditSegment from "./EditSegment"
import DraggableParent from "../../../common/components/DraggableParent"


const QuizSegments: React.FC<{ current_quiz: CareerQuizes, QuizReducer: QuizesState, id: number }> = ({ current_quiz, QuizReducer, id }) => {
    const [selectedSegment, setSelectedSegment] = useState(null)

    return (
        <div className="w-[1200px] max-w-[90%] mx-auto mt-8 p-4 bg-white  rounded-lg shadow-md">
            <h1 className="text-xl font-bold text-slate-800  mb-4 flex items-center gap-3 flex justify-between items-center">
                Manage Assesment Sections
                {selectedSegment === -1 ?
                    <BsXSquareFill className="text-xl cursor-pointer" onClick={e => setSelectedSegment(null)} /> : selectedSegment === null ?
                        <BsPlusSquareFill className="text-xl cursor-pointer" onClick={e => setSelectedSegment(-1)} /> :
                        <BsFillArrowLeftSquareFill className="text-xl cursor-pointer" onClick={e => setSelectedSegment(null)} />
                }
            </h1>
            {selectedSegment === null && current_quiz.segments.length === 0 ? <h1>There are no segments available for this quiz.</h1> : null}
            <div className="grid grid-cols-2 flex-wrap items-start justify-between gap-2">
                {selectedSegment === null ? [...current_quiz.segments].sort((a, b) => a.id > b.id ? 1 : -1).map(segment =>
                    <SegmentCard
                        key={segment.id}
                        segment={segment}
                        setSelectedSegment={setSelectedSegment}
                    />) : null
                }
            </div>
            {selectedSegment === -1 ? <CreateSegment
                current_quiz={current_quiz}
                QuizReducer={QuizReducer}
                id={id}
            /> : null}
            {selectedSegment !== null && selectedSegment !== -1 ? <EditSegment
                selectedSegment={selectedSegment}
                current_quiz={current_quiz}
                QuizReducer={QuizReducer}
                id={id}
            /> : null}
        </div>
    )
}

export default QuizSegments