import { CareerQuizes } from "../../../common/Interfaces";
import { NavLink } from "react-router-dom"
import { BsFillArrowLeftSquareFill } from "react-icons/bs"
import { HiPhotograph } from "react-icons/hi"
import { IonLoading, useIonAlert } from "@ionic/react"
import { useEffect, useState } from "react"
import { QuizActions } from "../../../Redux/Quizes/Actions"
import { setQuizesState } from "../../../Redux/Quizes/Slice"
import { QuizesState } from "../../../Redux/Quizes/Interface";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../Redux/Store";

let session = Math.random().toString(36).slice(2)

export const EditQuizForm: React.FC<{ current_quiz: CareerQuizes, QuizReducer:QuizesState, id:number }> = ({ current_quiz, QuizReducer, id }) => {
    const dispatch = useDispatch<AppDispatch>()
    const [quiz, setQuiz] = useState({ ...current_quiz })
    const [ionAlert] = useIonAlert()

    useEffect(() => {
        session = Math.random().toString(36).slice(2)
        dispatch(setQuizesState({
            careerQuizes: { ...QuizReducer.careerQuizes, updateInfo: { ...QuizReducer.careerQuizes.updateInfo, submitted: false, session_id: session } }
        }))
    }, [])

    useEffect(() => {
        if (QuizReducer.careerQuizes.updateInfo.submitted !== true || QuizReducer.careerQuizes.updateInfo.session_id !== session) return
        if (QuizReducer.careerQuizes.updateInfo.status === "fulfilled") {
            ionAlert({ header: "Success", message: "Quiz created successfully.", buttons: ["OK"] })
        }
        if (QuizReducer.careerQuizes.updateInfo.status === "rejected") {
            ionAlert({ header: "Error!", message: QuizReducer.careerQuizes.updateInfo.message, buttons: ["OK"] })
        }
    }, [QuizReducer.careerQuizes.updateInfo.status])

    const handleChange = e => {
        if (e.target.name === "cover_photo") setQuiz({ ...quiz, [e.target.name]: e.target.files[0] })
        else setQuiz({ ...quiz, [e.target.name]: e.target.value?.toString() })
        dispatch(setQuizesState({
            careerQuizes: { ...QuizReducer.careerQuizes, updateInfo: { ...QuizReducer.careerQuizes.updateInfo, submitted: false } }
        }))
    }

    const handleSubmit = e => {
        e.preventDefault()
        const data = new FormData()
        data.append("id", quiz.id.toString())
        data.append("career_id", quiz.career_id.toString())
        if(current_quiz.name !== quiz.name) data.append("name", quiz.name)
        if(current_quiz.description !== quiz.description) data.append("description", quiz.description)
        if(quiz.cover_photo !== null && typeof(quiz.cover_photo) !== typeof("123")) data.append("cover_photo", quiz.cover_photo)
        dispatch(QuizActions.updateQuiz({ quiz: data }))
    }

    return (
        <div className="w-[1200px] max-w-[90%] mx-auto mt-8 p-4 bg-white  rounded-lg shadow-md">
            <IonLoading isOpen={QuizReducer.careerQuizes.updateInfo.submitted === true && QuizReducer.careerQuizes.updateInfo.status === "pending"} message={QuizReducer.careerQuizes.updateInfo.message} />
            <h1 className="text-xl font-bold text-slate-800  mb-4 flex items-center gap-3">
                <NavLink to={"/career/" + quiz.career_id} className="text-slate-800 cursor-pointer">
                    <BsFillArrowLeftSquareFill />
                </NavLink>
                {current_quiz.name}
            </h1>
            <div className="flex flex-col gap-2 mb-3">
                <label className="text-sm font-bold text-slate-600 ">Assesment Name</label>
                <input className="px-2 py-1.5 bg-white  text-slate-800  rounded border border-slate-200  border-2 outline-none focus:border-slate-800" type="text" value={quiz.name} name="name" placeholder="Plastic Repair Technician" onChange={e => handleChange(e)} />
            </div>
            <div className="flex flex-col gap-2 mb-3">
                <label className="text-sm font-bold text-slate-600 ">Assesment Description</label>
                <textarea className="px-2 py-1.5 min-h-[100px] bg-white  text-slate-800  rounded border border-slate-200 border-2 outline-none focus:border-slate-800" value={quiz.description} name="description" placeholder="Explanation about the career..." onChange={e => handleChange(e)} />
            </div>
            <div className="relative mb-5 flex items-center">
                <label className="text-sm font-bold text-slate-600  mr-5">Select Assesment Cover Photo</label>
                <div>
                    <input type="file" name="cover_photo" onChange={e => handleChange(e)} className="absolute w-[50px] opacity-0 z-10 cursor-pointer" />
                    <HiPhotograph className={`w-[50px] z-0 text-3xl ${quiz.cover_photo === null || typeof(quiz.cover_photo) === typeof("123") ? "text-gray-500" : "text-slate-800"}`} />
                </div>
            </div>

            <button className="bg-slate-800 text-white px-8 py-1 rounded shadow-md hover:shadow-lg" onClick={e => handleSubmit(e)}>SUBMIT</button>
        </div>
    )
}

export default EditQuizForm