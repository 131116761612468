const Logo = ({ }) => (
    <svg width="120" height="35" viewBox="0 0 350 41.56910824993492">
        <defs id="SvgjsDefs2567"></defs>
        <g id="SvgjsG2568" featurekey="nameFeature-0" transform="matrix(1.4548695087432861,0,0,1.4548695087432861,-4.157144613965113,-16.62668594454122)" fill="#ffffff">
            <path d="M10.286 40 l-7.4286 0 l0 -24.286 l4.2857 -4.2857 l3.1429 0 l0 28.571 z M23.714 11.428999999999998 l4.2857 4.2857 l0 24.286 l-7.4286 0 l0 -8 l-9.1429 0 l0 -6.2857 l9.1429 0 l0 -8 l-9.1429 0 l0 -6.2857 l12.286 0 z M44.571714285714286 40 l-7.4286 0 l0 -28.571 l7.4286 0 l0 28.571 z M57.99971428571428 11.428999999999998 l4.2857 4.2857 l0 12 l-4.2857 4.2857 l-12.286 0 l0 -6.2857 l9.1429 0 l0 -8 l-9.1429 0 l0 -6.2857 l12.286 0 z M76.68572857142857 11.428999999999998 l0 6.2857 l-7.2571 0 l0 -6.2857 l7.2571 0 z M77.82852857142856 11.428999999999998 l15.886 0 l0 6.2857 l-8.4571 0 l0 22.286 l-7.4286 0 l0 -28.571 z M100 40 l0 -5.7143 l2.2857 0 l0 -17.143 l-2.2857 0 l0 -5.7143 l12 0 l0 5.7143 l-2.2857 0 l0 17.143 l2.2857 0 l0 5.7143 l-12 0 z M127.71457142857143 40 l-7.4286 0 l0 -28.571 l7.4286 0 l0 28.571 z M141.14257142857144 11.428999999999998 l4.2857 4.2857 l0 20 l-4.2857 4.2857 l-12.286 0 l0 -6.2857 l9.1429 0 l0 -16 l-9.1429 0 l0 -6.2857 l12.286 0 z M162.00028571428572 40 l-3.1429 0 l-4.2857 -4.2857 l0 -24.286 l7.4286 0 l0 28.571 z M163.14328571428572 11.428999999999998 l15.714 0 l0 6.2857 l-15.714 0 l0 -6.2857 z M176.57128571428572 22.571 l0 6.2857 l-13.429 0 l0 -6.2857 l13.429 0 z M163.14328571428572 40 l0 -6.2857 l15.714 0 l0 6.2857 l-15.714 0 z M199.02885714285716 19.143 l-4.4571 5.8857 l-7.4286 -10.171 l0 -3.4286 l6.6286 0 z M212.85685714285717 11.428999999999998 l0 3.4286 l-7.8857 10.857 l7.8857 10.857 l0 3.4286 l-6.5714 0 l-6.2286 -9.1429 l-6.2857 9.1429 l-6.6286 0 l0 -3.4286 l19.143 -25.143 l6.5714 0 z M226.4000142857143 11.428999999999998 l0 6.2857 l-7.2571 0 l0 -6.2857 l7.2571 0 z M227.54281428571431 11.428999999999998 l15.886 0 l0 6.2857 l-8.4571 0 l0 22.286 l-7.4286 0 l0 -28.571 z">
            </path>
        </g>
    </svg>
)

export default Logo