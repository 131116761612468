import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../Redux/Store"
import { UserState } from "../../Redux/User/Interface"
import { useEffect } from "react"
import { AnswerActions } from "../../Redux/Answers/Actions"
import { AnswersState } from "../../Redux/Answers/Interface"
import SessionCard from "./SessionCard"
import { UserActions } from "../../Redux/User/Actions"
import { QuizActions } from "../../Redux/Quizes/Actions"
import { QuizesState } from "../../Redux/Quizes/Interface"

const getUnreviewed = answers => {
    const ret = []
    for (const answer in answers) {
        if (answers[answer].find(a => a.points < 0) !== undefined) ret.push(answer)
    }
    return ret
}

const SessionsBrowser: React.FC = props => {
    const dispatch = useDispatch<AppDispatch>()
    const AnswersReducer: AnswersState = useSelector((state: RootState) => state.answers)
    const UserReducer: UserState = useSelector((state: RootState) => state.user)
    const QuizReducer: QuizesState = useSelector((state: RootState) => state.quiz)

    useEffect(() => {
        dispatch(AnswerActions.readAnswers({ arguments: { enable_hash: true, hash_key: "session_id", hash_type: "obht" } }))
        dispatch(QuizActions.readQuestions({ arguments: { enable_hash: true } }))
        dispatch(QuizActions.readQuizes({ arguments: { enable_hash: true } }))
        dispatch(UserActions.readUsers({ arguments: { enable_hash: true } }))
    }, [])

    const unreviewed = getUnreviewed(AnswersReducer.answer.answers)
    const reviewed = Object.keys(AnswersReducer.answer.answers).filter(key => unreviewed.includes(key) === false)

    return UserReducer.readUserInfo.status === "pending" || AnswersReducer.answer.readInfo.status === "pending" || QuizReducer.careerQuizes.readInfo.status === "pending" || QuizReducer.quizQuestions.readInfo.status === "pending" ?
        <p className="text-center">Loading... Please wait</p> :
        <div className="w-[1000px] max-w-[90%] mx-auto">
            {unreviewed.length === 0 ? null : <h1 className="font-bold text-slate-800 mb-3">Unreviewed Sessions:</h1>}
            {unreviewed.map(session => <SessionCard key={session} session_id={session} />)}
            {reviewed.length === 0 ? null : <h1 className="font-bold text-slate-800 mb-3">Reviewed Sessions:</h1>}
            {reviewed.map(session => <SessionCard key={session} session_id={session} />)}
        </div>

    return (
        <div className="pt-16 pb-12 max-h-[250px] bg-[url('https://normasapa.pro/wp-content/uploads/2018/05/informe-de-gestion-de-riesgos.jpg')] bg-[#06c59a] bg-no-repeat bg-contain bg-center rounded-b-2xl bg-fixed">
            <div className="w-[1000px] max-w-[90%] mx-auto p-4 mt-36 mb-12 bg-white  rounded-lg shadow-md">
                <h1 className="text-xl font-bold text-slate-800 mb-1">Browse & Grade Quiz</h1>
                <p>Examin and grade users' assessment attempt sessions.</p>
            </div>
            {UserReducer.readUserInfo.status === "pending" || AnswersReducer.answer.readInfo.status === "pending" || QuizReducer.careerQuizes.readInfo.status === "pending" || QuizReducer.quizQuestions.readInfo.status === "pending" ?
                <p className="text-center">Loading... Please wait</p> :
                <div className="w-[1000px] max-w-[90%] mx-auto">
                    <h1 className="font-bold text-slate-800 mb-3">Unreviewed Sessions:</h1>
                    {unreviewed.map(session => <SessionCard key={session} session_id={session} />)}
                    <h1 className="font-bold text-slate-800 mb-3">Reviewed Sessions:</h1>
                    {reviewed.map(session => <SessionCard key={session} session_id={session} />)}
                </div>}
        </div>
    )
}

export default SessionsBrowser