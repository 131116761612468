import { useEffect, useState } from "react"
import { BsFillArrowLeftSquareFill, BsPlusSquareFill } from "react-icons/bs"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import { NavLink } from "react-router-dom"
import { STATIC_URL } from "../../common/constants"
import { CareerActions } from "../../Redux/Careers/Actions"
import { CareersState } from "../../Redux/Careers/Interface"
import { QuizActions } from "../../Redux/Quizes/Actions"
import { QuizesState } from "../../Redux/Quizes/Interface"
import { AppDispatch, RootState } from "../../Redux/Store"
import { UserState } from "../../Redux/User/Interface"
import QuizCard from "./Cards/QuizCard"
import { IonModal } from "@ionic/react"

const CareerQuizes: React.FC<{}> = ({ }) => {
    const id: number = parseInt(useParams<{ id: string }>().id)
    const dispatch = useDispatch<AppDispatch>()

    const UserReudcer: UserState = useSelector((state: RootState) => state.user)
    const QuizesReducer: QuizesState = useSelector((state: RootState) => state.quiz)
    const CareersReducer: CareersState = useSelector((state: RootState) => state.career)
    const [readMore, setReadMore] = useState(false)

    useEffect(() => {
        dispatch(CareerActions.readCareers({ arguments: { enable_hash: true, hash_key: "id" } }))
        dispatch(QuizActions.readQuizes({ arguments: { enable_hash: true, hash_key: "id" } }))
        dispatch(QuizActions.readSegments({ arguments: { enable_hash: true, hash_key: "id" } }))
        dispatch(QuizActions.readQuestions({ arguments: { enable_hash: true, hash_key: "id" } }))
    }, [])

    const currentCareer = CareersReducer.career.careers.find(c => c !== null && c.id === id)
    const currentQuizes = QuizesReducer.careerQuizes.quizes.filter(q => q !== null && q.career_id === id)

    return (
        <div>
            <div className="
                bg-[url('https://liveimages.algoworks.com/new-algoworks/wp-content/uploads/2022/04/19150239/ezgif.com-gif-maker-1.gif')] 
                bg-[#043E61] bg-no-repeat bg-contain bg-center bg-fixed
                pt-16 pb-12 max-h-[250px] rounded-b-2xl
            ">
                <div className="w-[800px] max-w-[90%] mx-auto pt-1 p-4 mt-36 mb-12 bg-white  rounded-lg shadow-md">
                    <span className="flex mb-2 items-center justify-between mt-2">
                        <span className="flex items-center">
                            <NavLink to="/careers" className="text-[#043E61] cursor-pointer mr-2 text-xl">
                                <BsFillArrowLeftSquareFill />
                            </NavLink>
                            <h1 className="text-xl font-bold text-slate-800 mb-1">{currentCareer.name.substring(0, 28)}</h1>
                        </span>
                        <span className="flex items-center gap-2">
                            {UserReudcer.current.type_id >= 2 ? <NavLink className="bg-[#043E61] text-white px-2 py-1 rounded shadow-md hover:shadow-lg ml-auto hidden md:block" to={"/edit_career/" + id}>EDIT CAREER</NavLink> : null}
                            {UserReudcer.current.type_id >= 2 ? <NavLink className="bg-[#043E61] text-white px-2 py-1 rounded shadow-md hover:shadow-lg ml-auto hidden md:block" to={"/career_users/" + id}>ENROLLMENT</NavLink> : null}
                        </span>
                    </span>
                    <p><img src={STATIC_URL + currentCareer.cover_photo} className="w-[45px] h-[45px] mr-2 shadow float-left" />{currentCareer.description.substring(0, 180)} {currentCareer.description.length > 180 ? <span onClick={e => setReadMore(true)} className="text-slate-600 cursor-pointer">read more</span> : null}</p>
                </div>
            </div>
            <IonModal isOpen={readMore} onWillDismiss={e => setReadMore(false)}>
                <div className="w-full h-full overflow-y-scroll px-6 py-4 md:p-12 bg-slate-700">
                    <h1 className="font-bold text-xl mb-8">{currentCareer.name}</h1>
                    <p className="text-justify"><img src={STATIC_URL + currentCareer.cover_photo} className="w-[88px] h-[88px] mr-2 shadow float-left" />{currentCareer.description}</p>
                    <button onClick={e => setReadMore(false)} className="mt-4 block mx-auto bg-slate-400 px-4 py-1 font-bold text-slate-900 rounded-md">CLOSE</button>
                </div>
            </IonModal>
            <div className="w-[800px] max-w-[90%] mx-auto mt-48 md:mt-24 p-2">
                {UserReudcer.current.type_id >= 2 ? <NavLink to={"/create_quiz/" + id} className="p-4 flex items-center border border-dashed border-[#245E81] border-2 cursor-pointer text-[#245E81] mb-3">
                    <BsPlusSquareFill className="text-4xl" />
                    <div className="ml-2 flex flex-col justify-between font-bold"> CREATE NEW ASSESSMENTS </div>
                </NavLink> : null}
                <h1 className="font-bold text-slate-800 mb-3">Available Assessments:</h1>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                    {currentQuizes.map(quiz => <QuizCard
                        key={quiz.id}
                        quiz={quiz}
                        QuizesReducer={QuizesReducer}
                        current_user_type={UserReudcer.current.type_id}
                    />)}
                </div>
            </div>
        </div>
    )
}

export default CareerQuizes