import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { CareersState } from "../../../Redux/Careers/Interface"
import { AppDispatch, RootState } from "../../../Redux/Store"
import { setCareersState } from "../../../Redux/Careers/Slice"
import { IonLoading, useIonAlert } from "@ionic/react"
import { CareerActions } from "../../../Redux/Careers/Actions"
import { HiPhotograph } from "react-icons/hi"
import { BsFillArrowLeftSquareFill } from "react-icons/bs"
import { NavLink } from "react-router-dom"

let session = Math.random().toString(36).slice(2)

const CreateCareer: React.FC<{}> = ({ }) => {
    const dispatch = useDispatch<AppDispatch>()
    const CareersReducer: CareersState = useSelector((state: RootState) => state.career)
    const [career, setCareer] = useState({
        name: "",
        description: "",
        cover_photo: null,
    })

    const [ionAlert] = useIonAlert()

    useEffect(() => {
        session = Math.random().toString(36).slice(2)
        dispatch(setCareersState({
            career: { ...CareersReducer.career, createInfo: { ...CareersReducer.career.createInfo, submitted: false, session_id: session } }
        }))
    }, [])

    useEffect(() => {
        if (CareersReducer.career.createInfo.submitted !== true || CareersReducer.career.createInfo.session_id !== session) return
        if (CareersReducer.career.createInfo.status === "fulfilled") {
            ionAlert({ header: "Success", message: "Career created successfully.", buttons: ["OK"] })
            setCareer({
                name: "",
                description: "",
                cover_photo: null,
            })
        }
        if (CareersReducer.career.createInfo.status === "rejected") {
            ionAlert({ header: "Error!", message: CareersReducer.career.createInfo.message, buttons: ["OK"] })
        }
    }, [CareersReducer.career.createInfo.status])

    const handleChange = e => {
        if (e.target.name === "cover_photo") setCareer({ ...career, [e.target.name]: e.target.files[0] })
        else setCareer({ ...career, [e.target.name]: e.target.value?.toString() })
        dispatch(setCareersState({
            career: { ...CareersReducer.career, createInfo: { ...CareersReducer.career.createInfo, submitted: false } }
        }))
    }

    const handleSubmit = e => {
        e.preventDefault()
        const data = new FormData()
        data.append("name", career.name)
        data.append("description", career.description)
        data.append("cover_photo", career.cover_photo)
        dispatch(CareerActions.createCareer({ career: data }))
    }

    return (
        <div >
            <div className="
                bg-[url('https://app.aptidext.com/assets/images/gerberlogo.png')] 
                bg-[#0c4a6e] bg-no-repeat bg-center bg-fixed
                pt-16 pb-12 max-h-[250px] rounded-b-2xl
            ">
                <IonLoading isOpen={CareersReducer.career.createInfo.submitted === true && CareersReducer.career.createInfo.status === "pending"} message={CareersReducer.career.createInfo.message} />
                <div className="w-[800px] max-w-[90%] mx-auto p-4 mt-36 mb-12 bg-white  rounded-lg shadow-md">
                    <h1 className="text-xl font-bold text-slate-800  mb-4 flex items-center gap-3">
                        <NavLink to="/careers" className="text-[#ee3525] cursor-pointer">
                            <BsFillArrowLeftSquareFill />
                        </NavLink>
                        Create A New Career
                    </h1>
                    <div className="flex flex-col gap-2 mb-3">
                        <label className="text-sm font-bold text-slate-600 ">Enter Career Name</label>
                        <input className="px-2 py-1.5 bg-white  text-slate-800  rounded border border-slate-200  border-2 outline-none focus:border-[#FF7448]" type="text" value={career.name} name="name" placeholder="Plastic Repair Technician" onChange={e => handleChange(e)} />
                    </div>
                    <div className="flex flex-col gap-2 mb-3">
                        <label className="text-sm font-bold text-slate-600 ">Enter Career Description</label>
                        <textarea className="px-2 py-1.5 min-h-[100px] bg-white  text-slate-800  rounded border border-slate-200 border-2 outline-none focus:border-[#FF7448]" value={career.description} name="description" placeholder="Explanation about the career..." onChange={e => handleChange(e)} />
                    </div>
                    <div className="relative mb-5 flex items-center">
                        <label className="text-sm font-bold text-slate-600  mr-5">Select Career Cover Photo</label>
                        <div>
                            <input type="file" name="cover_photo" onChange={e => handleChange(e)} className="absolute w-[50px] opacity-0 z-10 cursor-pointer" />
                            <HiPhotograph className={`w-[50px] z-0 text-3xl ${career.cover_photo === null ? "text-gray-500" : "text-[#FF7448]"}`} />
                        </div>
                    </div>

                    <button className="bg-[#ee3525] text-white px-8 py-1 rounded shadow-md hover:shadow-lg" onClick={e => handleSubmit(e)}>SUBMIT</button>
                </div>
            </div>
        </div>
    )
}

export default CreateCareer