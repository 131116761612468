import { IonButton, IonInput, IonItem, IonLabel, IonLoading, useIonAlert } from "@ionic/react"
import { Fragment, useEffect, useState } from "react"
import { HiPhotograph } from "react-icons/hi"
import { useDispatch, useSelector } from "react-redux"
import { NavLink, useHistory } from "react-router-dom"
import { validateEmail } from "../../common/functions"
import { AppDispatch, RootState } from "../../Redux/Store"
import { UserActions } from "../../Redux/User/Actions"
import { UserState } from "../../Redux/User/Interface"
import { setUserState } from "../../Redux/User/Slice"

let session = Math.random().toString(36).slice(2)

const Register: React.FC<{}> = ({ }) => {
    const dispatch = useDispatch<AppDispatch>()
    const history = useHistory()

    const UserReducer: UserState = useSelector((state: RootState) => state.user)
    const [step, setStep] = useState<number>(1)
    const [user, setUser] = useState<{ email: string, password: string, confirm: string, first_name: string, last_name: string, phone_number: string, profile_picture_path: any }>({
        email: "",
        password: "",
        confirm: "",
        first_name: "",
        last_name: "",
        phone_number: "",
        profile_picture_path: null
    })
    const [ionAlert] = useIonAlert()

    useEffect(() => {
        session = Math.random().toString(36).slice(2)
        dispatch(setUserState({ registerInfo: { status: "idle", message: "", session_id: session, submitted: false } }))
    }, [])

    useEffect(() => {
        if (UserReducer.registerInfo.submitted !== true || UserReducer.registerInfo.session_id !== session) return
        if (UserReducer.registerInfo.status === "fulfilled") history.push("/careers")
        if (UserReducer.registerInfo.status === "rejected") {
            ionAlert({header: "Error!", message: UserReducer.registerInfo.message, buttons: ["OK"]})
            setStep(1)
        }
    }, [UserReducer.registerInfo.status])
    
    const handleStep = (step: number) => {
        if (validateEmail(user.email) === false) return ionAlert({ header: "Error!", message: "This is not a valid email address.", buttons: ["OK"] })
        if (user.password.length < 8) return ionAlert({ header: "Error!", message: "Please enter a strong password (at least 8 characters).", buttons: ["OK"] })
        if (user.password !== user.confirm) return ionAlert({ header: "Error!", message: "Passwords do not match.", buttons: ["OK"] })
        setStep(2)
    }

    const handleChange = e => {
        if (e.target.name === "profile_picture_path") setUser({...user, [e.target.name] : e.target.files[0]})
        else setUser({ ...user, [e.target.name]: e.detail.value?.toString() })
        // dispatch(setUserState({ registerInfo: { ...UserReducer.registerInfo, submitted: false } }))
    }

    const handleSubmit = e => {
        if (user.first_name.length < 2) return ionAlert({ header: "Error!", message: "Please enter your name.", buttons: ["OK"] })
        if (user.last_name.length < 2) return ionAlert({ header: "Error!", message: "Please enter your last name.", buttons: ["OK"] })
        if (user.phone_number.length < 10) return ionAlert({ header: "Error!", message: "Please enter your phone number.", buttons: ["OK"] })

        const data = new FormData()
        data.append("email", user.email)
        data.append("password", user.password)
        data.append("confirm", user.confirm)
        data.append("first_name", user.first_name)
        data.append("last_name", user.last_name)
        data.append("phone_number", user.phone_number)
        data.append("profile_picture_path", user.profile_picture_path)
        dispatch(UserActions.register({ user: data }))
    }

    return (
        <div className="flex flex-col bg-clip-border bg-white text-gray-700 shadow-xl w-full absolute bottom-0 md:bottom-auto md:rounded-xl md:top-2/4 md:left-2/4 md:max-w-[24rem] md:-translate-y-2/4 md:-translate-x-2/4">
            <IonLoading isOpen={UserReducer.registerInfo.status === "pending"} message={UserReducer.registerInfo.message} />
            <div className="relative bg-clip-border mx-4 rounded-xl overflow-hidden bg-gradient-to-tr from-[#00529a] to-blue-800 text-white shadow-blue-800/40 shadow-md -mt-6 mb-4 grid h-28 place-items-center">
                <h3 className="block antialiased tracking-normal font-sans text-3xl font-semibold leading-snug text-white">
                    Sign Up
                </h3>
            </div>
            <div className="px-6 py-3 flex flex-col">
                {step === 1 ? <Fragment>
                    <div className="relative w-full min-w-[200px]">
                        <IonItem>
                            <IonLabel position='floating'>Email</IonLabel>
                            <IonInput value={user.email} name="email" type="email" onIonChange={e => handleChange(e)} />
                        </IonItem>
                    </div>
                    <div className="relative w-full min-w-[200px]">
                        <IonItem>
                            <IonLabel position='floating'>Password</IonLabel>
                            <IonInput value={user.password} name="password" type="password" onIonChange={e => handleChange(e)} />
                        </IonItem>
                    </div>
                    <div className="relative w-full min-w-[200px]">
                        <IonItem>
                            <IonLabel position='floating'>Confirm Password</IonLabel>
                            <IonInput value={user.confirm} name="confirm" type="password" onIonChange={e => handleChange(e)} />
                        </IonItem>
                    </div>
                </Fragment> : null}
                {step === 2 ? <Fragment>
                    <div className="relative w-full min-w-[200px]">
                        <IonItem>
                            <IonLabel position='floating'>First Name</IonLabel>
                            <IonInput value={user.first_name} name="first_name" onIonChange={e => handleChange(e)} />
                        </IonItem>
                    </div>
                    <div className="relative w-full min-w-[200px]">
                        <IonItem>
                            <IonLabel position='floating'>Last Name</IonLabel>
                            <IonInput value={user.last_name} name="last_name" onIonChange={e => handleChange(e)} />
                        </IonItem>
                    </div>
                    <div className="relative w-full min-w-[200px]">
                        <IonItem>
                            <IonLabel position='floating'>Phone Number</IonLabel>
                            <IonInput value={user.phone_number} name="phone_number" onIonChange={e => handleChange(e)} />
                        </IonItem>
                    </div>
                    <div className="relative w-full min-w-[200px]">
                        <IonItem>
                            <label>Select Profule Picture</label>
                            <input type="file" name="profile_picture_path" onChange={e => handleChange(e)} className="absolute right-0 w-[50px] opacity-0 z-10" />
                            <HiPhotograph className={`absolute right-0 w-[50px] z-0 text-3xl ${user.profile_picture_path === null ? "text-gray-500" : "text-indigo-600"}`} />
                        </IonItem>
                    </div>
                </Fragment> : null}
            </div>
            <div className="p-6 pt-0  mt-3">
                {step === 1 ?
                    <IonButton onClick={e => handleStep(2)} className="block">NEXT</IonButton> :
                    <IonButton onClick={e => handleSubmit(e)} className="block"> Sign Up</IonButton>
                }
                <p className="antialiased font-sans text-sm font-light leading-normal text-inherit mt-6 flex justify-center">
                    Alreay have an account?
                    <NavLink to="/auth/login" className="block antialiased font-sans text-sm leading-normal text-[#00529a] ml-1 font-bold">
                        Sign In
                    </NavLink>
                </p>
            </div>
        </div>
    )
}

export default Register