import { useSelector } from "react-redux"
import { UserState } from "../../Redux/User/Interface"
import { RootState } from "../../Redux/Store"
import { BsPersonFillAdd } from "react-icons/bs"

export const BrowseUsers: React.FC<{ setSelected:Function }> = ({ setSelected }) => {

    const UserReducer: UserState = useSelector((state: RootState) => state.user)

    return (
        <div className="w-full">
            <div className="mb-4">
                <span className="flex justify-between items-center">
                    <h1 className="font-bold text-lg text-slate-800 mb-1">Browse Users</h1>
                    <div>
                        <span onClick={e => setSelected(1)} className="flex items-center gap-3 text-white bg-red-600 p-1 rounded border-4 border-red-200 cursor-pointer hover:shadow">
                            <BsPersonFillAdd /> Add User
                        </span>
                    </div>
                </span>
                <p className="text-slate-800">A list of all the users in your account including their name, title, email and role.</p>
            </div>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg mb-12">
                <table className="w-full text-sm text-left text-gray-500">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 border-b border-slate-400">
                        <tr>
                            <th className="px-6 py-3">Name</th>
                            <th className="px-6 py-3">E-mail</th>
                            <th className="px-6 py-3">Phone Number</th>
                            <th className="px-6 py-3">Date Entered</th>
                            <th className="px-6 py-3">Type</th>
                            <th className="px-6 py-3">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {UserReducer.users.filter(u => u !== null).map((u, idx) => (
                            <tr className={idx % 2 === 0 ? "bg-white border-b" : "bg-gray-50 border-b"}>
                                <th className="px-6 py-4">{u.first_name} {u.last_name}</th>
                                <td className="px-6 py-4">{u.email}</td>
                                <td className="px-6 py-4">{u.phone_number}</td>
                                <td className="px-6 py-4">{u.sign_up_date}</td>
                                <td className="px-6 py-4">{UserReducer?.userTypes?.types[u.type_id]?.name}</td>
                                <td className="px-6 py-4"><a href="#" className="font-medium text-red-600 hover:underline">Edit</a></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default BrowseUsers