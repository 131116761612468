import React, { useEffect, useState } from "react";
import { BsCheckLg } from "react-icons/bs";
import ReactSlider from 'react-slider';
import { STATIC_URL } from "../../../common/constants";
import { QuizQuestion as QuestionInterface } from "../../../common/Interfaces";
import "./Questions.css"
import { GrClose } from "react-icons/gr";

export interface AnswerInterface {
    question_id: number
    text: string | null
    options: Array<number>
    segment: number | null
    start: Date
    end: Date | null
}



export const MultipleChoice: React.FC<{ question: QuestionInterface | any, answer: AnswerInterface, setAnswer: Function, asPreview: Boolean }> = ({ question, answer, setAnswer, asPreview }) => {
    const handleSelect = (e, opt_id) => {
        setAnswer({
            ...answer,
            options: answer.options.includes(opt_id) ? answer.options.filter(o => o !== opt_id) : [...answer.options, opt_id]
        })
    }

    const [img, setImg] = useState(null)

    useEffect(() => {
        if (typeof (question.image_path) !== typeof ({})) return
        if (question.image_path === null) return setImg(null)

        const file = question.image_path
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.addEventListener('load', () => {
            setImg(reader.result)
        });
    }, [question.image_path])

    if (asPreview === true) return (
        <div className="px-6 py-5 w-full h-full overflow-y-scroll bg-slate-800">
            <h1 className="text-white font-bold">{question.question}</h1>
            {typeof (question.image_path) === typeof ("123") ? <img src={STATIC_URL + question.image_path} className="w-1/3 my-4 mx-auto border-2 border-slate-800" /> : null}
            {typeof (question.image_path) === typeof ({}) ? <img src={img} className="w-1/3 my-4 mx-auto border-2 border-slate-800" /> : null}
            {question.options.map(opt =>
                <div className="bg-slate-50 p-3 rounded shadow flex items-center font-bold text-slate-800 mb-4 cursor-pointer" onClick={e => handleSelect(e, opt.id)}>
                    <span className={`${answer.options.includes(opt.id) ? "bg-slate-800" : ""} rounded w-5 h-5 flex items-center justify-center border-slate-800 border-2 text-white text-lg`}>
                        {answer.options.includes(opt.id) ? <BsCheckLg /> : null}
                    </span>
                    <span className="ml-1 w-[90%]">
                        {opt.image_path !== null ? <img className="w-1/2 md:w-1/3" src={STATIC_URL + opt.image_path} /> : null}
                        {opt.text}
                    </span>
                </div>
            )}
        </div>
    )

    return (
        <div className="px-6 py-5 w-full h-full overflow-y-scroll bg-slate-800">
            <h1 className="text-white font-bold">{question.question}</h1>
            {question.image_path === null ? null : <img src={STATIC_URL + question.image_path} className="w-1/3 my-4 mx-auto border-2 border-slate-800" />}
            {question.data.options.map(opt =>
                <div className="bg-slate-50 p-3 rounded shadow flex items-center font-bold text-slate-800 mb-4 cursor-pointer" onClick={e => handleSelect(e, opt.id)}>
                    <span className={`${answer.options.includes(opt.id) ? "bg-slate-800" : ""} rounded w-5 h-5 flex items-center justify-center border-slate-800 border-2 text-white text-lg`}>
                        {answer.options.includes(opt.id) ? <BsCheckLg /> : null}
                    </span>
                    <span className="ml-1 w-[90%]">
                        {opt.image_path !== null ? <img className="w-1/2 md:w-1/3" src={STATIC_URL + opt.image_path} /> : null}
                        {opt.text}
                    </span>
                </div>
            )}
        </div>
    )
}

export const SingleChoice: React.FC<{ question: QuestionInterface | any, answer: AnswerInterface, setAnswer: Function, asPreview: Boolean }> = ({ question, answer, setAnswer, asPreview }) => {
    const handleSelect = (e, opt_id) => setAnswer({ ...answer, options: [opt_id] })

    const [img, setImg] = useState(null)

    useEffect(() => {
        if (typeof (question.image_path) !== typeof ({})) return
        if (question.image_path === null) return setImg(null)
        const file = question.image_path;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.addEventListener('load', () => {
            setImg(reader.result);
        });
    }, [question.image_path])

    if (asPreview === true) return (
        <div className="px-6 py-5 w-full h-full overflow-y-scroll bg-slate-800">
            <h1 className="text-white font-bold">{question.question}</h1>
            {typeof (question.image_path) === typeof ("123") ? <img src={STATIC_URL + question.image_path} className="w-1/3 my-4 mx-auto border-2 border-slate-800" /> : null}
            {typeof (question.image_path) === typeof ({}) ? <img src={img} className="w-1/3 my-4 mx-auto border-2 border-slate-800" /> : null}
            {question.options.map(opt =>
                <div className="bg-slate-50 p-3 rounded shadow flex items-center font-bold text-slate-800 mb-4 cursor-pointer" onClick={e => handleSelect(e, opt.id)}>
                    <span className={`${answer.options.includes(opt.id) ? "bg-slate-800" : ""} rounded w-5 h-5 flex items-center justify-center border-slate-800 border-2 text-white text-lg`}>
                        {answer.options.includes(opt.id) ? <BsCheckLg /> : null}
                    </span>
                    <span className="ml-1 w-[90%]">
                        {opt.image_path !== null ? <img className="w-1/2 md:w-1/3" src={STATIC_URL + opt.image_path} /> : null}
                        {opt.text}
                    </span>
                </div>
            )}
        </div>
    )

    return (
        <div className="px-6 py-5 w-full h-full overflow-y-scroll bg-slate-800">
            <h1 className="text-white font-bold">{question.question}</h1>
            {question.image_path === null ? null : <img src={STATIC_URL + question.image_path} className="w-1/3 my-4 mx-auto border-2 border-slate-800" />}
            {question.data.options.map(opt =>
                <div className="bg-slate-50 p-3 rounded shadow flex items-center font-bold text-slate-800 mb-4 cursor-pointer" onClick={e => handleSelect(e, opt.id)}>
                    <span className={`${answer.options.includes(opt.id) ? "bg-slate-800" : ""} rounded w-5 h-5 flex items-center justify-center border-slate-800 border-2 text-white text-lg`}>
                        {answer.options.includes(opt.id) ? <BsCheckLg /> : null}
                    </span>
                    <span className="ml-1 w-[90%]">
                        {opt.image_path !== null ? <img className="w-1/2 md:w-1/3" src={STATIC_URL + opt.image_path} /> : null}
                        {opt.text}
                    </span>
                </div>
            )}
        </div>
    )
}

export const Slider: React.FC<{ question: QuestionInterface | any, answer: AnswerInterface, setAnswer: Function, asPreview: Boolean }> = ({ question, answer, setAnswer, asPreview = false }) => {

    if (asPreview) return (
        <div className="px-1 md:px-6 py-5 w-full h-full bg-slate-800 rounded-lg overflow-hidden shadow-md">
            <h1 className="text-white font-bold">{question.question}</h1>
            {question.image_path === null ? null : <div className="w-1/3 my-4 mx-auto border-2 border-slate-800 bg-slate-200 h-[100px]"> </div>}
            <div className="w-[320px] md:w-[340px] mx-auto">
                <div className="mt-4 p-3 bg-slate-400 h-[45px] rounded-lg">
                    <ReactSlider
                        className="customSlider"
                        trackClassName="customSlider-track"
                        min={1}
                        value={question.prefered_segment || answer.segment || Math.round(question.segments / 2)}
                        onChange={e => setAnswer({ ...answer, segment: e })}
                        max={question.segments || 3}
                        renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                    />
                </div>
                <div className="flex items-center justify-between capitalize mt-2">
                    <span className="w-2/5 text-left">
                        <p className="bg-slate-400 rounded-md p-1 inline text-center text-sm">{question.word_least || "Never"}</p>
                    </span>
                    <span className="w-1/5 text-center">
                        <p className="bg-slate-400 rounded-md px-2 py-1 inline text-center text-sm">Neutral</p>
                    </span>
                    <span className="w-2/5 text-right">
                        <p className="bg-slate-400 rounded-md px-2 py-1 inline text-center text-sm">{question.word_most || "Always"}</p>
                    </span>
                </div>
            </div>
        </div>
    )
    else return (
        <div className="px-6 py-5 w-full h-full overflow-y-scroll bg-slate-800">
            <h1 className="text-white font-bold">{question.question}</h1>
            {question.image_path === null ? null : <img src={STATIC_URL + question.image_path} className="w-1/3 my-4 mx-auto border-2 border-slate-800" />}
            <div className="w-[340px] mx-auto">
                <div className="mt-4 p-3 bg-slate-400 h-[45px] rounded-lg">
                    <ReactSlider
                        marks
                        className="customSlider"
                        trackClassName="customSlider-track"
                        min={1}
                        value={answer.segment || Math.round(question.data.segments / 2)}
                        onChange={e => setAnswer({ ...answer, segment: e })}
                        max={question.data.segments}
                        renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                    />
                </div>
                <div className="flex items-center justify-between capitalize mt-2">
                    <span className="w-1/3 text-left">
                        <p className="bg-slate-400 rounded-md px-2 py-1 inline text-center">{question.data.word_least}</p>
                    </span>
                    <span className="w-1/3 text-center">
                        <p className="bg-slate-400 rounded-md px-2 py-1 inline text-center">Neutral</p>
                    </span>
                    <span className="w-1/3 text-right">
                        <p className="bg-slate-400 rounded-md px-2 py-1 inline text-center">{question.data.word_most}</p>
                    </span>
                </div>
            </div>
        </div>
    )
}

export const Essay: React.FC<{ question: QuestionInterface, answer: AnswerInterface, setAnswer: Function, asPreview: Boolean }> = ({ question, answer, setAnswer, asPreview }) => {


    if (asPreview) return (
        <div className="px-1 md:px-6 py-5 w-full h-full bg-slate-800 rounded-lg overflow-hidden shadow-md">
            <h1 className="text-white font-bold">{question.question}</h1>
            {question.image_path === null ? null : <div className="w-1/3 my-4 mx-auto border-2 border-slate-800 bg-slate-200 h-[100px]"> </div>}
            <textarea className="mt-12 px-2 py-1.5 min-h-[150px] w-full bg-white  text-slate-800  rounded border-slate-200 border-2 outline-none focus:border-slate-800"
                value={answer.text === null ? "" : answer.text}
                name="text"
                placeholder="Write your answer..."
                onChange={e => setAnswer({ ...answer, text: e.target.value })}
            />
        </div>
    )

    return (
        <div className="px-6 py-5 w-full h-full overflow-y-scroll bg-slate-800">
            <h1 className="text-white font-bold">{question.question}</h1>
            {question.image_path === null ? null : <img src={STATIC_URL + question.image_path} className="w-1/3 my-4 mx-auto border-2 border-slate-800" />}
            <textarea className="mt-12 px-2 py-1.5 min-h-[250px] w-full bg-white  text-slate-800  rounded border-slate-200 border-2 outline-none focus:border-slate-800"
                value={answer.text === null ? "" : answer.text}
                name="text"
                placeholder="Write your answer..."
                onChange={e => setAnswer({ ...answer, text: e.target.value })}
            />
        </div>
    )
}

// const QuizQuestion: React.FC<{ question: QuestionInterface }> = ({ question }) => {
//     const [answer, setAnswer] = useState<AnswerInterface>({
//         question_id: question.id,
//         text: null,
//         options: [],
//         segment: null,
//         start: new Date(),
//         end: null
//     })

//     if (question.type === "essay") return <Essay question={question} answer={answer} setAnswer={setAnswer} asPreview={false} />
//     if (question.type === "slider") return <Slider question={question} answer={answer} setAnswer={setAnswer} asPreview={false} />
//     if (question.type === "choice" && question.data.is_multiple === 0) return <SingleChoice question={question} answer={answer} setAnswer={setAnswer} asPreview={false} />
//     if (question.type === "choice" && question.data.is_multiple === 1) return <MultipleChoice question={question} answer={answer} setAnswer={setAnswer} asPreview={false} />
// }




const QuizQuestion: React.FC<{ question: QuestionInterface, setOpen: Function }> = ({ question, setOpen }) => {

    const [img, setImg] = useState(null)
    const [opts, setOpts] = useState(question.data?.options === undefined ? null : {})

    const getImg = async (img) => {
        if (img instanceof Blob) {
            return new Promise((resolve) => {
                const reader = new FileReader()
                reader.readAsDataURL(img)
                reader.addEventListener("load", () => resolve(reader.result))
            })
        }
        if (typeof img === "string") return STATIC_URL + img
        return null
    }

    useEffect(() => {
        if (question.image_path instanceof Blob) {
            const file = question.image_path;
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.addEventListener('load', () => {
                setImg(reader.result);
            });
        }
    }, [question.image_path])

    console.log(question)

    return <div className="bg-slate-800 min-w-full overflow-y-scroll h-full" style={{
        "backgroundImage": "url('https://app.aptidext.com/assets/images/questionmark-bg.png')",
        "backgroundSize": "contain",
    }}>
        <div className="glass min-h-full pb-8">
            <div className="px-6 py-5 flex justify-between items-start gap-2">
                <h1 className="text-white font-bold">{question.question}</h1>
                <button className="block bg-[#F7C01D] text-slate-800 font-bold rounded px-2 py-1.5" onClick={e => {
                    setOpen(false);
                }}><GrClose /></button>
            </div>
            {question.image_path === null || question.image_path === "null" ? null : <img className="w-[25%] mx-auto my-4 border border-4 border-[#F7C01D]" src={question.image_path instanceof Blob ? img : STATIC_URL + question.image_path} />}

            {question.type === "slider" ? <div className="w-[90%] mx-auto my-8">
                <div className="mb-4 p-3 bg-slate-700 opacity-70 h-[45px] rounded-lg">
                    <ReactSlider
                        marks
                        className="customSlider in-answer"
                        trackClassName="customSlider-track"
                        min={1}
                        value={question?.data?.prefered_segment || 0}
                        onChange={e => { }}
                        max={question?.data?.segments || 5}
                        renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>} />
                </div>
                <div className="flex items-center justify-between capitalize mt-2">
                    <span className="w-1/3 text-left">
                        <p className="text-slate-50 bg-slate-700 rounded-md px-2 py-1 inline text-center">{question?.data?.word_least || "Least"}</p>
                    </span>
                    <span className="w-1/3 text-center">
                        <p className="text-slate-50 bg-slate-700 rounded-md px-2 py-1 inline text-center">Neutral</p>
                    </span>
                    <span className="w-1/3 text-right">
                        <p className="text-slate-50 bg-slate-700 rounded-md px-2 py-1 inline text-center">{question?.data?.word_most || "Most"}</p>
                    </span>
                </div>
                <div className="mt-4 font-bold">
                    <h1 className="mb-2 text-white">Max Time (seconds): {question.duration}</h1>
                    <h1 className="mb-2 text-white">Correct Segment: {question?.data?.prefered_segment || "NA"} </h1>
                    <h1 className="mb-1 text-white">Max Points: {question.points}</h1>
                </div>
            </div> : null}

            {question.type === "essay" ? <div className="w-[90%] mx-auto">
                <textarea className="my-4 px-2 py-1.5 min-h-[250px] w-full bg-slate-700 opacity-70 text-slate-100 rounded border-slate-300 border-2 outline-none focus:border-[#F7C01D]"
                    value={""}
                    name="text"
                    placeholder="Write your answer..."
                    onChange={e => { }} />
                <div className="mt-4 font-bold">
                    <h1 className="mb-2 text-white">Max Time (seconds): {question.duration || "NA"}</h1>
                    <h1 className="mb-1 text-white">Max Points: {question.points || "NA"}</h1>
                </div>
            </div> : null}
            {question.type === "choice" || question.type === "choices" || question.type === "multiple" ? <div className="w-[90%] mx-auto mt-8">
                {question?.data?.options?.map(opt => <div className={`mt-4 p-3 w-full bg-[rgba(51,65,85,0.8)] text-slate-100 rounded-lg border-slate-300 border-2 cursor-pointer flex items-center ${opt?.is_correct ? " !bg-[rgba(9,64,29,0.55)]" : ""}`} onClick={(e) => { }}>
                    <span className="ml-1 w-[90%] flex gap-2">
                        {opt.image_path !== null ? <img className="w-1/5" src={STATIC_URL + opt.image_path} /> : null}
                        {opt.text}
                    </span>
                </div>
                )}
                <div className="mt-4 font-bold">
                    <h1 className="mb-2 text-white">Max Time (seconds): {question.duration}</h1>
                    <h1 className="mb-2 text-white">Correct Option(s): {question?.data?.options?.map(opt => opt.archived === 1 || opt.is_correct === 0 ? null : opt.text).filter(opt => opt !== null).join(", ")} </h1>
                    <h1 className="mb-1 text-white">Max Points: {question.points}</h1>
                </div>
            </div> : null}
        </div>
    </div>;

}

export default QuizQuestion