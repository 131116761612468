import { useEffect, useState } from "react"
import { HiBriefcase, HiChartPie, HiUserCircle, HiOutlineLogout, HiMenuAlt3, HiOutlineX, HiQuestionMarkCircle } from 'react-icons/hi'
import { useDispatch } from "react-redux"
import { NavLink } from "react-router-dom"
import { AppDispatch } from "../../Redux/Store"
import { logout } from "../../Redux/User/Slice"
import { User } from "../Interfaces"
import Logo from "../../theme/static/Logo"
// https://liveimages.algoworks.com/new-algoworks/wp-content/uploads/2022/04/19150239/ezgif.com-gif-maker-1.gif
const Nav: React.FC<{ user: User }> = ({ user }) => {
    const dispatch = useDispatch<AppDispatch>()
    const [open, setOpen] = useState(false)
    
    
    const colors = {
        "create_question": "rgba(89, 166, 95, alpha)",
        "sessions_browser": "rgba(6, 197, 154, alpha)",
        "question": "rgba(89, 166, 95, alpha)",
        "questions": "rgba(89, 166, 95, alpha)",
        "edit_career": "rgba(4, 62, 97, alpha)",
        "career_users": "rgba(4, 62, 97, alpha)",
        "create_quiz": "rgba(4, 62, 97, alpha)",
        "career": "rgba(4, 62, 97, alpha)",
        "quiz": "rgba(24, 129, 253, alpha)",
        "edit_quiz": "rgba(30, 41, 59, alpha)",
        // "careers": "rgba(255, 116, 72, alpha)",
        // "create_career": "rgba(255, 116, 72, alpha)",
        "create_career": "rgba(12, 74, 110, alpha)",
        "careers": "rgba(12, 74, 110, alpha)",
        "dashboard": "rgba(37, 78, 70, alpha)",
        "profile": "rgba(96, 165, 250, alpha)"
    }


    useEffect(() => {
        const bg_continaer = document.getElementById("bg-continaer")
        const container = document.getElementById("page-container")
        container.addEventListener("scroll", e => {
            let path = ""
            if (window.location.href.includes("8100")) path = (window.location.href.split(":8100")[1]).split("/")[1] // web local 
            else if (window.location.href.includes("localhost")) path = window.location.href.split("http://localhost/")[1].split("/")[0] // android / ios
            else if (window.location.href.includes("aptidext.com")) path = window.location.href.split("https://app.aptidext.com/")[1].split("/")[0] // web production 
            if (container.scrollTop > 20) {
                bg_continaer.style.backgroundColor = colors[path].replace("alpha", (container.scrollTop / 250) > 0.9 ? 0.9 : (container.scrollTop / 250))
                bg_continaer.classList.add("shadow-lg")
                bg_continaer.classList.add("nav-glass")
            } else {
                bg_continaer.style.backgroundColor = "unset"
                bg_continaer.classList.remove("shadow-lg")
                bg_continaer.classList.remove("nav-glass")
            }
        })
    }, [])

    return (
        <nav className="block w-full text-white shadow-none absolute top-0 left-0 z-10">
            <div id="bg-continaer" className="
                container mx-auto flex items-center justify-between text-white min-w-full
                px-3 py-1 md:py-2 mb-3 md:mb-0 
                rounded-b-2xl
            ">
                {/* <p className="block antialiased font-sans text-base leading-relaxed text-inherit mr-4 ml-2 cursor-pointer py-1.5 font-bold">APTIDEX</p> */}
                <div className="ml-2">
                    <Logo />
                </div>
                <div className="hidden md:block mr-4">
                    <span className="mb-0 mt-0 flex flex-row text-inherit items-center gap-3">
                        <NavLink to="/careers" className="mb-0 mt-0 flex flex-row text-inherit items-center transition-all hover:underline">
                            <HiBriefcase className="w-[18px] h-[18px] opacity-75 mr-1" /> CAREERS
                        </NavLink>
                        {user.type_id >= 2 ? <NavLink to="/questions" className="mb-0 mt-0 flex flex-row text-inherit items-center transition-all hover:underline">
                            <HiQuestionMarkCircle className="w-[18px] h-[18px] opacity-75 mr-1" /> QUESTIONS
                        </NavLink> : null}
                        <NavLink to="/dashboard" className="mb-0 mt-0 flex flex-row text-inherit items-center transition-all hover:underline">
                            <HiChartPie className="w-[18px] h-[18px] opacity-75 mr-1" /> DASHBOARD
                        </NavLink>
                        <NavLink to="/profile" className="mb-0 mt-0 flex flex-row text-inherit items-center transition-all hover:underline">
                            <HiUserCircle className="w-[18px] h-[18px] opacity-75 mr-1" /> PROFILE
                        </NavLink>
                        <NavLink onClick={e => dispatch(logout())} to="/auth/login" className="mb-0 mt-0 flex flex-row text-inherit items-center transition-all hover:underline">
                            <HiOutlineLogout className="w-[18px] h-[18px] opacity-75 mr-1" /> LOGOUT
                        </NavLink>
                    </span>
                </div>
                <button className="relative middle none font-sans font-medium text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none w-8 max-w-[32px] h-8 max-h-[32px] rounded-lg text-xs ml-auto text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent md:hidden" type="button" style={{ "position": "relative", "overflow": "hidden" }}>
                    <span className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2" onClick={e => setOpen(!open)}>
                        {open ? <HiOutlineX className="h-6 w-6" /> : <HiMenuAlt3 className="h-6 w-6" />}
                    </span>
                </button>
            </div>
            <div className="block md:hidden basis-full overflow-hidden rounded-xl bg-white  px-4 pt-2 pb-4 transition-all shadow-md mx-3" style={{ "height": open ? user.type_id == 1 ? "165px" :  "205px" : "0px", "opacity": open ? "1" : "0", "padding": open ? "0.5rem 1rem 1rem 1rem" : "0px" }}>
                <div className="container mx-auto">
                    <span className="mb-4 mt-2 flex flex-col gap-2">
                        <NavLink onClick={e => setOpen(false)} to="/careers" className="font-sans text-sm text-gray-800  leading-normal flex items-center gap-1 p-1 font-normal transition-all hover:text-[#FF7448] hover:font-bold">
                            <HiBriefcase className="w-[18px] h-[18px] opacity-75 mr-1" /> CAREERS
                        </NavLink>
                        {user.type_id >= 2 ? <NavLink onClick={e => setOpen(false)} to="/questions" className="font-sans text-sm text-gray-800  leading-normal flex items-center gap-1 p-1 font-normal transition-all hover:text-[#59A65F] hover:font-bold">
                            <HiQuestionMarkCircle className="w-[18px] h-[18px] opacity-75 mr-1" /> QUESTIONS
                        </NavLink> : null}
                        <NavLink onClick={e => setOpen(false)} to="/dashboard" className="font-sans text-sm text-gray-800  leading-normal flex items-center gap-1 p-1 font-normal transition-all hover:text-[#254E46] hover:font-bold">
                            <HiChartPie className="w-[18px] h-[18px] opacity-75 mr-1" /> DASHBOARD
                        </NavLink>
                        <NavLink onClick={e => setOpen(false)} to="/profile" className="font-sans text-sm text-gray-800  leading-normal flex items-center gap-1 p-1 font-normal transition-all hover:text-sky-300 hover:font-bold">
                            <HiUserCircle className="w-[18px] h-[18px] opacity-75 mr-1" /> PROFILE
                        </NavLink>
                        <NavLink onClick={e => { dispatch(logout()); setOpen(false) }} to="/auth/login" className="font-sans text-sm text-gray-800  leading-normal flex items-center gap-1 p-1 font-normal transition-all hover:text-red-600 hover:font-bold">
                            <HiOutlineLogout className="w-[18px] h-[18px] opacity-75 mr-1" /> LOGOUT
                        </NavLink>
                    </span>
                </div>
            </div>
        </nav>
    )
}

export default Nav