import { IonCheckbox } from "@ionic/react"
import { useState } from "react"
import { HiPhotograph } from "react-icons/hi"
import { AnswerInterface, MultipleChoice, SingleChoice } from "../../Careers/QuizComponents/QuizQuestion"
import Option from "./Option"
import "./QuestionForms.css"

const getId = options => {
    if (options.length === 0) return Math.random().toString(36).slice(2)
    let maxStr = options.reduce((a, b) => a.id > b.id ? a : b)
    let randomStr = Math.random().toString(36).slice(2)
    while (randomStr <= maxStr) randomStr = Math.random().toString(36).slice(2)
    return randomStr
}

const ChoiceForm: React.FC<{ question, handleChange }> = ({ question, handleChange }) => {

    const [answer, setAnswer] = useState<AnswerInterface>({
        question_id: question.id,
        text: null,
        options: [],
        segment: null,
        start: new Date(),
        end: null
    })


    return (
        <div>
            <div className="mb-3 flex justify-between items-center">
                <div className="flex items-center gap-2 cursor-pointer">
                    <IonCheckbox onIonChange={e => handleChange({ target: { name: "is_multiple", value: question.is_multiple === 1 ? 0 : 1 } })} checked={question.is_multiple === 1} slot="start" color={"success"}></IonCheckbox>
                    <p className="text-sm font-bold text-slate-600 ">Is Multiple Choice</p>
                </div>
                <button onClick={e => handleChange({
                    target: {
                        name: "options",
                        value: [...question.options, { id: getId(question.options), is_correct: 0, text: "Example Option", image: null }]
                    }
                })} className="bg-[#59A65F] text-white px-2 py-1 rounded shadow">Add Option</button>
            </div>
            {[...question.options].sort((a, b) => a.id > b.id ? 1 : -1).map(opt => <Option key={opt.id} question={question} handleChange={handleChange} option={opt} />)}
            <div className="relative mb-5 flex items-center">
                <label className="text-sm font-bold text-slate-600  mr-5">Select Question Photo (optional)</label>
                <div>
                    <input onClick={e => {
                        if (question.image_path === null || typeof (question.image_path) === typeof ("")) return
                        e.preventDefault()
                        handleChange({
                            target: {
                                name: "image_path",
                                files: [null]
                            }
                        })
                    }} type="file" name="image_path" onChange={e => handleChange(e)} className="absolute w-[50px] opacity-0 z-10 cursor-pointer" />
                    <HiPhotograph className={`w-[50px] z-0 text-3xl ${question.image_path === null || typeof (question.image_path) === typeof ("") ? "text-gray-500" : "text-[#043E61]"}`} />
                </div>
            </div>
            {/* {question.is_multiple === 1 ? <MultipleChoice
                question={question}
                answer={answer}
                setAnswer={setAnswer}
                asPreview={true}
            /> : 
            <SingleChoice
                question={question}
                answer={answer}
                setAnswer={setAnswer}
                asPreview={true}
            />} */}
        </div>
    )
}

export default ChoiceForm
