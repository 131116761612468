import { useState } from "react"
import { HiPhotograph } from "react-icons/hi"
import { AnswerInterface, Essay } from "../../Careers/QuizComponents/QuizQuestion"

const EssayForm: React.FC<{ question, handleChange }> = ({ question, handleChange }) => {

    const [answer, setAnswer] = useState<AnswerInterface>({
        question_id: question.id,
        text: null,
        options: [],
        segment: null,
        start: new Date(),
        end: null
    })

    return (
        <div>
            <div className="flex flex-col gap-2 mb-3">
                    <label className="text-sm font-bold text-slate-600 ">Enter character limit for the answer of this question</label>
                    <input
                        className="px-2 py-1.5 bg-white text-slate-800 rounded border-slate-200  border-2 outline-none focus:border-[#59A65F]"
                        type="text"
                        value={question.max_characters}
                        name="max_characters"
                        placeholder="1200"
                        onChange={e => handleChange(e)}
                    />
                </div>
            <div className="relative mb-5 flex items-center">
                <label className="text-sm font-bold text-slate-600  mr-5">Select Question Photo (optional)</label>
                <div>
                    <input onClick={e => {
                        if (question.image_path === null || typeof (question.image_path) === typeof ("")) return
                        e.preventDefault()
                        handleChange({
                            target: {
                                name: "image_path",
                                files: [null]
                            }
                        })
                    }} type="file" name="image_path" onChange={e => handleChange(e)} className="absolute w-[50px] opacity-0 z-10 cursor-pointer" />
                    <HiPhotograph className={`w-[50px] z-0 text-3xl ${question.image_path === null || typeof (question.image_path) === typeof ("") ? "text-gray-500" : "text-[#043E61]"}`} />
                </div>
            </div>
            {/* <Essay question={question} answer={answer} setAnswer={setAnswer} asPreview={true} /> */}
        </div>
    )
}

export default EssayForm