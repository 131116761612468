import { useEffect, useState } from "react"
import { HiPhotograph } from "react-icons/hi"
import { useDispatch } from "react-redux"
import { AppDispatch, RootState } from "../../Redux/Store"
import { UserState } from "../../Redux/User/Interface"
import { useSelector } from "react-redux"
import { setUserState } from "../../Redux/User/Slice"
import { useIonAlert } from "@ionic/react"
import { UserActions } from "../../Redux/User/Actions"
import { AiFillDatabase } from "react-icons/ai"

let session = Math.random().toString(36).slice(2)

export const CreateUser: React.FC<{ setSelected: Function }> = ({ setSelected }) => {
    const dispatch = useDispatch<AppDispatch>()
    const UserReducer: UserState = useSelector((state: RootState) => state.user)
    const [ionAlert] = useIonAlert()

    const [user, setUser] = useState({
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        password: "",
        confirm_password: "",
        profile_picture_path: null
    })

    useEffect(() => {
        session = Math.random().toString(36).slice(2)
        dispatch(setUserState({ createUserInfo: { ...UserReducer.createUserInfo, submitted: false, session_id: session } }))
    }, [])

    useEffect(() => {
        if (UserReducer.createUserInfo.submitted !== true || UserReducer.createUserInfo.session_id !== session) return
        if (UserReducer.createUserInfo.status === "fulfilled") {
            ionAlert({ header: "Success", message: "User created successfully.", buttons: ["OK"] })
        }
        if (UserReducer.createUserInfo.status === "rejected") {
            ionAlert({ header: "Error!", message: UserReducer.createUserInfo.message, buttons: ["OK"] })
        }
    }, [UserReducer.createUserInfo.status])

    const handleSubmit = e => {
        e.preventDefault()
        const data = new FormData()
        data.append("first_name", user.first_name)
        data.append("last_name", user.last_name)
        data.append("email", user.email)
        data.append("phone_number", user.phone_number)
        data.append("profile_picture_path", user.profile_picture_path)
        dispatch(UserActions.updateUser({ user: data }))
    }

    const handleChange = e => {
        if (e.target.name === "profile_picture_path") setUser({ ...user, [e.target.name]: e.target.files[0] })
        else setUser({ ...user, [e.target.name]: e.target.value?.toString() })
        dispatch(setUserState({ createUserInfo: { ...UserReducer.createUserInfo, submitted: false } }))
    }


    return (
        <div className="w-[1000px] max-w-[95%] mx-auto">
            <div className="mb-4">
                <span className="flex justify-between items-center">
                    <h1 className="font-bold text-lg text-slate-800 mb-1">Create User</h1>
                    <div>
                        <span onClick={e => setSelected(0)} className="flex items-center gap-3 text-white bg-red-600 p-1 rounded border-4 border-red-200 cursor-pointer hover:shadow">
                            <AiFillDatabase /> Browse Users
                        </span>
                    </div>
                </span>
                <p className="text-slate-800">Enter a new user in the system</p>
            </div>
            <div className="w-full mx-auto p-4 mt-4 mb-12 bg-white rounded-lg shadow-md">
                <span className="flex items-center gap-2 mb-4">
                    <h1 className="text-xl font-bold text-slate-700 mb-1">Create new user</h1>
                </span>
                <div className="flex flex-col gap-2 mb-3">
                    <label className="text-sm font-bold text-slate-600 ">First Name</label>
                    <input className="px-2 py-1.5 bg-white text-slate-800 rounded border-slate-200  border-2 outline-none focus:border-red-500" type="text" value={user.first_name} name="first_name" placeholder="John" onChange={e => handleChange(e)} />
                </div>
                <div className="flex flex-col gap-2 mb-3">
                    <label className="text-sm font-bold text-slate-600 ">Last Name</label>
                    <input className="px-2 py-1.5 bg-white text-slate-800 rounded border-slate-200  border-2 outline-none focus:border-red-500" type="text" value={user.last_name} name="last_name" placeholder="Doe" onChange={e => handleChange(e)} />
                </div>
                <div className="flex flex-col gap-2 mb-3">
                    <label className="text-sm font-bold text-slate-600 ">Email</label>
                    <input className="px-2 py-1.5 bg-white text-slate-800 rounded border-slate-200  border-2 outline-none focus:border-red-500" type="email" value={user.email} name="email" placeholder="john.doe@example.com" onChange={e => handleChange(e)} />
                </div>
                <div className="flex flex-col gap-2 mb-3">
                    <label className="text-sm font-bold text-slate-600 ">Phone Number</label>
                    <input className="px-2 py-1.5 bg-white text-slate-800 rounded border-slate-200  border-2 outline-none focus:border-red-500" type="text" value={user.phone_number} name="phone_number" placeholder="+1 222 333 4444" onChange={e => handleChange(e)} />
                </div>
                <div className="flex flex-col gap-2 mb-3">
                    <label className="text-sm font-bold text-slate-600 ">Password</label>
                    <input className="px-2 py-1.5 bg-white text-slate-800 rounded border-slate-200  border-2 outline-none focus:border-red-500" type="password" value={user.password} name="password" placeholder="**********" onChange={e => handleChange(e)} />
                </div>
                <div className="flex flex-col gap-2 mb-3">
                    <label className="text-sm font-bold text-slate-600 ">Confirm Password</label>
                    <input className="px-2 py-1.5 bg-white text-slate-800 rounded border-slate-200  border-2 outline-none focus:border-red-500" type="password" value={user.confirm_password} name="confirm_password" placeholder="**********" onChange={e => handleChange(e)} />
                </div>
                <div className="relative mb-5 flex items-center">
                    <label className="text-sm font-bold text-slate-600 mr-5">Select new profile picture</label>
                    <div>
                        <input type="file" name="profile_picture_path" onChange={e => handleChange(e)} className="absolute w-[50px] opacity-0 z-10 cursor-pointer" />
                        <HiPhotograph className={`w-[50px] z-0 text-3xl ${user.profile_picture_path === null || typeof (user.profile_picture_path) === typeof ("abc") ? "text-gray-500" : "text-red-500"}`} />
                    </div>
                </div>
                <button className="bg-red-600 text-white px-8 py-1 rounded shadow-md hover:shadow-lg" onClick={e => handleSubmit(e)}>SUBMIT</button>
            </div>
        </div>
    )
}

export default CreateUser