import { BsFillArrowLeftSquareFill } from "react-icons/bs"
import { useDispatch } from "react-redux"
import { useParams } from "react-router"
import { NavLink } from "react-router-dom"
import { AppDispatch, RootState } from "../../../Redux/Store"
import { UserState } from "../../../Redux/User/Interface"
import { useSelector } from "react-redux"
import { CareersState } from "../../../Redux/Careers/Interface"
import { useEffect, useState } from "react"
import { CareerActions } from "../../../Redux/Careers/Actions"
import { STATIC_URL } from "../../../common/constants"
import EnrolledUsers from "./EnrolledUsers"
import { UserActions } from "../../../Redux/User/Actions"
import EnrollUsers from "./EnrollUsers"

const CareerUsersContainer: React.FC<{}> = ({ }) => {
    const id: number = parseInt(useParams<{ id: string }>().id)
    const dispatch = useDispatch<AppDispatch>()

    const UserReudcer: UserState = useSelector((state: RootState) => state.user)
    const CareersReducer: CareersState = useSelector((state: RootState) => state.career)

    const [selected, setSelected] = useState(0)

    useEffect(() => {
        dispatch(UserActions.readUsers({ arguments: { enable_hash: true } }))
        dispatch(CareerActions.readCareers({ arguments: { enable_hash: true, hash_key: "id" } }))
        dispatch(CareerActions.readUserCareers({ arguments: { enable_hash: false } }))
        setTimeout(() => document.getElementById("enroll-width-animate").classList.replace("w-[800px]", "w-[1000px]"), 100)
    }, [])

    const currentCareer = CareersReducer.career.careers.find(c => c !== null && c.id === id)
    const enrollments = CareersReducer.userCareers.userCareers.filter(c => c !== null && c.career_id === id)

    return (
        <div>
            <div className="
                bg-[url('https://liveimages.algoworks.com/new-algoworks/wp-content/uploads/2022/04/19150239/ezgif.com-gif-maker-1.gif')] 
                bg-[#043E61] bg-no-repeat bg-contain bg-center bg-fixed
                pt-16 pb-12 max-h-[250px] rounded-b-2xl
            ">
                <div id="enroll-width-animate" className="w-[800px] transition-width duration-500 max-w-[90%] mx-auto mt-36">
                    <div className="pt-1 p-4 mb-6 bg-white  rounded-lg shadow-md">
                        <span className="flex mb-2 items-center justify-between mt-2">
                            <span className="flex items-center">
                                <NavLink to={"/career/" + id} className="text-[#043E61] cursor-pointer mr-2 text-xl">
                                    <BsFillArrowLeftSquareFill />
                                </NavLink>
                                <h1 className="text-xl font-bold text-slate-800 mb-1">{currentCareer.name.substring(0, 28)} Enrollment Menagement</h1>
                            </span>
                        </span>
                        <p>
                            <img src={STATIC_URL + currentCareer.cover_photo} className="w-[45px] h-[45px] mr-2 shadow float-left" />
                            {currentCareer.description}
                        </p>
                    </div>


                    <div>
                        <span className="flex justify-between items-center mb-2">
                            <h1 className="text-[#043E61] text-2xl">MANAGE ENROLLMENT</h1>
                            <span className="flex items-center gap-3">
                                <span onClick={e => setSelected(0)} className={(selected === 0 ? "border-2 !border-cyan-500 " : "") + "border-1 border-[#043E61] transition-all bg-[#043E61] text-white p-1 cursor-pointer rounded-md"}>
                                    View Enrolled Users
                                </span>
                                <span onClick={e => setSelected(1)} className={(selected === 1 ? "border-2 !border-cyan-500 " : "") + "border-1 border-[#043E61] transition-all bg-[#043E61] text-white p-1 cursor-pointer rounded-md"}>
                                    Enroll Users
                                </span>
                            </span>
                        </span>
                        <hr className="mb-4" />
                        {selected === 0 ? <EnrolledUsers id={id} enrollments={enrollments} /> : null}
                        {selected === 1 ? <EnrollUsers id={id} enrollments={enrollments} /> : null}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CareerUsersContainer