import { IonButton, IonInput, IonItem, IonLabel, useIonAlert } from "@ionic/react"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { NavLink, useHistory } from "react-router-dom"
import { AppDispatch, RootState } from "../../Redux/Store"
import { UserActions } from "../../Redux/User/Actions"
import { UserState } from "../../Redux/User/Interface"
import { setUserState } from "../../Redux/User/Slice"
import { debounce } from "../../common/functions"

let session = Math.random().toString(36).slice(2)

const Login: React.FC<{}> = ({ }) => {
    const dispatch = useDispatch<AppDispatch>()
    const history = useHistory()

    const UserReducer: UserState = useSelector((state: RootState) => state.user)
    const [user, setUser] = useState<{ email: string, password: string }>({
        email: "",
        password: "",
    })
    const [ionAlert] = useIonAlert()
    
    useEffect(() => {
        session = Math.random().toString(36).slice(2)
        dispatch(setUserState({ loginInfo: { status: "idle", message: "", session_id: session, submitted: false } }))
    }, [])

    useEffect(() => {
        if (UserReducer.loginInfo.submitted !== true || UserReducer.loginInfo.session_id !== session) return
        if (UserReducer.loginInfo.status === "fulfilled") history.push("/careers")
        if (UserReducer.loginInfo.status === "rejected") ionAlert({header: "Error!", message: UserReducer.loginInfo.message, buttons: ["OK"]})
    }, [UserReducer.loginInfo.status])

    const handleChange = e => {
        if (e.target.name === "profile_picture_path") setUser({...user, [e.target.name] : e.target.files[0]})
        else setUser({ ...user, [e.target.name]: e.target.value })
    }

    const handleSubmit = e => {
        if (user.email.length < 2) return ionAlert({ header: "Error!", message: "Please enter your email.", buttons: ["OK"] })
    

        const data = new FormData()
        data.append("email", user.email)
        data.append("password", user.password)
        dispatch(UserActions.login({ user: data }))
    }

    return (
        <div className="flex flex-col bg-clip-border bg-white text-gray-700 shadow-xl w-full absolute bottom-0 md:bottom-auto md:rounded-xl md:top-2/4 md:left-2/4 md:max-w-[24rem] md:-translate-y-2/4 md:-translate-x-2/4">
            <div className="relative bg-clip-border mx-4 rounded-xl overflow-hidden bg-gradient-to-tr from-[#00529a] to-blue-800 text-white shadow-blue-800/40 shadow-md -mt-6 mb-4 grid h-20 place-items-center">
                <h3 className="block antialiased tracking-normal font-sans text-3xl font-semibold leading-snug text-white">
                    Sign In
                </h3>
            </div>
            <div className="px-6 py-3 flex flex-col">
                <div className="relative w-full min-w-[200px] mb-4">
                    <span className="flex flex-col">
                        <label htmlFor="email" className="font-bold text-sm text-slate-800">Email</label>
                        <input name="email" type="email" value={user.email} onChange={e => handleChange(e)} 
                        className="bg-white text-slate-800 border-b border-slate-400 outline-none px-2 py-1" />
                    </span>
                </div>
                <div className="relative w-full min-w-[200px]">
                    <span className="flex flex-col">
                        <label htmlFor="password" className="font-bold text-sm text-slate-800">Password</label>
                        <input name="password" type="password" value={user.password} onChange={e => handleChange(e)} 
                        className="bg-white text-slate-800 border-b border-slate-400 outline-none px-2 py-1" />
                    </span>
                </div>
            </div>
            <div className="p-6 pt-0 mt-3">
                <IonButton className="block" onClick={e => handleSubmit(e)}>
                    Sign In
                </IonButton>
                <p className="antialiased font-sans text-sm font-light leading-normal text-inherit mt-6 flex justify-center">
                    Forgot Password?
                    <NavLink to="/auth/recover_password" className="block antialiased font-sans text-sm leading-normal text-[#00529a] ml-1 font-bold">
                        Click here
                    </NavLink>
                </p>
            </div>
        </div>
    )
}

export default Login