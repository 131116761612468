export const validateEmail = (email: string) => {
    const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const result: boolean = expression.test(email);
    return result === true ? true : false
}

export const insertInHash = (arr, val, key, type = "cbht") => {
    const ret = [...arr]
    if (type === "cbht") {
        ret[val[key]] = val
    } else {
        ret[val[key]] = ret[val[key]].filter(item => item !== val)
        ret[val[key]].push(val)
    }
    return ret
}

export const removeFromHash = (arr, val, key, type = "cbht") => {
    const ret = [...arr]

    if (type === "cbht") {
        ret[val[key]] = null
    } else {
        ret[val[key]] = ret[val[key]].filter(item => item !== val)
    }

    return ret
}

export const debounce = <T extends (...args: any[]) => any>(func: T, delay: number) => {
    let timerId: ReturnType<typeof setTimeout>
    
    const debounced = function (this: any, ...args: Parameters<T>): void {
      clearTimeout(timerId)
      timerId = setTimeout(() => {
        func.apply(this, args)
      }, delay)
    }
    
    debounced.cancel = function (): void {
      clearTimeout(timerId)
    }
    
    return debounced
  }