const SegmentCard: React.FC<{ segment: any, setSelectedSegment: Function }> = ({ segment, setSelectedSegment }) => (
    <div 
        onClick={e => setSelectedSegment(segment.id)}
        className="shadow rounded-lg cursor-pointer p-3"
    >
        <h1 className="font-bold text-slate-800">{segment.name}</h1>
        <p>{segment.questions.length} questions</p>
    </div>
)

export default SegmentCard