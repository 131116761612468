import { IonApp, setupIonicReact } from '@ionic/react';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
import { PushNotifications } from '@capacitor/push-notifications';

import firebase from 'firebase/app';
import 'firebase/messaging';

/* Theme variables */
import './theme/variables.css';

import RootRouter from './RootRouter';

setupIonicReact();

// PushNotifications.requestPermissions().then(() => {
//   PushNotifications.register();
// });

// firebase.initializeApp({
//   // Your Firebase config object
// });

// firebase.messaging().getToken().then((token) => {
//   console.log(`FCM token: ${token}`);
// });

// firebase.messaging().onMessage((message) => {
//   console.log(message)
// });


// PushNotifications.addListener('pushNotificationReceived', (notification) => {
//   console.log(notification)
// });

const App: React.FC = () => (
  <IonApp>
    <RootRouter />
  </IonApp>
);

export default App;
