import { useDispatch, useSelector } from "react-redux"
import { AnswersState } from "../../Redux/Answers/Interface"
import { AppDispatch, RootState } from "../../Redux/Store"
import { UserState } from "../../Redux/User/Interface"
import { QuizesState } from "../../Redux/Quizes/Interface"
import { useState } from "react"
import { IonAccordion, IonAccordionGroup, IonHeader, IonItem, IonLabel, IonModal } from "@ionic/react"
import { STATIC_URL } from "../../common/constants"
import ReactSlider from "react-slider"
import { AnswerActions } from "../../Redux/Answers/Actions"
import { setAnswersState } from "../../Redux/Answers/Slice"
import { GrClose } from "react-icons/gr"

const SessionCard: React.FC<{ session_id: string }> = ({ session_id }) => {
    const dispatch = useDispatch<AppDispatch>()
    const [expanded, setExpanded] = useState(false)
    const [selected, setSelected] = useState(null)
    const [points, setPoints] = useState(0)
    const [submitted, setSubmitted] = useState(false)

    const UserReudcer: UserState = useSelector((state: RootState) => state.user)
    const AnswersReducer: AnswersState = useSelector((state: RootState) => state.answers)
    const QuizReducer: QuizesState = useSelector((state: RootState) => state.quiz)

    const session = AnswersReducer.answer.answers[session_id]
    const user = UserReudcer.users[session[0].user_id]
    const quiz = QuizReducer.careerQuizes.quizes[session[0].quiz_id]
    const date_solved = session[0].date.slice(5, 22)
    const quiz_max_duration = (session.reduce((a, b) => a + QuizReducer.quizQuestions.questions[b.question_id]?.duration, 0) / 60).toFixed(2)
    const quiz_time_taken = (session.reduce((a, b) => a + b.time_taken, 0) / 60).toFixed(2)
    const graded_questions = session.filter(q => q.points >= 0).length
    const selected_question = selected === null ? null : QuizReducer.quizQuestions.questions[selected.question_id]

    const updateAnswer = e => {
        setSubmitted(true)
        if (isNaN(points)) return dispatch(setAnswersState({
            answer: {
                ...AnswersReducer.answer, updateInfo: {
                    status: "warning", message: "Please enter a numerical value", session_id: null, submitted: false
                }
            }
        }))
        if (selected_question.points < points) return dispatch(setAnswersState({
            answer: {
                ...AnswersReducer.answer, updateInfo: {
                    status: "warning", message: "Cannot grade greater than question limit", session_id: null, submitted: false
                }
            }
        }))

        const data = new FormData()
        data.append("id", selected.id.toString())
        data.append("points", points.toString())
        dispatch(AnswerActions.updateAnswer({ answer: data }))
    }

    return (
        <div className="mb-5 w-full">
            <div className="p-3 rounded-md shadow-md bg-white mb-3">
                <h1 className="font-bold text-slate-800 mb-3">{user?.first_name} {user?.last_name} attempted {quiz.name} on {date_solved} ({session_id})</h1>
                <p className="text-slate-800">The user took {quiz_time_taken} min(s) to finish the quiz. The total duration of the quiz is {quiz_max_duration} min(s).</p>
                <p className="text-slate-800">The system has automatically graded {graded_questions} questions. {session.length - graded_questions} question pending review.</p>
                <button onClick={e => setExpanded(!expanded)} className="mt-2 block ml-auto bg-blue-500 px-2 py-1.5 rounded text-white font-bold">{expanded ? "HIDE QUESTIONS" : "SHOW QUESTIONS"}</button>
            </div>
            <div className={expanded ? "rounded-md overflow-hidden shadow duration-800 transition-all max-h-[5000px]" : "duration-800 transition-all max-h-0 overflow-hidden"}>
                <IonAccordionGroup multiple={true}>
                    {quiz.segments.map(segment => (<div className="bg-white text-slate-800 flex items-center justify-between border-b">
                        <IonAccordion className="text-slate-800">
                            <h1 slot="header" className="border-b font-bold font-bold  text-slate-800 px-2 py-4 cursor-pointer bg-slate-50 flex justify-between">
                                <p>{segment.name} - {segment.questions.length} questions, {[...AnswersReducer.answer.answers[session_id]].filter(answer => answer.segment_id === segment.id && answer.points === -1).length} ungraded</p>
                                <p>
                                    {[...AnswersReducer.answer.answers[session_id]].reduce((points, answer) => points + (answer.segment_id !== segment.id || answer.points === -1 ? 0 : answer.points), 0)}  / {[...AnswersReducer.answer.answers[session_id]].reduce((points, answer) => points + (answer.segment_id !== segment.id ? 0 : QuizReducer.quizQuestions.questions[answer.question_id]?.points), 0)} 
                                </p>
                            </h1>

                            {[...AnswersReducer.answer.answers[session_id]].sort((a, b) => new Date(a.date) > new Date(b.date) ? 1 : -1)
                                .map(answer => answer.segment_id !== segment.id ? null : <div className="px-4 py-2.5 text-md flex justify-between cursor-pointer border-b border-slate-200 hover:bg-slate-50" slot="content" onClick={e => setSelected(answer)}>
                                    <p>{QuizReducer.quizQuestions.questions[answer.question_id]?.question}</p>
                                    <p>{answer.points < 0 ? "ungraded" : answer.points + "/" + QuizReducer.quizQuestions.questions[answer.question_id]?.points}</p>
                                </div>)}
                        </IonAccordion>
                    </div>))}
                </IonAccordionGroup>
            </div>
            <IonModal isOpen={selected !== null} onWillDismiss={e => {
                setSelected(null)
                setPoints(0)
                setSubmitted(false)
            }}>
                {selected === null ? null :
                    <div className="bg-slate-800 min-w-full overflow-y-scroll h-full" style={{
                        "backgroundImage": "url('https://app.aptidext.com/assets/images/questionmark-bg.png')",
                        "backgroundSize": "contain",
                    }}>
                        <div className="glass min-h-full">
                            <div className="px-6 py-5 flex justify-between items-start gap-2">
                                <h1 className="text-white font-bold">{selected_question.question}</h1>
                                <button className="block bg-[#F7C01D] text-slate-800 font-bold rounded px-2 py-1.5" onClick={e => {
                                    setSelected(null)
                                    setPoints(0)
                                    setSubmitted(false)
                                }}><GrClose /></button>
                            </div>
                            {selected_question.image_path === null || selected_question.image_path === "null" ? null : <img className="w-[25%] mx-auto my-4 border border-4 border-[#F7C01D]" src={STATIC_URL + selected_question.image_path} />}

                            {selected_question.type === "slider" ? <div className="w-[90%] mx-auto my-8">
                                <div className="mb-4 p-3 bg-slate-700 opacity-70 h-[45px] rounded-lg">
                                    <ReactSlider
                                        marks
                                        className="customSlider in-answer"
                                        trackClassName="customSlider-track"
                                        min={1}
                                        value={selected.answer_slider_segment}
                                        onChange={e => { }}
                                        max={selected_question.data.segments}
                                        renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                                    />
                                </div>
                                <div className="flex items-center justify-between capitalize mt-2">
                                    <span className="w-1/3 text-left">
                                        <p className="bg-slate-700 rounded-md px-2 py-1 inline text-center">{selected_question.data.word_least}</p>
                                    </span>
                                    <span className="w-1/3 text-center">
                                        <p className="bg-slate-700 rounded-md px-2 py-1 inline text-center">Neutral</p>
                                    </span>
                                    <span className="w-1/3 text-right">
                                        <p className="bg-slate-700 rounded-md px-2 py-1 inline text-center">{selected_question.data.word_most}</p>
                                    </span>
                                </div>
                                <div className="mt-4 font-bold">
                                    <h1 className="mb-2">Max Time / User Time (seconds): {selected_question.duration} / {selected.time_taken}</h1>
                                    {UserReudcer.current.type_id < 2 ? <h1 className="mb-2">Selected Segment: {selected.answer_slider_segment}</h1> : <h1 className="mb-2">Correct Segment / Selected Segment: {selected_question.data.prefered_segment} / {selected.answer_slider_segment}</h1>}
                                    <h1 className="mb-1">Score: {selected.points} / {selected_question.points}</h1>
                                    {UserReudcer.current.type_id >= 2 ? <h1 className="flex items-center gap-3">
                                        Update Score:
                                        <input onChange={e => setPoints(parseInt(e.target.value))} className="px-2 py-1 w-[40px] text-center bg-white text-slate-800 font-bold rounded-md" defaultValue={selected.points === -1 ? 0 : selected.points} />
                                        <button onClick={e => updateAnswer(e)} className="px-2 py-1.5 bg-[#F7C01D] text-slate-800 rounded-md">UPDATE</button>
                                    </h1> : null}
                                </div>
                            </div> : null}

                            {selected_question.type === "essay" ? <div className="w-[90%] mx-auto">
                                <textarea className="my-4 px-2 py-1.5 min-h-[250px] w-full bg-slate-700 opacity-70 text-slate-100 rounded border-slate-300 border-2 outline-none focus:border-[#F7C01D]"
                                    value={selected.answer_text === null ? "" : selected.answer_text}
                                    name="text"
                                    placeholder="Write your answer..."
                                    onChange={e => { }}
                                />
                                <div className="mt-4 font-bold">
                                    <h1 className="mb-2">Max Time / User Time (seconds): {selected_question.duration} / {selected.time_taken}</h1>
                                    <h1 className="mb-1">Score: {selected.points === -1 ? "Ungraded / " + selected_question.points : selected.points + " / " + selected_question.points}</h1>
                                    {UserReudcer.current.type_id >= 2 ? <h1 className="flex items-center gap-3">
                                        Update Score:
                                        <input onChange={e => setPoints(parseInt(e.target.value))} className="px-2 py-1 w-[40px] text-center bg-white text-slate-800 font-bold rounded-md" defaultValue={selected.points === -1 ? 0 : selected.points} />
                                        <button onClick={e => updateAnswer(e)} className="px-2 py-1.5 bg-[#F7C01D] text-slate-800 rounded-md">UPDATE</button>
                                    </h1> : null}
                                </div>
                            </div> : null}
                            {selected_question.type === "choice" ? <div className="w-[90%] mx-auto mt-8">
                                {selected_question.data.options.map(opt =>
                                    <div className={`mt-4 p-3 w-full bg-[rgba(51,65,85,0.8)] text-slate-100 rounded-lg border-slate-300 border-2 cursor-pointer flex items-center ${(selected.answer_option_ids === null ? [] : JSON.parse(selected.answer_option_ids)).includes(opt.id) ? " !bg-[rgba(9,64,29,0.55)]" : ""}`} onClick={(e) => { }}>
                                        <span className="ml-1 w-[90%] flex gap-2">
                                            {opt.image_path !== null ? <img className="w-1/5" src={STATIC_URL + opt.image_path} /> : null}
                                            {opt.text}
                                        </span>
                                    </div>
                                )}
                                <div className="mt-4 font-bold">
                                    <h1 className="mb-2">Max Time / User Time (seconds): {selected_question.duration} / {selected.time_taken}</h1>
                                    {UserReudcer.current.type_id >= 2 ? <h1 className="mb-2">Correct Option(s): {selected_question.data.options.map(opt =>
                                        opt.archived === 1 || opt.is_correct === 0 ? null : opt.text
                                    ).filter(opt => opt !== null).join(", ")} </h1> : null}
                                    <h1 className="mb-2">Selected Option(s): {selected_question.data.options.map(opt =>
                                        (selected.answer_option_ids === null ? [] : JSON.parse(selected.answer_option_ids)).includes(opt.id) ? opt.text : null
                                    ).filter(opt => opt !== null).join(", ")}</h1>
                                    <h1 className="mb-1">Score: {selected.points} / {selected_question.points}</h1>
                                    {UserReudcer.current.type_id >= 2 ? <h1 className="flex items-center gap-3">
                                        Update Score:
                                        <input onChange={e => setPoints(parseInt(e.target.value))} className="px-2 py-1 w-[40px] text-center bg-white text-slate-800 font-bold rounded-md" defaultValue={selected.points === -1 ? 0 : selected.points} />
                                        <button onClick={e => updateAnswer(e)} className="px-2 py-1.5 bg-[#F7C01D] text-slate-800 rounded-md">UPDATE</button>
                                    </h1> : null}
                                </div>
                            </div> : null}
                            <div className="pb-12 pt-4 w-[90%] mx-auto font-bold">
                                {submitted === false ? null : AnswersReducer.answer.updateInfo.status === "pending" ? <p className="w-full px-2 py-1.5 bg-blue-500 rounded-md">Updating... Please wait.</p> :
                                    AnswersReducer.answer.updateInfo.status === "fulfilled" ? <p className="w-full px-2 py-1.5 bg-emerald-600 rounded-md">{AnswersReducer.answer.updateInfo.message}</p> :
                                        AnswersReducer.answer.updateInfo.status === "warning" ? <p className="w-full px-2 py-1.5 bg-yellow-600 rounded-md">{AnswersReducer.answer.updateInfo.message}</p> :
                                            AnswersReducer.answer.updateInfo.status === "rejected" ? <p className="w-full px-2 py-1.5 bg-red-600 rounded-md">{AnswersReducer.answer.updateInfo.message}</p> : null}
                            </div>
                        </div>
                    </div>
                }
            </IonModal>
        </div>
    )
}


export default SessionCard